import {
  ActionButtonContainer,
  BodyField,
  BodyTypeContainer,
  FormDataContainer,
  Input,
  RequestOptionContainer,
  ResponseFieldsContainer,
  TitleInputContainer,
  TypeContainer,
} from "./ServiceCallNode.styled";
import NodeContext from "../../context/NodeContext";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { servceCallTypes } from "../../utils/constants";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "../../assets/images/addIcon.svg";
import { FullScreenContainer } from "../CodeBlockNodeEditor/CodeBlockNodeEditor.styled";
import CloseIcon from "../../assets/images/closeIcon.svg";
import { createPortal } from "react-dom";
import ExternalIcon from "../../assets/images/external.svg";
import ResponseVariable from "../ResponseVariable/index";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { LABELS } from "../../utils/labels";

function ServiceCallNodeEditor() {
  const [showFullScreen, setShowFullScreen] = useState(false);

  const { selectedNodeInfo, updateNode, isEditable, isSuperAdmin } =
    useContext(NodeContext);
  useEffect(() => {
    if (!isSuperAdmin) {
      toast.error("You don't have permission to edit this node", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    }
  }, [isSuperAdmin, selectedNodeInfo?.id, isEditable]);
  if (!selectedNodeInfo) {
    return null;
  }

  if (selectedNodeInfo?.data?.atsValue) {
    delete selectedNodeInfo.data.atsValue;
    updateNode(selectedNodeInfo.id, {
      ...selectedNodeInfo?.data,
    });
  }

  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
        />
        <p>
          Service call initiates a get/post call to other systems using APIs
        </p>
      </TitleInputContainer>
      <TypeContainer>
        <div>
          <p>Type</p>
          <Select
            options={servceCallTypes}
            className="select"
            placeholder="Select type"
            value={servceCallTypes.find(
              (o) => o.value === selectedNodeInfo?.data?.requestType
            )}
            onChange={(e) => {
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                requestType: e.value,
              });
            }}
            key={selectedNodeInfo?.id}
            isDisabled={!isSuperAdmin ? !isSuperAdmin : !isEditable}
          />
        </div>
      </TypeContainer>
      <TypeContainer>
        <div>
          <p>{LABELS.REQUEST_URL}</p>
          <input
            value={selectedNodeInfo?.data?.requestUrl}
            placeholder="https://"
            className="input"
            onChange={(e) => {
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                requestUrl: e.target.value,
              });
            }}
            readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
          />
        </div>
      </TypeContainer>
      <RequestOptionContainer>
        <p>{LABELS.REQUEST_HEADERS}</p>
        {selectedNodeInfo?.data?.requestHeaders?.map((item, index) => (
          <div className="inputContainer" key={item.id}>
            <input
              className="input"
              placeholder="key"
              value={item.key}
              onChange={(e) => {
                item.key = e.target.value;

                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                });
              }}
              readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
            />
            <input
              className="input"
              placeholder="value"
              value={item.value}
              onChange={(e) => {
                item.value = e.target.value;

                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                });
              }}
              readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
            />
            {selectedNodeInfo?.data?.requestHeaders.length > 1 &&
              isSuperAdmin && isEditable && (
                <img
                  src={CloseIcon}
                  alt="closeIcon"
                  onClick={() => {
                    const requestHeaders =
                      selectedNodeInfo?.data?.requestHeaders;
                    if (requestHeaders.length === 1) {
                      toast.error("Atleast one header is required.");
                      return;
                    }
                    requestHeaders.splice(index, 1);
                    // toast.success("Deleted successfully.");

                    updateNode(selectedNodeInfo.id, {
                      ...selectedNodeInfo?.data,
                      requestHeaders,
                    });
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
              )}
          </div>
        ))}
      </RequestOptionContainer>
      {isSuperAdmin && isEditable && (
        <ActionButtonContainer>
          <button
            onClick={() =>
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                requestHeaders: [
                  ...selectedNodeInfo?.data?.requestHeaders,
                  {
                    id: uuidv4(),
                    key: "",
                    value: "",
                  },
                ],
              })
            }
          >
            <img src={AddIcon} alt="addIcon" />
            Add Key
          </button>
        </ActionButtonContainer>
      )}
      <BodyTypeContainer>
        <p>{LABELS.REQUEST_BODY}</p>
        <img
          src={ExternalIcon}
          alt="externalIcon"
          onClick={() => setShowFullScreen(true)}
        />
        {/* <Select
                    options={bodyTypes}
                    className="select"
                    placeholder="Select type"
                    value={bodyTypes.find(
                        (o) =>
                            o.value === selectedNodeInfo?.data?.requestBodyType
                    )}
                    onChange={(e) => {
                        updateNode(selectedNodeInfo.id, {
                            ...selectedNodeInfo?.data,
                            requestBodyType: e.value,
                        });
                    }}
                    key={selectedNodeInfo?.id}
                /> */}
      </BodyTypeContainer>
      {selectedNodeInfo?.data?.requestBodyType === "raw" ? (
        <BodyField
          value={selectedNodeInfo?.data?.rawRequestBody}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              rawRequestBody: e.target.value,
            });
          }}
          readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
        />
      ) : (
        <>
          <FormDataContainer>
            {selectedNodeInfo?.data?.requestBodyFormData?.map((item, index) => (
              <div className="inputContainer" key={item.id}>
                <input
                  className="input"
                  placeholder="key"
                  value={item.key}
                  onChange={(e) => {
                    const requestBodyFormData =
                      selectedNodeInfo?.data?.requestBodyFormData;
                    requestBodyFormData[index].key = e.target.value;

                    updateNode(selectedNodeInfo.id, {
                      ...selectedNodeInfo?.data,
                      requestBodyFormData,
                    });
                  }}
                  readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
                />
                <input
                  className="input"
                  placeholder="value"
                  value={item.value}
                  onChange={(e) => {
                    const requestBodyFormData =
                      selectedNodeInfo?.data?.requestBodyFormData;
                    requestBodyFormData[index].value = e.target.value;

                    updateNode(selectedNodeInfo.id, {
                      ...selectedNodeInfo?.data,
                      requestBodyFormData,
                    });
                  }}
                  readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
                />
              </div>
            ))}
          </FormDataContainer>
          {isSuperAdmin && isEditable && (
            <ActionButtonContainer>
              <button
                onClick={() =>
                  updateNode(selectedNodeInfo.id, {
                    ...selectedNodeInfo?.data,
                    requestBodyFormData: [
                      ...selectedNodeInfo?.data?.requestBodyFormData,
                      {
                        id: uuidv4(),
                        key: "",
                        value: "",
                      },
                    ],
                  })
                }
              >
                <img src={AddIcon} alt="addIcon" />
                Add Key
              </button>
            </ActionButtonContainer>
          )}
        </>
      )}
      <ResponseFieldsContainer>
        {/* {atsData && (
          <>
            <p>{LABELS.ATS_FIELD}</p>
            <Select
              options={atsData}
              className="select-container"
              defaultValue=""
              isClearable
              value={atsData.find(
                (o) => o.value === (selectedNodeInfo?.data?.atsValue || "")
              )}
              onChange={(e) => {
                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  atsValue: e ? e.value : e,
                });
              }}
              key={selectedNodeInfo?.id}
              isDisabled={!isSuperAdmin}
              placeholder="Select ATS field"
            />
          </>
        )} */}
        <ResponseVariable
          updateNodeFun={updateNode}
          selectedNodeInfo={selectedNodeInfo}
          iseditable={isSuperAdmin && isEditable}
        />
        {selectedNodeInfo?.data?.saveResponseFields?.map((item, index) => (
          <div className="inputContainer" key={item.id}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "50%",
                position: "relative",
              }}
            >
              {/* New Component */}
              <InputFieldForKey
                item={item}
                updateNode={updateNode}
                selectedNodeInfo={selectedNodeInfo}
                key={index}
              />
            </div>
            <input
              className="input"
              placeholder="value"
              value={item.value}
              onChange={(e) => {
                item.value = e.target.value;

                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                });
              }}
              readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
            />
            {selectedNodeInfo?.data?.saveResponseFields?.length > 1 &&
              (isSuperAdmin && isEditable) && (
                <img
                  src={CloseIcon}
                  alt="deleteIcon"
                  onClick={() => {
                    const saveResponseFields =
                      selectedNodeInfo?.data?.saveResponseFields;
                    saveResponseFields.splice(index, 1);

                    updateNode(selectedNodeInfo.id, {
                      ...selectedNodeInfo?.data,
                      saveResponseFields,
                    });
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
              )}
          </div>
        ))}
      </ResponseFieldsContainer>
      {(isSuperAdmin && isEditable) && (
        <ActionButtonContainer>
          <button
            onClick={() =>
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                saveResponseFields: [
                  ...selectedNodeInfo?.data?.saveResponseFields,
                  {
                    id: uuidv4(),
                    key: "",
                    value: "",
                  },
                ],
              })
            }
          >
            <img src={AddIcon} alt="addIcon" />
            Add Key
          </button>
        </ActionButtonContainer>
      )}
      {showFullScreen
        ? createPortal(
          <FullScreenContainer>
            <div className="codeEditorContainer">
              <div className="header">
                <img
                  src={CloseIcon}
                  alt="closeIcon"
                  onClick={() => setShowFullScreen(false)}
                />
              </div>
              <textarea
                value={selectedNodeInfo?.data?.rawRequestBody}
                onChange={(e) => {
                  updateNode(selectedNodeInfo.id, {
                    ...selectedNodeInfo?.data,
                    rawRequestBody: e.target.value,
                  });
                }}
                readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
              ></textarea>
            </div>
          </FullScreenContainer>,
          document.getElementById("portal")
        )
        : null}
    </>
  );
}

export default ServiceCallNodeEditor;

function InputFieldForKey(props) {
  const { updateNode, selectedNodeInfo, item } = props;
  const [displayError, setDisplayError] = useState(
    selectedNodeInfo.data.isVariableNameAlreadyUsed ?? false
  );
  const { variableList, setNewAddedNode, isEditable, isSuperAdmin } = useContext(NodeContext);

  return (
    <>
      <input
        style={{
          width: "100%",
        }}
        className="input"
        placeholder="key"
        value={item.key}
        onBlur={(e) => {
          setNewAddedNode(true);
        }}
        onChange={(e) => {
          if (e.target.value.length > 60) {
            return;
          }
          const isVariableNameAlreadyUsed = variableList.find(
            (item) => item.value === `{${e.target.value.trim()}}}`
          );
          if (isVariableNameAlreadyUsed) {
            setDisplayError(true);
          } else {
            setDisplayError(false);
          }

          item.key = e.target.value;
          updateNode(selectedNodeInfo.id, {
            ...selectedNodeInfo?.data,
            isVariableNameAlreadyUsed: isVariableNameAlreadyUsed ? true : false,
          });
        }}
        readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
      />
      {displayError && (
        <Tooltip title="This variable has already been used.">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            style={{
              color: "#ffcc00",
              fontSize: "16px",
              position: "absolute",
              right: "8px",
            }}
          />
        </Tooltip>
      )}
    </>
  );
}
