import { useContext, useState } from "react";
import { Position } from "reactflow";
import GoToIcon from "../../assets/images/gotoicon-purple.svg";
import AddNodeSelector from "../../components/AddNodeSelector";
import DeleteIconImg from "../../assets/images/deleteIcon.svg";
import NodeContext from "../../context/NodeContext";
import {
  ActionButtonContainer,
  StyledJumpToProfileNode,
} from "./JumpToProfile.styled";
import { TopEdge } from "../StartingNode/StartingNode.styled";

function JumpToProfile(props) {
  const { id, data } = props;
  const { titleText, isTop, active } = data;
  const [showAddBox, setShowAddBox] = useState(false);
  const { handleNodeClick, deleteNode, selectedNodeInfo, isEditable } = useContext(NodeContext);
  const [visible, setVisible] = useState(false);


  return (
    <>
      {isTop ? (
        <TopEdge
          type="target"
          position={Position.Top}
          id="top"
          isConnectable={true}
          iseditable={isEditable ? "true" : undefined}
        />
      ) : null}
      <StyledJumpToProfileNode
        $isActive={active ? active : selectedNodeInfo?.id === id}
        onClick={() => handleNodeClick(id)}
      >
        <div className="titleContainer">
          <img src={GoToIcon} alt="GoToIcon" className="jumpToprofile" />
          <span className="titleText">{titleText}</span>
        </div>

        {isEditable && <ActionButtonContainer
          className="action-btns"
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src={DeleteIconImg}
            alt="deleteIcon"
            onClick={() => {
              setVisible(!visible);
              // deleteNode(id);
            }}
          />
        </ActionButtonContainer>}
        {visible && isEditable && (
          <ActionButtonContainer
            className="action-btns"
            onClick={(e) => e.stopPropagation()}
            style={{
              right: "-315px",
              top: "-35px",
              height: "auto",
              width: "250px",
              padding: "10px",
            }}
          >
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "Plus Jakarta Sans, sans-serif",
                marginBottom: "10px",
                color: "#000",
              }}
            >
              Are you sure you want to delete?
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "150px",
                margin: "auto",
                padding: "10px 8px",
              }}
            >
              <button
                style={{
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  color: "#007cc5",
                  border: "1px solid #007cc5",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setVisible(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  color: "#ef4949",
                  border: "1px solid #ef4949",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  setVisible(false);
                  deleteNode(id);
                }}
              >
                Delete
              </button>
            </div>
          </ActionButtonContainer>
        )}

        {showAddBox ? (
          <AddNodeSelector nodeData={props} setShowAddBox={setShowAddBox} />
        ) : null}
      </StyledJumpToProfileNode>
    </>
  );
}

export default JumpToProfile;
