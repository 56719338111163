import {
  SaveButton,
  DeleteButton,
  PreviewButton,
  PublishButton,
} from "./Buttons.styled";
import Tooltip from "@mui/material/Tooltip";
import ButtonLoader from "../Button Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FLOW_TYPE } from "../../constants";

import React from "react";
import Searchbar from "../Searchbar";
// import NodeContext from "../../context/NodeContext";
// import { toast } from "react-toastify";


function Buttons({
  onSave,
  onPreview,
  setVisibleDeploy,
  flowName,
  setFlowName,
  setVisible,
  editFlowName,
  buttonClick,
  isUpdating,
  FlowType,
  isEditable,
  setIsEditable,
  nodes,
  handleSearch,
  searchRef,
  selectedOption,
  setSelectedOption,
  setAtsModalVisibility,
  checkIfMissingAtsValues,

}) {
  // const {
  //   isSuperAdmin,
  //   setIsSuperAdmin
  // } = useContext(NodeContext);

  return (
    <>
      <nav
        style={{
          position: "fixed",
          zIndex: "1000",
          top: "0",
          left: "0",
          padding: "5px 20px",
          width: "100%",
          height: "60px",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            padding: "5px 10px",
            width: "auto",
            display: "flex",
            justifyItems: "space-between",
            alignItems: "center",
          }}
        >
          <input
            style={{
              width: "auto",
              height: "30px",
              fontSize: "18px",
              fontWeight: "bold",
              border: "none",
              outline: "none",
              padding: "0 5px",
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              color: "#007cc5",
            }}
            value={flowName}
            type="text"
            readOnly={!editFlowName}
            onChange={(e) => setFlowName(e.target.value)}
          />
        </div>
        <div>
          <Searchbar
            nodes={nodes}
            handleSearch={handleSearch}
            ref={searchRef}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            key={"searchbar"}
            id={"searchbar"}
          />
        </div>


        {isEditable && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 10,
              alignItems: "center",
            }}
          >
            <Tooltip title="Click this button to save the flow">
              <SaveButton onClick={onSave}>
                <span
                  style={{
                    fontSize: "14px",
                    transform: "none",
                  }}
                >
                  {isUpdating && buttonClick === "save" ? (
                    <ButtonLoader visible={isUpdating} />
                  ) : (
                    "Save"
                  )}
                </span>
              </SaveButton>
            </Tooltip>
            {/* <Comments /> */}
            <Tooltip title="Test the flow of your bot">
              <PreviewButton onClick={checkIfMissingAtsValues}>
                <span
                  style={{
                    fontSize: "14px",
                    transform: "none",
                  }}
                >
                  {isUpdating && buttonClick === "preview" ? (
                    <ButtonLoader visible={isUpdating} />
                  ) : (
                    "Test this bot"
                  )}
                </span>
              </PreviewButton>
            </Tooltip>
            {/* ToDo: Use this button for debugging purposes only */}
            {/* <button
              style={{
                border: "none",
                outline: "none",
                padding: "5px 10px",
                borderRadius: "5px",
                backgroundColor: "#007cc5",
                color: "#ffffff",
                fontSize: "14px",
                transform: "none",
                height: "40px",
                cursor: "pointer",
              }}
              onClick={() => {
                toast.success("isSuperAdmin set to: " + !isSuperAdmin);
                setIsSuperAdmin(!isSuperAdmin)
              }}
            >Change Super Admin</button>
            <button
              style={{
                border: "none",
                outline: "none",
                padding: "5px 10px",
                borderRadius: "5px",
                backgroundColor: "#007cc5",
                color: "#ffffff",
                fontSize: "14px",
                transform: "none",
                height: "40px",
                cursor: "pointer",
              }}
              onClick={() => {
                toast.success("isEditable set to: " + !isEditable);
                setIsEditable(!isEditable)
              }}
            >Change Edit Access</button> */}
            {FlowType === FLOW_TYPE.PROFILE && (
              <>
                <Tooltip title="Click this button to deploy the flow">
                  <PublishButton onClick={() => setVisibleDeploy(true)}>
                    <span
                      style={{
                        color: "#ffffff",
                        fontSize: "14px",
                        transform: "none",
                      }}
                    >
                      {isUpdating && buttonClick === "publish" ? (
                        <ButtonLoader visible={isUpdating} color={"#ffffff"} />
                      ) : (
                        "DEPLOY"
                      )}
                    </span>
                  </PublishButton>
                </Tooltip>
                <Tooltip title="Click this button to delete the chat flow changes">
                  <DeleteButton onClick={() => setVisible(true)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </DeleteButton>
                </Tooltip>
              </>
            )}
          </div>
        )}
      </nav>
    </>
  );
}

export default Buttons;



