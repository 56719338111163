import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import FlowBoard from "../screens/FlowBoard";
import { flowModuleApi } from "../api/FlowModule/api";
import { queryClient } from "../utils/helper";
import { authStore } from "../store/authStore";
import Redirect from "../components/Redirect";
import UnauthorizedPage from "../components/UnauthorizedPage";

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Redirect />,
  },
  {
    path: "/:sessionId",
    element: <FlowBoard />,
    loader: async (obj) => {
      const { sessionId } = obj.params;
        return queryClient.fetchQuery(
          ["authentication-Data", sessionId],
          async () => {
            // if (
            //   JSON.parse(sessionStorage.getItem("authData")) !== null &&
            //   (JSON.parse(sessionStorage.getItem("authData"))["token"] !== undefined ||
            //   JSON.parse(sessionStorage.getItem("authData"))["requestIdentity"] !== undefined ||
            //   JSON.parse(sessionStorage.getItem("authData"))["profileId"] !== undefined 
            //   )) {
            //   // return new Promise((resolve) => {
            //     const localData = JSON.parse(sessionStorage.getItem("authData"));
            //     authStore.setState(() => {
            //       return localData;
            //     });
            //     queryClient.invalidateQueries("flow-data");
            //     return localData;
            //   // });
            // } else{
              const authData = await flowModuleApi.getTokenFromSession(sessionId);
              if (!authData?.status) {
                authStore.setState(() => {
                  return authData;
                });
                // sessionStorage.setItem("authData", JSON.stringify(authData));
                queryClient.invalidateQueries("flow-data");
                return authData;
              } else {
                return null;
              }
            }
          // }
        );
    },
  },
  {
    path: "*",
    element: <Redirect />,
  },
  {
    path: "/unauthorized",
    element: <UnauthorizedPage />,
  },
]);

export default AppRoutes;
