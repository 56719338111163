// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  ActionButtonContainer,
  ButtonsListContainer,
  Input,
  InputContainer,
  MessageInputContainer,
  TitleInputContainer,
} from "./ButtonsNodeEditor.styled";
import NodeContext from "../../context/NodeContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  buttonOptions,
  BUTTON_TYPE,
  NODE_TYPE,
  toolbar,
} from "../../utils/constants";
import AddIcon from "../../assets/images/addIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import { ReactComponent as MoreVertical } from "../../assets/images/moreVertical.svg";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import { getHTMLToDraft } from "../../utils/helper";
import ResponseVariable from "../ResponseVariable/index";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
// import DraggableList from "../DraggableList";
import { ButtonNodeEditorConstants } from "./constants";
import { LABELS } from "../../utils/labels";
import { FLOW_TYPE_SCREENING } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
function ButtonItem({ button, index }) {
  const {
    selectedNodeInfo,
    updateNode,
    deleteNode,
    isEditable,
    profileType,
    saveData,
  } = useContext(NodeContext);
  const [isOpen, setIsOpen] = useState();
  const ref = useRef();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (!isOpen) return;

    setTimeout(() => {
      window.addEventListener("click", handleClose);
    }, 300);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [ref, isOpen, handleClose]);

  return (
    <div
      className="item"
      style={{
        width: "100%",
        // marginLeft: isEditable ? "10px" : "0px",
      }}
    >
      <div className="input-container">
        <div className="inp-cont">
          <div>
            <input
              style={{
                paddingRight:
                  profileType === FLOW_TYPE_SCREENING ? "85px" : "10px",
                position: "relative",
              }}
              value={selectedNodeInfo?.data?.buttons[index].titleText}
              onChange={(e) => {
                if (e.target.value.length > 120) {
                  return;
                }

                const newButtons = selectedNodeInfo?.data?.buttons;
                newButtons[index].titleText = e.target.value;
                updateNode(button?.buttonNodeId, {
                  ...button,
                  titleText: e.target.value,
                });
                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  buttons: newButtons,
                });
              }}
              placeholder="Choice"
              readOnly={!isEditable}
              required
            />
            {(selectedNodeInfo?.data?.buttons[index].titleText === "" ||
              selectedNodeInfo?.data?.buttons[index].titleText === null) && (
              <Tooltip title="This value is required.">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  style={{
                    color: "#ffcc00",
                    fontSize: "18px",
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                  }}
                />
              </Tooltip>
            )}
          </div>
          <input
            style={{
              paddingRight:
                profileType === FLOW_TYPE_SCREENING ? "85px" : "10px",
              marginTop: "10px",
            }}
            value={selectedNodeInfo?.data?.buttons[index].optionValue}
            onChange={(e) => {
              if (e.target.value.length > 120) {
                return;
              }

              const newButtons = selectedNodeInfo?.data?.buttons;
              updateNode(button?.buttonNodeId, {
                ...button,
                optionValue: e.target.value,
              });
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                buttons: newButtons,
              });
              newButtons[index].optionValue = e.target.value;
            }}
            placeholder="Value"
            readOnly={!isEditable}
          />
          {/* {isEditable && profileType === FLOW_TYPE_SCREENING && (
            <div className="icon-container">
              <MoreVertical onClick={() => setIsOpen(!isOpen)} />
            </div>
          )} */}
          <div className="absolute-select" ref={ref}>
            <Select
              options={buttonOptions}
              className="select-container"
              placeholder="Select date format"
              key={selectedNodeInfo?.id}
              menuIsOpen={isOpen}
              value={buttonOptions?.find(
                (item) => item.value === button?.assessmentValue
              )}
              onChange={(e) => {
                setIsOpen(false);

                const newButtons = selectedNodeInfo?.data?.buttons;
                newButtons[index].assessmentValue = e.value;

                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  buttons: newButtons,
                });
                updateNode(button?.buttonNodeId, {
                  ...button,
                  assessmentValue: e.value,
                });
              }}
              classNames={{
                control: () => "custom-controller",
                menu: () => "custom-menu",
              }}
              isDisabled={!isEditable}
            />
          </div>
          {/* {profileType === FLOW_TYPE_SCREENING && (
            <div className="label-item">{button?.assessmentValue}</div>
          )} */}
          <span
            style={{
              marginTop: "5px",
            }}
          >
            {button.buttonType === BUTTON_TYPE.BRANCH_BUTTON
              ? "Add new blocks from the port to configure it"
              : "Click on the URL icon to add the URL"}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {isEditable && profileType === FLOW_TYPE_SCREENING && (
            <div className="icon-container">
              <MoreVertical onClick={() => setIsOpen(!isOpen)} />
            </div>
          )}
          {selectedNodeInfo?.data?.buttons?.length > 1 && isEditable ? (
            <img
              src={DeleteIcon}
              alt="deleteIcon"
              onClick={async () => {
                const newButtons = selectedNodeInfo?.data?.buttons;
                newButtons.splice(index, 1);
                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  buttons: newButtons,
                });
                await deleteNode(button?.buttonNodeId);
                saveData();
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

function ButtonsNodeEditor() {
  const {
    selectedNodeInfo,
    updateNode,
    addButtonNode,
    variableList,
    atsData,
    isEditable,
    setNewAddedNode,
    saveData,
  } = useContext(NodeContext);
  const [displayError, setDisplayError] = useState(
    selectedNodeInfo.data.isVariableNameAlreadyUsed ?? false
  );

  // const [itemsArray, setItemsArray] = useState(
  //   selectedNodeInfo?.data?.buttons.map((item) => ({
  //     ...item,
  //     id: item?.id ?? uuidv4(),
  //   }))
  // );

  const handleAddButton = useCallback(
    (buttonType) => {
      const buttonNode = {
        type: NODE_TYPE.BUTTON_NODE,
        buttonType,
        titleText: `Choice ${selectedNodeInfo?.data?.buttons?.length + 1}`,
        buttonNodeId: uuidv4(),
        url: "",
        newTab: true,
        assessmentValue: "Shortlisted",
        id: uuidv4(),
      };

      addButtonNode(selectedNodeInfo, buttonNode);
      updateNode(selectedNodeInfo.id, {
        ...selectedNodeInfo?.data,
        buttons: [...selectedNodeInfo?.data?.buttons, buttonNode],
      });
    },
    [selectedNodeInfo, addButtonNode, updateNode]
  );

  // const itemRenderer = (button, index) => (
  //   <ButtonItem
  //     button={button}
  //     index={index}
  //     key={index}
  //     itemsArray={itemsArray}
  //     setItemsArray={setItemsArray}
  //   />
  // );

  // const reorder = (list, startIndex, endIndex) => {
  //   const result = Array.from(list);
  //   const [removed] = result.splice(startIndex, 1);
  //   result.splice(endIndex, 0, removed);

  //   return result;
  // };

  // const handleOnDragEnd = (result) => {
  //   if (!result.destination) return;
  //   const itemsCopy = reorder(
  //     itemsArray,
  //     result.source.index,
  //     result.destination.index
  //   );
  //   setItemsArray(itemsCopy);
  //   updateNode(selectedNodeInfo.id, {
  //     ...selectedNodeInfo?.data,
  //     buttons: itemsCopy,
  //   });
  // };

  // useEffect(() => {
  //   setItemsArray(
  //     selectedNodeInfo?.data?.buttons.map((item) => ({
  //       ...item,
  //       id: item?.id ?? uuidv4(),
  //     }))
  //   );
  // }, [selectedNodeInfo, nodes]);

  if (!selectedNodeInfo) {
    return null;
  }

  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder={ButtonNodeEditorConstants.TITLE_INPUT_PLACEHOLDER}
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
        <p>
          Buttons allow the conversation flow to be branched into various paths
          based on user input
        </p>
      </TitleInputContainer>
      <MessageInputContainer>
        <div className="response-container">
          <p>{LABELS.SHOW_MESSAGE}</p>
        </div>
        <Editor
          editorState={getHTMLToDraft(selectedNodeInfo?.data?.message)}
          onEditorStateChange={(data) => {
            const isMessageEmpty = data.getCurrentContent().hasText();
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              message: data,
              isMessageEmpty: !isMessageEmpty,
            });
          }}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={toolbar}
          mention={{
            separator: " ",
            trigger: "{",
            suggestions: variableList,
          }}
          onBlur={() => {
            setNewAddedNode(true);
          }}
          placeholder="What is your..."
          readOnly={!isEditable}
          stripPastedStyles={true}
        />
      </MessageInputContainer>
      {/* <div> */}
      <ButtonsListContainer>
        {selectedNodeInfo?.data?.buttons?.map((button, index) => {
          return (
            <ButtonItem
              button={button}
              index={index}
              key={button?.buttonNodeId}
            />
          );
        })}
        {/* <DraggableList
            itemsArray={itemsArray}
            setItemsArray={setItemsArray}
            handleOnDragEnd={handleOnDragEnd}
            itemRenderer={itemRenderer}
            isEditable={isEditable}
            key={`${selectedNodeInfo.id}-draggableList`}
          /> */}
      </ButtonsListContainer>
      {/* </div> */}

      {selectedNodeInfo?.data?.buttons?.length < 20 && isEditable ? (
        <ActionButtonContainer>
          <button
            onClick={async () => {
              await handleAddButton(BUTTON_TYPE.BRANCH_BUTTON);
              saveData();
            }}
          >
            <img src={AddIcon} alt="addIcon" />
            Choice
          </button>
        </ActionButtonContainer>
      ) : null}
      <div
        className="response-container"
        style={{
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: '"Plus Jakarta Sans", sans-serif',
        }}
      >
        <p>Enable multiselect option</p>
        <Tooltip title="Enable multiselect options">
          <Switch
            checked={
              selectedNodeInfo?.data.isMultiSelect
                ? selectedNodeInfo?.data.isMultiSelect
                : false
            }
            onChange={(e) => {
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                isMultiSelect: e.target.checked,
              });
            }}
            disabled={!isEditable}
          />
        </Tooltip>
      </div>
      <InputContainer>
        {atsData && (
          <>
            <p>{LABELS.ATS_FIELD}</p>
            <Select
              options={atsData}
              className="select-container"
              defaultValue={""}
              isClearable
              value={atsData.find(
                (o) => o.value === (selectedNodeInfo?.data?.atsValue || "")
              )}
              onChange={(e) => {
                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  atsValue: e ? e.value : e,
                });
                setTimeout(() => {
                  saveData();
                }, 10);
              }}
              key={selectedNodeInfo?.id}
              isDisabled={!isEditable}
              placeholder="Select ATS Field"
            />
          </>
        )}
        <ResponseVariable
          updateNodeFun={updateNode}
          selectedNodeInfo={selectedNodeInfo}
          iseditable={isEditable ? "true" : undefined}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "2px",
            width: "100%",
          }}
        >
          <input
            className="input"
            placeholder="Enter Variable Name"
            value={selectedNodeInfo?.data?.variableName}
            onBlur={(e) => {
              setNewAddedNode(true);
            }}
            onChange={(e) => {
              if (e.target.value.length > 60) {
                return;
              }
              const isVariableNameAlreadyUsed = variableList.find(
                (item) => item.value === `{${e.target.value.trim()}}}`
              );
              if (isVariableNameAlreadyUsed) {
                setDisplayError(true);
              } else {
                setDisplayError(false);
              }

              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                variableName: e.target.value,
                isVariableNameAlreadyUsed: isVariableNameAlreadyUsed
                  ? true
                  : false,
              });
            }}
            readOnly={!isEditable}
          />
          {displayError && (
            <Tooltip title="This variable has already been used.">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                style={{
                  color: "#ffcc00",
                  fontSize: "18px",
                  display: "",
                  position: "absolute",
                  right: "40px",
                }}
              />
            </Tooltip>
          )}
        </div>
        <span className="note">
          You can add a variable that can be referenced later in the
          conversation.
        </span>
      </InputContainer>
    </>
  );
}

export default ButtonsNodeEditor;
