import styled from "styled-components";

export const Input = styled.input`
    color: #101010;
    padding: 6px 0px;
    font-size: 18px;
    background: #ffffff;
    line-height: normal;
    border: 0;
    border-bottom: 2px solid #afb2b3;
    width: 100%;
    font-weight: bold;
    font-family: "Plus Jakarta Sans", sans-serif;

    &:focus {
        outline: none;
    }
`;

export const TitleInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    p {
        color: #858585;
        font-size: 14px;
        text-align: left;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
    }
`;

export const MessageInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    p {
        color: #101010;
        font-size: 15px;
        text-align: left;
        margin-bottom: 4px;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }

    span.note {
        font-size: 11px;
        font-family: "Plus Jakarta Sans", sans-serif;
        color: #858585;
        margin-top: 5px;
        margin-bottom: 20px;
        text-align: left;
    }

    & > .ck {
        width: 100%;
    }

    .ck-editor__editable {
        min-height: 100px;
    }

    & > .select-container {
        width: 100%;
        text-align: left;
    }

    .wrapper-class {
        border: 1px solid #ccc;
        width: 100%;
    }
    .editor-class {
        padding: 0 0.5rem;
        overflow: unset;
    }
    .toolbar-class {
        border-bottom: 1px solid #ccc;
    }
`;

export const CheckboxContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    .itemContainer {
        display: flex;
        align-items: center;
        width: 33.33%;
        margin-bottom: 15px;

        input[type="checkbox"] {
            margin-right: 10px;
        }
    }
`;

export const InputContainer = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    p {
        color: #101010;
        font-size: 15px;
        text-align: left;
        margin-bottom: 4px;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }

    .input {
        width: 100%;
        outline: none;
        border: 1px solid rgb(133, 133, 133);
        border-radius: 4px;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 15px;
        color: rgb(16, 16, 16);
        padding: 0px 10px;
        height: 40px;
    }
`;
