/* eslint-disable default-case */
import React, { useState, useEffect, useContext } from "react";
import CreatableSelect from "react-select/creatable";
import NodeContext from "../../context/NodeContext";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export default function CreateableSelect(props) {
  const { type, placeholder } = props;
  const [inputValue, setInputValue] = useState("");
  const { variableList, selectedNodeInfo, updateNode } =
    useContext(NodeContext);

  const [value, setValue] = useState(
    type === "numbers"
      ? selectedNodeInfo?.data?.toNumbers
      : selectedNodeInfo?.data?.toEmails ?? []
  );

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  useEffect(() => {
    type === "emails"
      ? updateNode(selectedNodeInfo.id, {
          ...selectedNodeInfo?.data,
          toEmails: value,
        })
      : updateNode(selectedNodeInfo.id, {
          ...selectedNodeInfo?.data,
          toNumbers: value,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      options={variableList.map((item) => ({
        value: `{${item.value}`,
        label: item.label,
      }))}
      onChange={(newValue) => {
        setValue(newValue);
      }}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={value}
      styles={{
        control: (base) => ({
          ...base,
          textAlign: "left",
        }),
      }}
    />
  );
}
