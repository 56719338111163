import AppRoutes from "./routes";
import { ReactFlowProvider } from "reactflow";
import "./App.css";
import "reactflow/dist/style.css";
import { NodeContextProvider } from "./context/NodeContext";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./utils/helper";

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ReactFlowProvider>

          <NodeContextProvider>
            <AppRoutes />
            <ToastContainer />
          </NodeContextProvider>
        </ReactFlowProvider>
      </QueryClientProvider>
    </div >
  );
}

Array.prototype.constructor.prototype.insert = function (index, ...items) {
  this.splice(index, 0, ...items);
};

export default App;
