import styled from "styled-components";

export const BoxContainer = styled.div`
  position: absolute;
  width: 350px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgb(232 233 239 / 50%);
  z-index: 2 !important;
  position: absolute;
  top: 100%;
  max-height: 380px;
  left: 50%;
  transform: translateX(-50%) translateY(20px);
  padding: 15px 15px;
  overflow-y: auto;
`;

export const SearchBar = styled.div`
  display: flex;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #858585;
  background-color: #ffffff;
  align-items: center;
  color: #101010;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-radius: 4px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #101010;
    padding: 0 10px;
  }
`;

export const NodeItem = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
  align-items: flex-start;

  img {
    width: 30px;
  }

  p {
    color: #101010;
    border-bottom: 1px solid #afb2b3;
    width: 100%;
    text-align: left;
    padding-bottom: 10px;
    margin: 0;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    cursor: pointer;

    :hover {
      color: #2f80ed;
    }
  }
`;
