/* eslint-disable no-unused-vars */
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { flowModuleApi } from "../api/FlowModule/api";
import {
  addEdge,
  useReactFlow,
  useNodesState,
  useEdgesState,
  useNodes,
  useEdges,
} from "reactflow";
import {
  DEFAULT_VARIABLE_LIST,
  INITIAL_GAP_BETWEEN_TWO_NODES,
  NODE_TYPE,
  STARTING_NODE_ID2,
  STARTING_NODE_POSITION,
  STARTING_NODE_POSITION2,
  edgeConfig,
} from "../utils/constants";
import { v4 as uuidv4 } from "uuid";
import {
  FLOW_TYPE,
  FLOW_TYPE_SCREENING,
  defaultNodeScreeningState,
  defaultNodeState,
} from "../constants";
import {
  afterAll,
  areArraysSame,
  beforeSendNodes,
  checkAtsValueMissing,
  checkIfFlowIsComplete,
  convertToEditorState,
  debounce,
  queryClient,
  validateButtonNodes,
  validateJumpToNode,
  validateNodeMessages,
} from "../utils/helper";
import { useMutation, useQuery } from "@tanstack/react-query";
import { authStore, flowStore } from "../store/authStore";
import { toast } from "react-toastify";
import { convertToRaw } from "draft-js";
import { useRef } from "react";

const NodeContext = createContext(null);

export const NodeContextProvider = ({ children }) => {
  const [profileType, setProfileType] = useState(null);
  const initNodes = [];
  const initEdges = [];

  const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [autoSave, setAutoSave] = useState(false);
  const [buttonClick, setbuttonClick] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [atsData, setAtsData] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [newAddedNode, setNewAddedNode] = useState(false);

  const [nodeTextCounter, setNodeTextCounter] = useState({
    ...Object.keys(NODE_TYPE).reduce(
      (acc, key) => ({
        ...acc,
        [NODE_TYPE[key]]: 1,
      }),
      {}
    ),
  });
  const [viewport, setViewport] = useState(null);
  const [flowName, setFlowName] = useState("Untitled Flow");
  const [editFlowName, setEditFlowName] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loadingFlow, setIsLoading] = useState(true);
  const [visibleDeploy, setVisibleDeploy] = useState(false);
  const [atsModalVisibility, setAtsModalVisibility] = useState(false);
  const [superAdminModalVisibility, setSuperAdminModalVisibility] = useState({
    visible: false,
    id: null,
  });
  const [profileQuestionOptions, setProfileQuestionOptions] = useState([]);
  const [FlowType, setFlowType] = useState(null);
  const flowInstance = useReactFlow();
  const reactFlowWrapperRef = useRef(null);

  const { id, flowType, isEdit, isSuperAdmin: isSuperAdminFlag } = authStore();
  const { setFlowData } = flowStore();

  const { isFetching } = useQuery(
    ["flow-data", id],
    async () => {
      if (!id) return null;
      setAutoSave(false);
      setFlowType(flowType);
      setIsEditable(isEdit);
      setIsSuperAdmin(isSuperAdminFlag);
      const flow =
        flowType === FLOW_TYPE.PROFILE
          ? await flowModuleApi.getProfileData(id)
          : await flowModuleApi.getTemplateFlow(id);
      let { data, profileName, profileType, profiles: profilesData } = flow;
      let { nodes, edges, viewport } = data;
      const { x = 0, y = 0, zoom = 1 } = viewport;
      setProfileType(profileType);
      if (profileType !== FLOW_TYPE_SCREENING) {
        nodes = nodes.filter((node) => node.id !== STARTING_NODE_ID2);
      }
      if (nodes.length === 0 && edges.length === 0) {
        profileType === FLOW_TYPE_SCREENING
          ? setNodes([STARTING_NODE_POSITION, STARTING_NODE_POSITION2])
          : setNodes([STARTING_NODE_POSITION]);
        setFlowData(convertToEditorState(nodes), initEdges);
        // setNodes(convertToEditorState(nodes));
        setEdges(initEdges);
        setIsLoading(false);
        return flow;
      }
      setFlowData(convertToEditorState(nodes), edges);
      setNodes(convertToEditorState(nodes));
      if (data.atsProperties && data.atsProperties.length > 0) {
        let atsData = data.atsProperties.map((item) => {
          return {
            label: item.displayPropertyName,
            value: item.propertyPath,
          };
        });
        setAtsData(atsData);
      }
      if (profilesData && profilesData.length > 0) {
        setProfiles(profilesData);
      }
      setEdges(
        edges?.map((item) => ({
          ...item,
          type: "custom",
          ...edgeConfig,
        }))
      );
      setViewport({ x, y, zoom });
      setFlowName(profileName);
      setIsLoading(false);

      setNewAddedNode(true);
      return flow;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  const { mutate, isLoading } = useMutation(
    async (payload) => {
      const { token } = authStore.getState();
      if (!token) return null;
      try {
        const response =
          FlowType === FLOW_TYPE.PROFILE
            ? await flowModuleApi.postProfileData(token.id, payload)
            : await flowModuleApi.postTemplateFlow(token.id, payload);
        if (response?.error) {
          throw response;
        }
        return response;
      } catch (err) {
        setAutoSave(false);
        toast.error(err?.error?.errorMessage ?? "Something went wrong", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            width: "350px",
          },
        });
      }
    },
    {
      onSuccess: (data) => {
        setAutoSave(false);
        if (data) {
          setbuttonClick(null);
          if (data.chatLink) {
            const screenHeight = window.screen.height;
            const windowFeatures = `width=600,height=${screenHeight}`;
            window.open(data.chatLink, "_blank", windowFeatures);
          } else if (data.hasOwnProperty("message")) {
            toast.success(data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            if (data.hasOwnProperty("error")) {
              toast.error(data.error.errorMessage ?? "Something went wrong", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            setFlowData(convertToEditorState(data.nodes), data.edges);
            // refetch();
          }
        }
      },
      onError: (data) => {
        setAutoSave(false);
        console.log(data.error);
      },
    },
    {
      onError: (data) => {
        setAutoSave(false);
        console.log(data.error);
      },
      retry: 0,
    }
  );

  const saveData = useCallback(() => {
    // console.log("Save data");
    if (!isEditable) return null;
    const flow = flowInstance.toObject();
    if (flow) {
      flow.nodes = beforeSendNodes(flow?.nodes || []);
      mutate({ ...flow, saveMode: 1 });
      setAutoSave(false);
    }
  }, [flowInstance, isEditable, mutate]);

  useEffect(() => {
    if (autoSave && isEditable) {
      const flow = flowInstance.toObject();
      if (flow.nodes.length > 0 && flow.edges.length > 0) {
        const time = setTimeout(() => {
          saveData();
        }, 100);
        return () => clearTimeout(time);
      }
    }
  }, [autoSave, flowInstance, isEditable, saveData]);
  // useEffect(() => {
  //   const flow = flowInstance.toObject();
  //   // console.log("Updating flow");
  //   // if (autoSave && isEditable) {
  //   if (
  //     (savedEdges?.length !== flow?.edges?.length ||
  //       savedNodes?.length !== flow?.nodes?.length) &&
  //     !isFetching &&
  //     !isLoading
  //   ) {
  //     // console.log("Updating flow API");
  //     setAutoSave(true);
  //     // saveData();
  //   }
  //   // }

  //   return () => {
  //     // console.log("Cleaning up");
  //     setAutoSave(false);
  //   };
  // }, [
  //   flowInstance,
  //   isFetching,
  //   isLoading,
  //   savedEdges,
  //   savedNodes,
  //   autoSave,
  //   isEditable,
  //   edgesInstance,
  // ]);

  const onConnect = useCallback(
    (params) => {
      const { source, target, sourceHandle } = params;
      const sourceType = nodes.find((item) => item.id === source)?.type;
      const targetType = nodes.find((item) => item.id === target)?.type;

      if (
        sourceType === NODE_TYPE.BUTTON_NODE &&
        targetType === NODE_TYPE.BUTTON_NODE
      ) {
        return;
      }
      if (source === target) return;

      const isEdgeAlreadyTaken = edges.find(
        (item) => item.source === source && sourceHandle === "bottom"
      );

      if (!isEdgeAlreadyTaken) {
        setEdges((eds) =>
          addEdge({ ...params, type: "custom", ...edgeConfig }, eds)
        );
      }
    },
    [nodes, edges, setEdges]
  );
  const onSave = useCallback(async () => {
    if (!isEditable) return null;
    if (flowInstance) {
      setbuttonClick("save");
      const flow = flowInstance.toObject();
      flow.nodes = beforeSendNodes(flow?.nodes || []);
      mutate({ ...flow, saveMode: 1 });
    }
  }, [flowInstance, isEditable, mutate]);

  const onPreview = useCallback(async () => {
    if (!isEditable) return;
    if (flowInstance) {
      setbuttonClick("preview");
      const flow = flowInstance.toObject();
      flow.nodes = beforeSendNodes(flow?.nodes || []);
      mutate({ saveMode: 2 });
    }
  }, [isEditable, flowInstance, mutate]);

  const checkIfMissingAtsValues = useCallback(async () => {
    const focusNodeTransition = (node) => {
      const { x, y } = node.position;
      const { width, height } =
        reactFlowWrapperRef.current.getBoundingClientRect();
      const nodeX = width / 2 - x - 100;
      const nodeY = height / 2 - y - 50;
      flowInstance.setViewport(
        { x: nodeX, y: nodeY, zoom: 1 },
        { duration: 800 }
      );
      setSelectedNodeId(null);
      return;
    };
    const { isComplete, errNode } = await checkIfFlowIsComplete(nodes, edges);
    if (!isComplete) {
      toast.error(
        "The chat is not completed correctly. The final node in the tree has to be either End Chat, Go To node or Go To Profile node.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            width: "350px",
          },
        }
      );
      focusNodeTransition(errNode);
      return;
    }
    const { isJumpToNodeValid, jumpToNode, message } =
      validateJumpToNode(nodes);
    if (!isJumpToNodeValid) {
      toast.error(
        `Please select the ${message} for \n` + jumpToNode.data.titleText,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      focusNodeTransition(jumpToNode);
      return;
    }
    const { isNodeMessageValid, nodeMessage } = validateNodeMessages(nodes);
    // console.log(nodeMessage);
    if (!isNodeMessageValid) {
      toast.error(
        "Please enter the message for \n" + nodeMessage.data.titleText,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      focusNodeTransition(nodeMessage);
      return;
    }
    const { isButtonNodeValid, buttonNode } = validateButtonNodes(nodes);
    if (!isButtonNodeValid) {
      toast.error(
        "Please provide proper Option Title for \n" + buttonNode.data.titleText,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      focusNodeTransition(buttonNode);
      return;
    }

    const { isAtsValueMissing } = checkAtsValueMissing(nodes);
    if (isAtsValueMissing) {
      setAtsModalVisibility(true);
      return;
    }
    onPreview();
  }, [edges, flowInstance, nodes, onPreview]);

  const onPublish = useCallback(async () => {
    if (!isEditable) return;
    if (flowInstance) {
      setbuttonClick("publish");
      const flow = flowInstance.toObject();
      flow.nodes = beforeSendNodes(flow?.nodes || []);
      mutate({ ...flow, saveMode: 3 });
    }
    setVisibleDeploy(false);
  }, [flowInstance, isEditable, mutate]);

  const onRestore = useCallback(async () => {
    if (!isEditable) return;
    setbuttonClick("delete");
    setNodes(
      profileType === FLOW_TYPE_SCREENING
        ? defaultNodeScreeningState.nodes
        : defaultNodeState.nodes
    );
    setEdges([]);
    setViewport({ x: 0, y: 0, zoom: 1 });
    mutate({ ...nodes, saveMode: 4 });
    setVisible(false);
  }, [isEditable, setNodes, profileType, setEdges, mutate, nodes]);

  const handleNodeClick = useCallback(
    (nodeId) => {
      const nodeType =
        nodeId && nodes.filter((node) => node.id === nodeId)[0].type;
      if (
        nodeType !== NODE_TYPE.BUTTON_NODE &&
        nodeId !== selectedNodeId &&
        nodeId !== NODE_TYPE.STARTING_NODE
      ) {
        setSelectedNodeId(nodeId);
      }
      setNodes((nodes) => {
        return nodes.map((node) => {
          if (node.id === nodeId) {
            node.data.selected = true;
            node.zIndex = 10;
          } else {
            node.data.selected = false;
            node.data.active = false;
            node.zIndex = 0;
          }

          return node;
        });
      });
    },
    [nodes, selectedNodeId, setNodes]
  );

  const addButtonNode = useCallback(
    (parentNode, data = parentNode?.data?.buttons[0]) => {
      const buttonNodeId = data?.buttonNodeId;

      const lastButtonNodeId =
        parentNode?.data?.buttons[parentNode?.data?.buttons?.length - 1]
          ?.buttonNodeId;
      const lastButtonNode = nodes.find((item) => item.id === lastButtonNodeId);

      const newButtonNode = {
        id: buttonNodeId,
        type: NODE_TYPE.BUTTON_NODE,
        position: {
          x: lastButtonNode
            ? lastButtonNode?.position?.x + 200
            : parentNode?.xPos ?? parentNode?.position.x,
          y: parentNode?.yPos
            ? parentNode?.yPos + INITIAL_GAP_BETWEEN_TWO_NODES
            : parentNode?.position.y + INITIAL_GAP_BETWEEN_TWO_NODES,
        },
        data: {
          buttonNodeId,
          isTop: true,
          isBottom: true,
          label: `Element ${buttonNodeId}`,
          selected: false,
          ...data,
        },
      };
      flowInstance.addNodes(newButtonNode);
      setEdges((e) => [
        ...e,
        {
          id: `reactflow__edge-${buttonNodeId}-${parentNode.id}-bottom`,
          source: parentNode.id,
          sourceHandle: "bottom",
          target: buttonNodeId,
          targetHandle: "top",
          type: "custom",
          ...edgeConfig,
        },
      ]);
    },
    [flowInstance, setEdges, nodes]
  );

  const addCardNode = useCallback(
    (parentNode, data = parentNode?.data?.cards[0]) => {
      const cardNodeId = data?.cardNodeId;

      const lastCardNodeId =
        parentNode?.data?.cards[parentNode?.data?.cards?.length - 1]
          ?.cardNodeId;
      const lastCardNode = nodes.find((item) => item.id === lastCardNodeId);

      const newCardNode = {
        id: cardNodeId,
        type: NODE_TYPE.CARD_NODE,
        position: {
          x: lastCardNode
            ? lastCardNode?.position?.x + 200
            : parentNode?.xPos ?? parentNode?.position.x,
          y: parentNode?.yPos
            ? parentNode?.yPos + INITIAL_GAP_BETWEEN_TWO_NODES
            : parentNode?.position.y + INITIAL_GAP_BETWEEN_TWO_NODES,
        },
        data: {
          cardNodeId,
          isTop: true,
          isBottom: true,
          label: `Element ${cardNodeId}`,
          selected: false,
          ...data,
        },
      };
      flowInstance.addNodes(newCardNode);
      setEdges((e) => [
        ...e,
        {
          id: `reactflow__edge-${cardNodeId}-${parentNode.id}-bottom`,
          source: parentNode.id,
          sourceHandle: "bottom",
          target: cardNodeId,
          targetHandle: "top",
          type: "custom",
          ...edgeConfig,
        },
      ]);

      addButtonNode(newCardNode);
    },
    [flowInstance, setEdges, nodes, addButtonNode]
  );

  const onAdd = useCallback(
    (curNodeData, nodeData) => {
      const id = uuidv4();
      setNodeTextCounter((c) => ({
        ...c,
        [nodeData.type]: c[nodeData.type] + 1,
      }));
      const newNode = {
        id,
        type: nodeData.type,
        position: {
          x: curNodeData?.xPos,
          y: curNodeData?.yPos + INITIAL_GAP_BETWEEN_TWO_NODES,
        },
        data: {
          isTop: true,
          isBottom: true,
          label: `Element ${id}`,
          selected: false,
          ...nodeData,
        },
      };
      flowInstance.addNodes(newNode);
      setEdges((e) => [
        ...e,
        {
          id: `reactflow__edge-${id}-${curNodeData.id}-${curNodeData.sourcePosition}`,
          source: curNodeData.id,
          sourceHandle: curNodeData.sourcePosition,
          target: id,
          targetHandle: curNodeData.targetPosition,
          type: "custom",
          ...edgeConfig,
        },
      ]);

      if (nodeData.type === NODE_TYPE.BUTTONS_NODE) {
        addButtonNode(newNode);
      }

      if (nodeData.type === NODE_TYPE.CAROUSEL_NODE) {
        addCardNode(newNode);
      }
    },
    [flowInstance, setEdges, addButtonNode, addCardNode]
  );

  const updateNode = useCallback(
    (nId, nodeData) => {
      setNodes((nodes) => {
        const newNodes = nodes.map((node) => {
          if (node.id === nId) {
            node.data = { ...nodeData };
          }
          return node;
        });
        return newNodes;
      });
    },
    [setNodes]
  );

  const updateNodeData = debounce((nId, nodeData) => {
    setNodes((nodes) => {
      const newNodes = nodes.map((node) => {
        if (node.id === nId) {
          node.data = { ...nodeData };
        }
        return node;
      });
      return newNodes;
    });
  }, 50);

  const deleteNode = useCallback(
    (nodeId) => {
      if (nodeId === selectedNodeId) {
        setSelectedNodeId(null);
      }

      const node = nodes.find((item) => item.id === nodeId);
      if (node && node.type === NODE_TYPE.BUTTONS_NODE) {
        node.data.buttons.forEach((button) => {
          deleteNode(button.buttonNodeId);
        });
      }

      if (node && node.type === NODE_TYPE.CAROUSEL_NODE) {
        node.data.cards.forEach((card) => {
          deleteNode(card.cardNodeId);
        });
      }

      setNodes((nodes) => {
        const newNodes = nodes.filter((node) => node.id !== nodeId);
        return newNodes;
      });
      setEdges((edges) => {
        const newEdges = edges.filter(
          (edge) => edge.source !== nodeId && edge.target !== nodeId
        );
        return newEdges;
      });
    },
    [selectedNodeId, nodes, setNodes, setEdges]
  );

  const addButtonToCardNode = useCallback(
    (cardId, buttonData) => {
      const cardNode = nodes.find((item) => item.id === cardId);
      if (cardNode) {
        addButtonNode(cardNode, buttonData);
        updateNode(cardNode?.id, {
          ...cardNode?.data,
          buttons: [...cardNode?.data?.buttons, buttonData],
        });
      }
    },
    [nodes, addButtonNode, updateNode]
  );

  const selectedNodeInfo = useMemo(
    () => nodes.find((node) => node.id === selectedNodeId),
    [selectedNodeId, nodes]
  );

  const oldVariableList = useMemo(() => {
    // setNewAddedNode(true);
    return nodes.reduce((acc, node) => {
      if (node.data?.type === NODE_TYPE.SERVICE_CALL_NODE) {
        // if (node.data?.variableName) {
        //   return [
        //     ...acc,
        //     {
        //       text: (
        //         <>
        //           <span className="var-text">{node.data?.variableName}</span>
        //           <span className="var-type">Custom</span>
        //         </>
        //       ),
        //       value: `{${node.data?.variableName}}}`,
        //       label: node.data?.variableName,
        //       type: "custom",
        //       id: node.id,
        //     },
        //   ];
        // }

        const serviceCallResponseFields = node.data?.saveResponseFields
          ? node.data?.saveResponseFields.filter(
              (item) => item.key.trim() && item.value.trim()
            )
          : null;

        if (serviceCallResponseFields && serviceCallResponseFields.length > 0) {
          return [
            ...acc,
            ...serviceCallResponseFields?.map((item) => {
              return {
                text: (
                  <>
                    <span className="var-text">{item.key}</span>
                    <span className="var-type">Custom</span>
                  </>
                ),
                value: `{${item.key}}}`,
                label: item.key,
                type: "custom",
                id: item.id,
              };
            }),
          ];
        }
      } else {
        if (node.data?.variableName) {
          return [
            ...acc,
            {
              label: node.data?.variableName,
              value: `{${node.data?.variableName}}}`,
              type: "custom",
              text: (
                <>
                  <span className="var-text">{node.data?.variableName}</span>
                  <span className="var-type">Custom</span>
                </>
              ),
              id: node.id,
            },
          ];
        }

        const serviceCallResponseFields = node.data?.saveResponseFields
          ? node.data?.saveResponseFields.filter(
              (item) => item.key.trim() && item.value.trim()
            )
          : null;

        if (serviceCallResponseFields && serviceCallResponseFields.length > 0) {
          return [
            ...acc,
            ...node.data?.saveResponseFields?.map((item) => {
              return {
                label: item?.key,
                value: `{${item.key}}}`,
                type: "custom",
                text: (
                  <>
                    <span className="var-text">{item.key}</span>
                    <span className="var-type">Custom</span>
                  </>
                ),
                id: item.id,
              };
            }),
          ];
        }
      }
      return acc;
    }, DEFAULT_VARIABLE_LIST);
  }, [nodes]);

  const variableList = useMemo(() => {
    // setNewAddedNode(true);
    // console.log("Variable List updated", oldVariableList);
    return oldVariableList;
  }, [oldVariableList]);

  useEffect(() => {
    const updateNodeData = (node) => {
      let editorState = null;
      if (node.data.messages) {
        editorState = convertToRaw(
          node.data.messages?.[0]?.text?.getCurrentContent()
        );
      } else if (node?.data?.message) {
        editorState = convertToRaw(node?.data?.message?.getCurrentContent());
      } else if (node?.message) {
        console.log(node?.message);
        editorState = convertToRaw(node?.message?.getCurrentContent());
      }
      if (!editorState) return node;
      const entityMap = editorState?.entityMap;
      const val = Object.values(entityMap).map((entity) => {
        if (entity.type !== "MENTION") return false;
        const toCheck = entity.data.value;
        const s = oldVariableList.filter(
          (variable) =>
            // variable.type === "custom" &&
            variable.value === toCheck
        );
        return s.length === 0 ? true : false;
      });
      const isVariableMissing = val.includes(true);
      return {
        ...node,
        data: {
          ...node.data,
          isVariableMissing,
        },
      };
    };

    const timeOut = setTimeout(() => {
      if (!newAddedNode) return;
      const newNodes = nodes.map(updateNodeData);
      setNodes(newNodes);
      setNewAddedNode(false);
    }, 100);

    return () => {
      clearTimeout(timeOut);
    };
  }, [newAddedNode, nodes, oldVariableList, setNodes, variableList]);

  const deleteEdge = useCallback(
    (id) => {
      const foundEdge = edges.find((item) => item.id === id);

      const { target, source } = foundEdge;
      const targetNode = nodes.find((node) => node.id === target);
      const sourceNode = nodes.find((node) => node.id === source);
      if ([NODE_TYPE.BUTTON_NODE].includes(targetNode?.type)) {
        let data = sourceNode?.data;
        if (data?.buttons?.length > 1 || data?.cards?.length > 1) {
          setEdges((o) => o.filter((item) => item.id !== id));
          deleteNode(target);
        }

        if (targetNode?.type === NODE_TYPE.BUTTON_NODE) {
          data.buttons = data.buttons?.filter(
            (item) => item.buttonNodeId !== target
          );

          if (sourceNode?.type === NODE_TYPE.CARD_NODE) {
            const carouselNodeId = edges.find(
              (item) => item.target === sourceNode?.id
            )?.source;
            const carouselNode = nodes.find(
              (item) => item.id === carouselNodeId
            );

            const data2 = carouselNode?.data;
            const indexToUpdate = data2.cards.findIndex(
              (item) => item.cardNodeId === sourceNode?.id
            );
            if (indexToUpdate !== -1) {
              data2.cards[indexToUpdate].buttons = data2.cards[
                indexToUpdate
              ].buttons?.filter((item) => item.buttonNodeId !== target);
            }
            updateNode(carouselNode?.id, data2);
          }
        } else {
          data.cards = data.cards?.filter((item) => item.cardNodeId !== target);
        }
        updateNode(source, data);
      } else {
        setEdges((o) => o.filter((item) => item.id !== id));
      }
    },
    [setEdges, edges, nodes, deleteNode, updateNode]
  );

  const { mutate: getProfileIdQuestions } = useMutation(
    (profileId) => {
      return flowModuleApi.getProfileQuestions(profileId);
    },
    {
      onSuccess: (data) => {
        if (!data.isSuccess) {
          setProfileQuestionOptions([]);
          return toast.error(data?.data?.errorMessage);
        } else {
          setProfileQuestionOptions(
            data.data.map((item) => {
              return {
                value: item.qId,
                label: item.question,
              };
            })
          );
          return data;
        }
      },
      onError: (data) => {
        console.log(data);
      },
    }
  );

  return (
    <NodeContext.Provider
      value={{
        onNodesChange,
        onEdgesChange,
        onConnect,
        onAdd,
        updateNode,
        updateNodeData,
        selectedNodeInfo,
        handleNodeClick,
        nodes,
        edges,
        deleteNode,
        nodeTextCounter,
        addButtonNode,
        onSave,
        onPreview,
        onPublish,
        onRestore,
        flowName,
        setFlowName,
        addCardNode,
        addButtonToCardNode,
        deleteEdge,
        editFlowName,
        setEditFlowName,
        variableList,
        // variableListForCondition,
        visible,
        setVisible,
        loadingFlow,
        isLoading,
        buttonClick,
        saveData,
        setAutoSave,
        visibleDeploy,
        setVisibleDeploy,
        atsData,
        viewport,
        FlowType,
        isEditable,
        setIsEditable,
        isSuperAdmin,
        setIsSuperAdmin,
        profileType,
        setNewAddedNode,
        setViewport,
        flowInstance,
        setNodes,
        setSelectedNodeId,
        reactFlowWrapperRef,
        atsModalVisibility,
        setAtsModalVisibility,
        checkIfMissingAtsValues,
        profiles,
        superAdminModalVisibility,
        setSuperAdminModalVisibility,
        getProfileIdQuestions,
        profileQuestionOptions,
        setProfileQuestionOptions,
      }}
    >
      {children}
    </NodeContext.Provider>
  );
};

export default NodeContext;
