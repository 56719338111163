import { authStore } from "../store/authStore";

let API_URL_NEW = "";
var environment = "LOCAL";
if (window.location.origin.includes("flowbuilder.smartai.com.au")) environment = "PROD";
if (window.location.origin.includes("flowbuilder.smartai.co.uk")) environment = "PRODUK";
switch (environment) {
    case "PROD": {
        API_URL_NEW = "https://api.smartai.com.au/api";
        break;
    }
    case "PRODUK": {
        API_URL_NEW = "https://api.smartai.co.uk/api";
        break;
    }
    default:{
        API_URL_NEW = "https://portaluat.smartai.com.au/api";
        break;
    }
}

class ApiUtil {

    getNew(url) {
        const token = authStore.getState().token;
        const requestIdentity = authStore.getState().requestIdentity;
        return fetch(`${API_URL_NEW}/${url}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token?.token ?? token,
                'Request_Identity': token?.requestIdentity ?? requestIdentity,
            }
        })
        .then(response => response.json())
        .catch(error => console.log(error));
    }
    
    patch(url, data, file = false) {
        const { token } = authStore.getState();
        return fetch(`${API_URL_NEW}/${url}`, {
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': file ? 'multipart/form-data' : 'application/json',
                'Authorization': token.token,
                'Request_Identity': token.requestIdentity,
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .catch(error => console.log(error));
    }

    
    post(url, data, file = false) {
        const { token } = authStore.getState();
        return fetch(`${API_URL_NEW}/${url}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': file ? 'multipart/form-data' : 'application/json',
                'Authorization': token.token,
                'Request_Identity': token.requestIdentity,
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .catch(error => console.log(error));
    }
}

export default ApiUtil;