import React from "react";
import {
  UnauthorizedPageButton,
  UnauthorizedPageContainer,
  UnauthorizedPageContent,
  UnauthorizedPageSubtitle,
  UnauthorizedPageTitle,
} from "./UnauthorizedPage.styled";

function UnauthorizedPage() {
  return (
    <>
      <UnauthorizedPageContainer>
        <UnauthorizedPageContent>
          <div>
            <UnauthorizedPageTitle>404</UnauthorizedPageTitle>
          </div>
          <div>
            <UnauthorizedPageTitle>Page Not Found</UnauthorizedPageTitle>
            <UnauthorizedPageSubtitle>
              Unable to load the requested page or the session id is not valid
            </UnauthorizedPageSubtitle>
          </div>
          <UnauthorizedPageButton
            onClick={() =>
              (window.location.href = "https://app.smartai.com.au")
            }
          >
            Go to Home
          </UnauthorizedPageButton>
        </UnauthorizedPageContent>
      </UnauthorizedPageContainer>
    </>
  );
}

export default UnauthorizedPage;
