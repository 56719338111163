import {
  ActionButtonContainer,
  ButtonsListContainer,
  CardsListContainer,
  ImagePicker,
  Input,
  InputContainer,
  MessageInputContainer,
  TitleInputContainer,
} from "./CarouselNodeEditor.styled";
import NodeContext from "../../context/NodeContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AddIcon from "../../assets/images/addIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import { v4 as uuidv4 } from "uuid";
import {
  buttonOptions,
  BUTTON_TYPE,
  NODE_TYPE,
  toolbar,
} from "../../utils/constants";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ReactComponent as MoreVertical } from "../../assets/images/moreVertical.svg";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import { getHTMLToDraft } from "../../utils/helper";
import { flowModuleApi } from "../../api/FlowModule/api";
import ResponseVariable from "../ResponseVariable/index";
import { LABELS } from "../../utils/labels";

function CardItem({ card, index, setSelectedCardNode }) {
  const { selectedNodeInfo, updateNode, deleteNode, isEditable } =
    useContext(NodeContext);

  return (
    <div className="card" onClick={() => setSelectedCardNode(card)}>
      <p>{card?.titleText}</p>
      {selectedNodeInfo?.data?.cards?.length > 1 && isEditable ? (
        <img
          src={DeleteIcon}
          alt="deleteIcon"
          onClick={(e) => {
            e.stopPropagation();
            const newCards = selectedNodeInfo?.data?.cards;
            newCards.splice(index, 1);
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              cards: newCards,
            });
            deleteNode(card?.cardNodeId);
          }}
        />
      ) : null}
    </div>
  );
}

function ButtonItem({ button, index, selectedCardNode }) {
  const { selectedNodeInfo, updateNode, deleteNode, isEditable } =
    useContext(NodeContext);

  const [isOpen, setIsOpen] = useState();
  const ref = useRef();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (!isOpen) return;

    setTimeout(() => {
      window.addEventListener("click", handleClose);
    }, 300);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [ref, isOpen, handleClose]);

  return (
    <div className="item">
      <div className="input-container">
        <div className="inp-cont">
          <input
            style={{
              paddingRight: "85px",
            }}
            value={button.titleText}
            onChange={(e) => {
              const newButtons = selectedCardNode?.buttons;
              newButtons[index].titleText = e.target.value;

              updateNode(selectedCardNode.cardNodeId, {
                ...selectedCardNode,
                buttons: newButtons,
              });
              updateNode(button?.buttonNodeId, {
                ...button,
                titleText: e.target.value,
              });
            }}
            readOnly={!isEditable}
          />
          {isEditable && (
            <div className="icon-container">
              <MoreVertical onClick={() => setIsOpen(!isOpen)} />
            </div>
          )}
          <div className="absolute-select" ref={ref}>
            <Select
              options={buttonOptions}
              className="select-container"
              placeholder="Select date format"
              key={selectedNodeInfo?.id}
              menuIsOpen={isOpen}
              value={buttonOptions?.find(
                (item) => item.value === button?.assessmentValue
              )}
              onChange={(e) => {
                setIsOpen(false);

                const newButtons = selectedCardNode?.buttons;
                newButtons[index].assessmentValue = e.value;

                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  buttons: newButtons,
                });
                updateNode(button?.buttonNodeId, {
                  ...button,
                  assessmentValue: e.value,
                });
              }}
              classNames={{
                control: () => "custom-controller",
                menu: () => "custom-menu",
              }}
              isDisabled={!isEditable}
            />
          </div>
          <div className="label-item">{button?.assessmentValue}</div>
        </div>
        {selectedCardNode?.buttons?.length > 1 && isEditable ? (
          <img
            src={DeleteIcon}
            alt="deleteIcon"
            onClick={() => {
              const newButtons = selectedCardNode?.buttons;
              newButtons.splice(index, 1);
              updateNode(selectedCardNode.cardNodeId, {
                ...selectedCardNode,
                buttons: newButtons,
              });
              deleteNode(button?.buttonNodeId);
            }}
          />
        ) : null}
      </div>
      <span>
        {button.buttonType === BUTTON_TYPE.BRANCH_BUTTON
          ? "Add new blocks from the port to configure it"
          : "Click on the URL icon to add the URL"}
      </span>
    </div>
  );
}

function CarouselNodeEditor() {
  const ref = useRef();
  const {
    selectedNodeInfo,
    updateNode,
    addCardNode,
    addButtonToCardNode,
    variableList,
    saveData,
    atsData,
    isEditable,
    setNewAddedNode,
  } = useContext(NodeContext);

  const [showMsg, setShowMsg] = useState("");

  const [selectedCardNode, setSelectedCardNode] = useState(null);

  const handleAddCard = useCallback(() => {
    const buttonNode = {
      type: NODE_TYPE.BUTTON_NODE,
      buttonType: BUTTON_TYPE.BRANCH_BUTTON,
      titleText: `Choice 1`,
      buttonNodeId: uuidv4(),
      url: "",
      newTab: true,
      assessmentValue: "",
    };

    const cardNode = {
      type: NODE_TYPE.CARD_NODE,
      message: "",
      titleText: `Card ${selectedNodeInfo?.data?.cards?.length + 1}`,
      cardNodeId: uuidv4(),
      buttons: [buttonNode],
      image: "",
    };

    addCardNode(selectedNodeInfo, cardNode);
    updateNode(selectedNodeInfo.id, {
      ...selectedNodeInfo?.data,
      cards: [...selectedNodeInfo?.data?.cards, cardNode],
    });
  }, [updateNode, addCardNode, selectedNodeInfo]);

  const handleAddButton = useCallback(() => {
    const buttonNode = {
      type: NODE_TYPE.BUTTON_NODE,
      buttonType: BUTTON_TYPE.BRANCH_BUTTON,
      titleText: `Choice ${selectedCardNode?.buttons?.length + 1}`,
      buttonNodeId: uuidv4(),
      url: "",
      newTab: true,
      assessmentValue: "",
    };

    addButtonToCardNode(selectedCardNode?.cardNodeId, buttonNode);
    setSelectedCardNode((o) => ({
      ...o,
      buttons: [...o.buttons, buttonNode],
    }));

    const data = selectedNodeInfo?.data;
    const cardIndex = data.cards.findIndex(
      (item) => item.cardNodeId === selectedCardNode?.cardNodeId
    );
    data.cards[cardIndex].buttons = [
      ...data.cards[cardIndex].buttons,
      buttonNode,
    ];
    updateNode(selectedNodeInfo?.id, data);
  }, [addButtonToCardNode, selectedCardNode, updateNode, selectedNodeInfo]);

  if (!selectedNodeInfo) {
    return null;
  }

  if (selectedCardNode) {
    return (
      <>
        <ImagePicker
          iseditable={isEditable ? "true" : undefined}
          onClick={(e) => {
            e.preventDefault();
            if (!isEditable) return;
            ref.current.click();
          }}
        >
          {selectedCardNode?.image ? (
            <img src={selectedCardNode?.image} alt="selectedCardNode" />
          ) : null}

          <input
            type="file"
            ref={ref}
            accept="image/png,image/jpeg,image/jpg"
            onChange={(e) => {
              if (
                !["image/png", "image/jpeg", "image/jpg"].includes(
                  e.target.files[0].type
                )
              ) {
                return;
              }

              // convert image to base64
              const reader = new FileReader();
              reader.readAsDataURL(e.target.files[0]);
              reader.onload = async () => {
                const base64 = reader.result;
                const imageToBase64 = base64.split(",")[1];
                const { fileUrl } = await flowModuleApi.uploadFile({
                  fileName: e.target.files[0].name,
                  filebase64: imageToBase64,
                });
                if (fileUrl) {
                  setShowMsg("Image uploaded successfully");
                  setTimeout(() => {
                    setShowMsg("");
                  }, 2000);
                }
                setSelectedCardNode((o) => ({
                  ...o,
                  fileUrl,
                }));
                updateNode(selectedCardNode?.cardNodeId, {
                  ...selectedCardNode,
                  fileUrl,
                });

                const newCards = selectedNodeInfo?.data?.cards;
                const index = newCards.findIndex(
                  (item) => item.cardNodeId === selectedCardNode?.cardNodeId
                );
                newCards[index].image = fileUrl;
                updateNode(selectedNodeInfo?.id, {
                  ...selectedNodeInfo?.data,
                  cards: newCards,
                });
                saveData();
              };
              reader.onerror = (error) => {
                console.log("Error: ", error);
                setShowMsg("Something went wrong");
                setTimeout(() => {
                  setShowMsg("");
                }, 2000);
              };
            }}
          />

          {/* const image = URL.createObjectURL(
                                e.target.files[0]
                            );
                            setSelectedCardNode((o) => ({
                                ...o,
                                image,
                            }));
                            updateNode(selectedCardNode?.cardNodeId, {
                                ...selectedCardNode,
                                image,
                            });

                            const newCards = selectedNodeInfo?.data?.cards;
                            const index = newCards.findIndex(
                                (item) =>
                                    item.cardNodeId ===
                                    selectedCardNode?.cardNodeId
                            );
                            newCards[index].image = image;
                            updateNode(selectedNodeInfo?.id, {
                                ...selectedNodeInfo?.data,
                                cards: newCards,
                            });
                        }}
                    /> */}
        </ImagePicker>
        {showMsg && <span>{showMsg}</span>}
        <Input
          placeholder="Card Title"
          value={selectedCardNode?.titleText}
          onChange={(e) => {
            setSelectedCardNode((o) => ({
              ...o,
              titleText: e.target.value,
            }));
            updateNode(selectedCardNode?.cardNodeId, {
              ...selectedCardNode,
              titleText: e.target.value,
            });

            const newCards = selectedNodeInfo?.data?.cards;
            const index = newCards.findIndex(
              (item) => item.cardNodeId === selectedCardNode?.cardNodeId
            );
            newCards[index].titleText = e.target.value;
            updateNode(selectedNodeInfo?.id, {
              ...selectedNodeInfo?.data,
              cards: newCards,
            });
          }}
          readOnly={!isEditable}
        />
        <MessageInputContainer>
          {/* <CKEditor
                        editor={ClassicEditor}
                        config={{
                            toolbar: [
                                "bold",
                                "italic",
                                "link",
                                "bulletedList",
                                "numberedList",
                                "blockQuote",
                            ],
                            placeholder: "What is your...",
                        }}
                        onChange={(_, editor) => {
                            const data = editor.getData();
                            setSelectedCardNode((o) => ({
                                ...o,
                                message: data,
                            }));
                            updateNode(selectedCardNode?.cardNodeId, {
                                ...selectedCardNode,
                                message: data,
                            });

                            const newCards = selectedNodeInfo?.data?.cards;
                            const index = newCards.findIndex(
                                (item) =>
                                    item.cardNodeId ===
                                    selectedCardNode?.cardNodeId
                            );
                            newCards[index].message = data;
                            updateNode(selectedNodeInfo?.id, {
                                ...selectedNodeInfo?.data,
                                cards: newCards,
                            });
                        }}
                        data={selectedCardNode?.message}
                    /> */}
          <Editor
            editorState={getHTMLToDraft(selectedCardNode?.message)}
            onEditorStateChange={(data) => {
              setSelectedCardNode((o) => ({
                ...o,
                message: data,
              }));
              updateNode(selectedCardNode?.cardNodeId, {
                ...selectedCardNode,
                message: data,
              });

              const newCards = selectedNodeInfo?.data?.cards;
              const index = newCards.findIndex(
                (item) => item.cardNodeId === selectedCardNode?.cardNodeId
              );
              newCards[index].message = data;
              updateNode(selectedNodeInfo?.id, {
                ...selectedNodeInfo?.data,
                cards: newCards,
              });
            }}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={toolbar}
            mention={{
              separator: " ",
              trigger: "{",
              suggestions: variableList,
            }}
            onBlur={() => {
              setNewAddedNode(true);
            }}
            placeholder="What is your..."
            readOnly={!isEditable}
            stripPastedStyles={true}
          />
        </MessageInputContainer>
        <ButtonsListContainer>
          {selectedCardNode?.buttons?.map((button, index) => (
            <ButtonItem
              button={button}
              index={index}
              key={button?.buttonNodeId}
              selectedCardNode={selectedCardNode}
            />
          ))}
        </ButtonsListContainer>
        {selectedCardNode?.buttons?.length < 10 && isEditable ? (
          <ActionButtonContainer>
            <button onClick={handleAddButton}>
              <img src={AddIcon} alt="addIcon" />
              Add Choice
            </button>
          </ActionButtonContainer>
        ) : null}
      </>
    );
  }

  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
        <p>
          Carousels are a set of cards which display items with CTAs to each
          card
        </p>
      </TitleInputContainer>
      <CardsListContainer>
        {selectedNodeInfo?.data?.cards?.map((card, index) => {
          return (
            <CardItem
              card={card}
              index={index}
              key={card?.cardNodeId}
              setSelectedCardNode={setSelectedCardNode}
            />
          );
        })}
      </CardsListContainer>

      {selectedNodeInfo?.data?.cards?.length < 10 && isEditable ? (
        <ActionButtonContainer>
          <button onClick={handleAddCard}>
            <img src={AddIcon} alt="addIcon" />
            Add Card
          </button>
        </ActionButtonContainer>
      ) : null}
      <InputContainer>
        {atsData && (
          <>
            <p>{LABELS.ATS_FIELD}</p>
            <Select
              options={atsData}
              className="select-container"
              defaultValue=""
              isClearable
              value={atsData.find(
                (o) => o.value === (selectedNodeInfo?.data?.atsValue || "")
              )}
              onChange={(e) => {
                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  atsValue: e ? e.value : e,
                });
                setTimeout(() => {
                  saveData();
                }, 10);
              }}
              key={selectedNodeInfo?.id}
              isDisabled={!isEditable}
              placeholder="Select ATS Field"
            />
          </>
        )}
        <ResponseVariable
          updateNodeFun={updateNode}
          selectedNodeInfo={selectedNodeInfo}
          iseditable={isEditable ? "true" : undefined}
        />
        <input
          className="input"
          placeholder="Enter Variable Name"
          value={selectedNodeInfo?.data?.variableName}
          onBlur={(e) => {
            setNewAddedNode(true);
          }}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              variableName: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
        <span className="note">
          You can add a variable that can be referenced later in the
          conversation.
        </span>
      </InputContainer>
    </>
  );
}

export default CarouselNodeEditor;
