import styled from "styled-components";

export const StyledEndNode = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background-color: white;
    border-radius: 3px;
    width: 160px;
    height: 55px;
    border: 3px solid #ef4949;
    border-radius: 10px;
    transition: box-shadow 0.2s ease-in;
    font-size: 16px;
    border-radius: 28px;
    color: #ef4949;

    .nodeBody {
        display: flex;
        width: 100%;
        padding: 0 20px;
        align-items: center;
        justify-content: start;
        gap: 10px;

        .nodeBody > img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
    }

    &:hover {
        box-shadow: 0px 0px 0px #ed2b2b, 0px 0px 0px #ed2b2b,
            0px 0px 0px #ed2b2b, 0px 0px 0px #ed2b2b;
    }

    &:hover {
        .action-btns {
            visibility: visible;
        }
    }

    position: relative;

    span {
        font-weight: bold;
        text-align: center;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 16px;
    }
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    position: absolute;
    top: 0;
    right: -50px;
    border: solid 1px #e8e9ef;
    background-color: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(232, 233, 239, 0.5);
    transition: all 1s ease;
    visibility: hidden;

    img {
        cursor: pointer;
    }
`;
