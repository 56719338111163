import {
  SidePanelBody,
  SidePanelContainer,
  SidePanelHeader,
} from "./SidePanel.styled";
import CloseIcon from "../../assets/images/closeIcon.svg";
import MessageIcon from "../../assets/images/messageIcon.svg";
import CollectInputIcon from "../../assets/images/collectInput.svg";
import ButtonIcon from "../../assets/images/buttonIcon.svg";
import FileIcon from "../../assets/images/fileIcon.svg";
import CalendarIcon from "../../assets/images/calendarIcon.svg";
import CodeBlockIcon from "../../assets/images/codeBlockIcon.svg";
import ServiceCallIcon from "../../assets/images/serviceCallIcon.svg";
import CarouselIcon from "../../assets/images/carouselIcon.svg";
import ConditionalIcon from "../../assets/images/conditional.svg";
import JumpToIcon from "../../assets/images/gotoicon.svg";
import JumpToPurpleIcon from "../../assets/images/gotoicon-purple.svg";
import EmailIcon from "../../assets/images/emailIcon.svg";
import MessageNodeEditor from "../MessageNodeEditor";
import { useContext } from "react";
import NodeContext from "../../context/NodeContext";
import { NODE_TYPE } from "../../utils/constants";
import CollectInputNodeEditor from "../CollectInputNodeEditor";
import ButtonsNodeEditor from "../ButtonsNodeEditor";
import FileUploadNodeEditor from "../FileUploadNodeEditor";
import CalendarNodeEditor from "../CalendarNodeEditor";
import CodeBlockNodeEditor from "../CodeBlockNodeEditor";
import ServiceCallNodeEditor from "../ServiceCallNodeEditor";
import CarouselNodeEditor from "../CarouselNodeEditor";
import ConditionalBlockNodeEditor from "../ConditionalBlockNodeEditor";
import JumpToNodeEditor from "../JumpToNodeEditor";
import CardNodeEditor from "../CardNodeEditor";
import EmailNodeEditor from "../EmailNodeEditor";
import JumpToProfileEditor from "../JumpToProfileEditor";

const TypeBodyMapping = {
  [NODE_TYPE.MESSAGE_NODE]: MessageNodeEditor,
  [NODE_TYPE.COLLECT_INPUT_NODE]: CollectInputNodeEditor,
  [NODE_TYPE.BUTTONS_NODE]: ButtonsNodeEditor,
  [NODE_TYPE.FILE_UPLOAD_NODE]: FileUploadNodeEditor,
  [NODE_TYPE.CALENDAR_NODE]: CalendarNodeEditor,
  [NODE_TYPE.CODE_BLOCK_NODE]: CodeBlockNodeEditor,
  [NODE_TYPE.SERVICE_CALL_NODE]: ServiceCallNodeEditor,
  [NODE_TYPE.CAROUSEL_NODE]: CarouselNodeEditor,
  [NODE_TYPE.CARD_NODE]: CardNodeEditor,
  [NODE_TYPE.CONDITIONAL_NODE]: ConditionalBlockNodeEditor,
  [NODE_TYPE.JUMP_TO_NODE]: JumpToNodeEditor,
  [NODE_TYPE.EMAIL_NODE]: EmailNodeEditor,
  [NODE_TYPE.JUMP_TO_PROFILE_NODE]: JumpToProfileEditor,
};

const TypeIconMapping = {
  [NODE_TYPE.MESSAGE_NODE]: MessageIcon,
  [NODE_TYPE.COLLECT_INPUT_NODE]: CollectInputIcon,
  [NODE_TYPE.BUTTONS_NODE]: ButtonIcon,
  [NODE_TYPE.FILE_UPLOAD_NODE]: FileIcon,
  [NODE_TYPE.CALENDAR_NODE]: CalendarIcon,
  [NODE_TYPE.CODE_BLOCK_NODE]: CodeBlockIcon,
  [NODE_TYPE.SERVICE_CALL_NODE]: ServiceCallIcon,
  [NODE_TYPE.CAROUSEL_NODE]: CarouselIcon,
  [NODE_TYPE.CARD_NODE]: CarouselIcon,
  [NODE_TYPE.CONDITIONAL_NODE]: ConditionalIcon,
  [NODE_TYPE.JUMP_TO_NODE]: JumpToIcon,
  [NODE_TYPE.JUMP_TO_PROFILE_NODE]: JumpToPurpleIcon,
  [NODE_TYPE.EMAIL_NODE]: EmailIcon,
};

function SidePanel({ setIsOpen }) {
  const { handleNodeClick, selectedNodeInfo, saveData } =
    useContext(NodeContext);

  const Body =
    TypeBodyMapping[selectedNodeInfo?.type] ?? TypeBodyMapping.messageNode;

  return (
    <SidePanelContainer $xValue={selectedNodeInfo ? 0 : 100}>
      <SidePanelHeader>
        <img
          src={TypeIconMapping[selectedNodeInfo?.type] ?? MessageIcon}
          alt="startingNode"
          width={20}
        />
        <img
          src={CloseIcon}
          alt="closeIcon"
          className="closeIcon"
          onClick={() => {
            saveData();
            handleNodeClick(null);
          }}
        />
      </SidePanelHeader>
      <SidePanelBody>
        <Body />
      </SidePanelBody>
    </SidePanelContainer>
  );
}

export default SidePanel;
