import {
  AndOrContainer,
  ConditionContainer,
  Input,
  PlusIconContainer,
  TitleInputContainer,
  ConfitionTitle,
} from "./ConditionalBlockNodeEditor.styled";
import NodeContext from "../../context/NodeContext";
import { useContext } from "react";
import { conditionOptions } from "../../utils/constants";
import { ReactComponent as DeleteIcon } from "../../assets/images/deleteIcon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/addIcon.svg";
import Select from "react-select";

function ConditionalBlockNodeEditor() {
  const { selectedNodeInfo, updateNode, variableList, isEditable } =
    useContext(NodeContext);
  // useEffect(() => {
  //   if (!isSuperAdmin) {
  //     toast.error("You don't have permission to edit this node", {
  //       position: "top-center",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       draggable: true,
  //     });
  //   }
  // }, [isSuperAdmin, selectedNodeInfo?.id, isEditable]);
  if (!selectedNodeInfo) {
    return null;
  }
  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
        <p>Conditional block lets you run flow based on some conditions</p>
      </TitleInputContainer>
      {selectedNodeInfo?.data?.conditions?.map((item, index) => (
        <div key={`CodeBlockEditorElement-${index}`}>
          <ConfitionTitle>
            <p>
              {" "}
              Condition{" "}
              {selectedNodeInfo?.data?.conditions[index]?.conditionTitle}
            </p>
            {selectedNodeInfo?.data?.conditions?.length > 1 && (isEditable) && (
              <DeleteIcon
                onClick={() => {
                  const conditions = selectedNodeInfo?.data?.conditions;

                  conditions.splice(index, 1);

                  updateNode(selectedNodeInfo.id, {
                    ...selectedNodeInfo?.data,
                    conditions,
                  });
                }}
              />
            )}
          </ConfitionTitle>
          <ConditionContainer>
            <Select
              theme={{
                borderRadius: 0,
              }}
              options={variableList}
              className="select-container"
              placeholder="Select variable name"
              value={variableList.find(
                // eslint-disable-next-line array-callback-return
                (o) => {
                  if (o.id ? o.id === item.id : `{${o.value}` === item.variableName) {
                    return o;
                  }
                }
              )}
              formatOptionLabel={(data) => (
                <div className="label-container">
                  <span>{data.label}</span>
                  <span>{data?.type || "system"}</span>
                </div>
              )}
              onChange={(e) => {
                const conditions = selectedNodeInfo?.data?.conditions;
                conditions[index].variableName = `{${e.value}`;
                conditions[index].id = e.id;

                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  conditions,
                });
              }}
              key={`variable-${selectedNodeInfo?.id}`}
              isDisabled={!isEditable}
            />
            <Select
              theme={{
                borderRadius: 0,
              }}
              options={conditionOptions}
              className="select-container"
              placeholder="Select operator"
              value={conditionOptions.find((o) => o.value === item?.condition)}
              onChange={(e) => {
                const conditions = selectedNodeInfo?.data?.conditions;
                conditions[index].condition = e.value;

                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  conditions,
                });
              }}
              key={selectedNodeInfo?.id}
              isDisabled={!isEditable}
            />
            <input
              className="input"
              placeholder="Enter value"
              value={item?.value}
              onChange={(e) => {
                const conditions = selectedNodeInfo?.data?.conditions;
                conditions[index].value = e.target.value;

                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  conditions,
                });
              }}
              readOnly={!isEditable}
            />
          </ConditionContainer>
          {index === selectedNodeInfo?.data?.conditions?.length - 1 ? null : (
            <AndOrContainer>
              <span
                className={item.andOr === "and" ? "active" : ""}
                onClick={() => {
                  if (!isEditable) return;
                  const conditions = selectedNodeInfo?.data?.conditions;
                  conditions[index].andOr = "and";

                  updateNode(selectedNodeInfo.id, {
                    ...selectedNodeInfo?.data,
                    conditions,
                  });
                }}
              >
                And
              </span>
              <span
                className={item.andOr === "or" ? "active" : ""}
                onClick={() => {
                  if (!isEditable) return;
                  const conditions = selectedNodeInfo?.data?.conditions;
                  conditions[index].andOr = "or";

                  updateNode(selectedNodeInfo.id, {
                    ...selectedNodeInfo?.data,
                    conditions,
                  });
                }}
              >
                Or
              </span>
            </AndOrContainer>
          )}
          {index === selectedNodeInfo?.data?.conditions?.length - 1 && (isEditable) &&
            selectedNodeInfo?.data?.conditions?.length < 10 ? (
            <PlusIconContainer
              onClick={() => {
                const conditions = selectedNodeInfo?.data?.conditions;
                conditions.push({
                  variableName: "",
                  condition: "",
                  value: "",
                  andOr: "and",
                  conditionTitle:
                    parseInt(
                      selectedNodeInfo?.data?.conditions.at(-1).conditionTitle
                    ) + 1,
                });
                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  conditions,
                });
              }}
            >
              <AddIcon />
            </PlusIconContainer>
          ) : null}
        </div>
      ))}
    </>
  );
}

export default ConditionalBlockNodeEditor;
