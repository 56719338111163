import { useContext, useMemo, useState } from "react";
import { Position } from "reactflow";
import NodeContext from "../../context/NodeContext";
import { ButtonNode as StyledButtonNode } from "./ButtonNode.styled";
import AddNodeSelector from "../../components/AddNodeSelector";
import { BottomEdge, TopEdge } from "../StartingNode/StartingNode.styled";
import { buttonOptions } from "../../utils/constants";

export const assessmentMapping = {
  [buttonOptions[0].value]: "#1888CB",
  [buttonOptions[1].value]: "#F0C46D",
  [buttonOptions[2].value]: "#F06C6D",
};

export const assessmentColorMapping = {};

function ButtonNode(props) {
  const { id, data } = props;
  const { titleText, assessmentValue } = data;
  const { edges, handleNodeClick, isEditable } = useContext(NodeContext);
  const [showAddBox, setShowAddBox] = useState(false);

  const isBottomEdgeConnected = useMemo(
    () =>
      edges.findIndex(
        (edge) => edge.source === id && edge.sourceHandle === "bottom"
      ) !== -1,
    [edges, id]
  );

  return (
    <>
      <TopEdge
        type="target"
        position={Position.Top}
        id="top"
        isConnectable={true}
        iseditable={isEditable ? "true": undefined}
      />

      <StyledButtonNode
        $bgColor={assessmentMapping[assessmentValue]}
        $textColor={assessmentColorMapping[assessmentValue]}
      >
        <div className="titleContainer">
          <span className="titleText">{titleText}</span>
        </div>

        {showAddBox ? (
          <AddNodeSelector nodeData={props} setShowAddBox={setShowAddBox} />
        ) : null}
      </StyledButtonNode>

      <BottomEdge
        type="source"
        position={Position.Bottom}
        id="bottom"
        isConnectable={!isBottomEdgeConnected}
        onClick={(e) => {
          e.stopPropagation();
          handleNodeClick(id);
          setShowAddBox(!showAddBox);
        }}
        iseditable={isEditable ? "true": undefined}
      />
    </>
  );
}

export default ButtonNode;
