/* eslint-disable array-callback-return */
import DOMPurify from "dompurify";
import { convertToHTML } from "draft-convert";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { QueryClient } from "@tanstack/react-query";
import { NODE_TYPE } from "./constants";

export const getDraftToHTML = (data) => {
  if (typeof data === "string") return data;
  return DOMPurify.sanitize(convertToHTML(data.getCurrentContent()));
};

export const getHTMLToDraft = (html) => {
  if (typeof html === "string") {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
    return EditorState.createEmpty();
  }
  return html;
};

const convertMentionToSpan = (entity, text) => {
  if (entity.type === "MENTION") {
    return `<span>${entity.data.text}</span>`;
  }
};

export const beforeSendNodes = (nodes) => {
  return nodes?.map((node) => {
    if (node?.data?.messages) {
      const messages = node?.data?.messages?.map((message) => {
        return {
          ...message,
          text: convertToRaw(message?.text?.getCurrentContent()),
          html: draftToHtml(
            convertToRaw(message?.text?.getCurrentContent()),
            {},
            false,
            convertMentionToSpan
          ),
        };
      });
      return { ...node, data: { ...node.data, messages } };
    } else if (node?.data?.message) {
      return {
        ...node,
        data: {
          ...node?.data,
          message: convertToRaw(node?.data?.message?.getCurrentContent()),
          html: draftToHtml(
            convertToRaw(node?.data?.message?.getCurrentContent()),
            {},
            false,
            convertMentionToSpan
          ),
        },
      };
    } else if (node?.message) {
      return {
        ...node,
        message: convertToRaw(node?.message?.getCurrentContent()),
        html: draftToHtml(
          convertToRaw(node?.message?.getCurrentContent()),
          {},
          false,
          convertMentionToSpan
        ),
      };
    }
    return node;
  });
};

export const convertToEditorState = (nodes) => {
  return nodes?.map((node) => {
    if (node?.data?.messages) {
      const messages = node?.data?.messages?.map((message) => {
        return {
          ...message,
          // variables: Object.values(message?.text?.entityMap).map((entity) => {
          //   return entity.data.value;
          // }),
          text: EditorState.createWithContent(
            convertFromRaw(message.text || "")
          ),
        };
      });
      return { ...node, data: { ...node.data, messages } };
    } else if (node?.data?.message) {
      return {
        ...node,
        data: {
          ...node?.data,
          // variables: Object.values(node?.data?.message?.entityMap).map(
          //   (entity) => {
          //     return entity.data.value;
          //   }
          // ),
          message: EditorState.createWithContent(
            convertFromRaw(node?.data?.message || "")
          ),
        },
      };
    } else if (node?.message) {
      return {
        ...node,
        // variables: Object.values(node?.message?.entityMap).map((entity) => {
        //     return entity.data.value;
        //   }),
        message: EditorState.createWithContent(
          convertFromRaw(node?.message || "")
        ),
      };
    }
    return node;
  });
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
    },
  },
});

export function areArraysSame(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  // for (let i = 0; i < arr1.length; i++) {
  //   if (arr1[i] !== arr2[i]) {
  //     return false;
  //   }
  // }
  return true;
}

export function getSizeFromBase64String(base64String) {
  let sizeInBytes = base64String.length;
  let sizeInMB = sizeInBytes / (1024 * 1024);

  return sizeInMB;
}

// export function debounce(func, wait, immediate) {
//   let timeout;
//   return function () {
//     let context = this,
//       args = arguments;
//     let later = function () {
//       timeout = null;
//       if (!immediate) func.apply(context, args);
//     };
//     let callNow = immediate && !timeout;

//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//     if (callNow) func.apply(context, args);
//   };
// }

export function debounce(fn, delay) {
  let timeoutID;
  let lastArgs;

  const run = () => {
    if (lastArgs) {
      fn(...lastArgs);
      lastArgs = undefined;
    }
  };

  const debounced = (...args) => {
    clearTimeout(timeoutID);
    lastArgs = args;
    timeoutID = window.setTimeout(run, delay);
  };

  debounced.flush = () => {
    clearTimeout(timeoutID);
    run();
  };

  return debounced;
}

export function throttle(func, limit) {
  let inThrottle;
  return function () {
    let context = this,
      args = arguments;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

// create a function that can be called multiple times but only executes once after all calls have been made
export function afterAll(fn) {
  let calls = 0;
  return function () {
    calls++;
    if (calls >= 2) {
      fn();
    }
  };
}


export function checkIfFlowIsComplete(nodes, edges) {
  let isComplete = true;
  let errNode = null;
  nodes.forEach((node) => {
    if (node.type === NODE_TYPE.JUMP_TO_NODE || node.type === NODE_TYPE.END_NODE || node.type === NODE_TYPE.JUMP_TO_PROFILE_NODE) {
      return;
    }
    if (node.type === NODE_TYPE.CONDITIONAL_NODE || node.type === NODE_TYPE.SERVICE_CALL_NODE) {
      if (!checkSpecialNodeEdges(edges, node.id)) {
        isComplete = false;
        // console.log("Flow is not complete", node);
        errNode = node;
        return;
      }
      return;
    }
    if (!isBottomEdgeConnected(edges, node.id)) {
      isComplete = false;
      // console.log("Flow is not complete", node);
      errNode = node;
      return;
    }
  });
  return { isComplete, errNode };
}

const isBottomEdgeConnected =
  (edges, id) =>
    edges.findIndex(
      (edge) => edge.source === id && edge.sourceHandle === "bottom"
    ) !== -1

const checkSpecialNodeEdges = (edges, id) => {
  const foundSuccessEdge = edges.findIndex(
    (edge) => edge.source === id && edge.sourceHandle === "bottom-success"
  ) !== -1;
  const foundFailureEdge = edges.findIndex(
    (edge) => edge.source === id && edge.sourceHandle === "bottom-failure"
  ) !== -1;
  return foundSuccessEdge && foundFailureEdge;
}

export function checkAtsValueMissing(nodes) {

  let atsValueMissingNode = null;
  let totalAtsNodes = nodes.filter((node) =>
    node.type === NODE_TYPE.BUTTONS_NODE || node.type === NODE_TYPE.COLLECT_INPUT_NODE || node.type === NODE_TYPE.FILE_UPLOAD_NODE || node.type === NODE_TYPE.CALENDAR_NODE
  );

  let missingAtsNodes = totalAtsNodes.filter((node) => {
    if (!node.data.hasOwnProperty("atsValue") || node?.data?.atsValue === "" || node?.data?.atsValue === null) {
      atsValueMissingNode = node;
      return true;
    }
    return false;
  });
  let isAtsValueMissing = totalAtsNodes.length === missingAtsNodes.length;
  return { isAtsValueMissing, atsValueMissingNode };
}

export function validateJumpToNode(nodes) {
  let isJumpToNodeValid = true;
  let jumpToNode = null;
  let message = null;
  nodes.forEach((node) => {
    if (!isJumpToNodeValid) return;
    if (node.type === NODE_TYPE.JUMP_TO_NODE || node.type === NODE_TYPE.JUMP_TO_PROFILE_NODE) {
      if (node?.data?.value === "0" || node?.data?.questionId === "0") {
        isJumpToNodeValid = false;
        if (node?.data?.value === "0") {
          message = "profile";
        } else {
          if (node?.data?.questionId === "0") {
            message = "question";
          }
        }
        // if (node.type === NODE_TYPE.JUMP_TO_PROFILE_NODE && node?.data?.questionId === null) {
        //   jumpToNode = node;
        //   return;
        // } else {
        jumpToNode = node;
        return;
        // }\
      }
    }
  });
  return { isJumpToNodeValid, jumpToNode, message };
}

export function validateNodeMessages(nodes) {
  let isNodeMessageValid = true;
  let nodeMessage = null;
  nodes.forEach((node) => {
    if (!isNodeMessageValid) return;
    if (node?.data?.isMessageEmpty) {
      isNodeMessageValid = false;
      nodeMessage = node;
      return;
    }
  });
  return { isNodeMessageValid, nodeMessage };
}

export function validateButtonNodes(nodes) {
  let isButtonNodeValid = true;
  let buttonNode = null;
  nodes.forEach((node) => {
    if (!isButtonNodeValid) return;
    if (node.type === NODE_TYPE.BUTTONS_NODE) {
      node?.data?.buttons?.map(function (button) {
        if (button.titleText === "" || button.titleText === null) {
          isButtonNodeValid = false;
          buttonNode = node;
        }
      })
    }
  });
  return { isButtonNodeValid, buttonNode };
}