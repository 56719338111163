import { useContext, useMemo, useState } from "react";
import { Position } from "reactflow";
import ConditionalIcon from "../../assets/images/conditional.svg";
import NodeContext from "../../context/NodeContext";
import {
  ActionButtonContainer,
  ConditionalNode as StyledConditionalNode,
} from "./ConditionalNode.styled";
import DeleteIconImg from "../../assets/images/deleteIcon.svg";
import AddIconRed from "../../assets/images/addIconRed.svg";
import AddIconGreen from "../../assets/images/addIconGreen.svg";
import AddNodeSelector from "../../components/AddNodeSelector";
import { BottomEdge, TopEdge } from "../StartingNode/StartingNode.styled";

function ConditionalNode(props) {
  const { id, data } = props;
  const { titleText, isTop, isBottom, active } = data;
  const { handleNodeClick, edges, deleteNode, selectedNodeInfo, isEditable, isSuperAdmin, setSuperAdminModalVisibility } =
    useContext(NodeContext);
  const [showAddBox, setShowAddBox] = useState(false);
  const [sourceHandle, setSourceHandle] = useState(null);
  const [visible, setVisible] = useState(false);

  const isSuccessEdgeConnected = useMemo(
    () =>
      edges.findIndex(
        (edge) => edge.source === id && edge.sourceHandle === "bottom-success"
      ) !== -1,
    [edges, id]
  );

  const isFailureEdgeConnected = useMemo(
    () =>
      edges.findIndex(
        (edge) => edge.source === id && edge.sourceHandle === "bottom-failure"
      ) !== -1,
    [edges, id]
  );

  return (
    <>
      {isTop ? (
        <TopEdge
          type="target"
          position={Position.Top}
          id="top"
          isConnectable={true}
          iseditable={isEditable ? "true" : undefined}
        />
      ) : null}

      <StyledConditionalNode
        $isActive={active ? active : selectedNodeInfo?.id === id}
        onClick={() => handleNodeClick(id)}
      >
        <div className="titleContainer">
          <img src={ConditionalIcon} alt="conditionalIcon" />
          <span className="titleText">{titleText}</span>
        </div>

        {(isEditable) && (
          <ActionButtonContainer
            className="action-btns"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={DeleteIconImg}
              alt="deleteIcon"
              onClick={() => {
                //   deleteNode(id);
                setVisible(true);
              }}
            />
          </ActionButtonContainer>
        )}
        {(isEditable) && visible && (
          <ActionButtonContainer
            className="action-btns"
            onClick={(e) => e.stopPropagation()}
            style={{
              right: "-315px",
              top: "-35px",
              height: "auto",
              width: "250px",
              padding: "10px",
            }}
          >
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "Plus Jakarta Sans, sans-serif",
                marginBottom: "10px",
                color: "#000",
              }}
            >
              Are you sure you want to delete?
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "150px",
                margin: "auto",
                padding: "10px 8px",
              }}
            >
              <button
                style={{
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  color: "#007cc5",
                  border: "1px solid #007cc5",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setVisible(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  color: "#ef4949",
                  border: "1px solid #ef4949",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  setVisible(false);
                  if (!isSuperAdmin) {
                    setSuperAdminModalVisibility({
                      visible: true,
                      id: id,
                    });
                  } else {
                    deleteNode(id);
                  }
                }}
              >
                Delete
              </button>
            </div>
          </ActionButtonContainer>
        )}
        {/* 
                {!isSuccessEdgeConnected ? (
                    <AddIcon
                        className="addIcon success"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowAddBox(!showAddBox);
                            setSourceHandle("bottom-success");
                        }}
                    />
                ) : null}

                {!isFailureEdgeConnected ? (
                    <AddIcon
                        className="addIcon failure"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowAddBox(!showAddBox);
                            setSourceHandle("bottom-failure");
                        }}
                    />
                ) : null} */}

        {showAddBox ? (
          <AddNodeSelector
            nodeData={{
              ...props,
              sourcePosition: sourceHandle
                ? sourceHandle
                : props.sourcePosition,
              xPos: sourceHandle
                ? sourceHandle === "bottom-success"
                  ? props?.xPos - 150
                  : props?.xPos + 150
                : props?.xPos,
            }}
            setShowAddBox={setShowAddBox}
          />
        ) : null}
      </StyledConditionalNode>

      {isBottom ? (
        <BottomEdge
          type="source"
          position={Position.Bottom}
          id="bottom-success"
          isConnectable={!isSuccessEdgeConnected}
          style={{ left: "30%", transform: "translateX(-50%)" }}
          onClick={(e) => {
            e.stopPropagation();
            handleNodeClick(id);
            setShowAddBox(!showAddBox);
            setSourceHandle("bottom-success");
          }}
          iseditable={isEditable ? "true" : undefined}
          bgcolor="#00c53d"
          $addIcon={AddIconGreen}
          $handleColor="#00c53d"
        />
      ) : null}

      {isBottom ? (
        <BottomEdge
          type="source"
          position={Position.Bottom}
          id="bottom-failure"
          isConnectable={!isFailureEdgeConnected}
          style={{ left: "70%", transform: "translateX(-50%)" }}
          $addIcon={AddIconRed}
          $handleColor="#ec0000"
          onClick={(e) => {
            e.stopPropagation();
            handleNodeClick(id);
            setShowAddBox(!showAddBox);
            setSourceHandle("bottom-failure");
          }}
          iseditable={isEditable ? "true" : undefined}
          bgcolor="#ec0000"
        />
      ) : null}
    </>
  );
}

export default ConditionalNode;
