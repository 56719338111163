import React, { useContext, useMemo } from "react";
import { getSmoothStepPath } from "reactflow";
import NodeContext from "../../context/NodeContext";
import { NODE_TYPE } from "../../utils/constants";

const foreignObjectSize = 40;

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) {
  const { deleteEdge, edges, nodes, isEditable } = useContext(NodeContext);
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const shouldShowDeleteButton = useMemo(() => {
    const edge = edges.find((item) => item.id === id);
    if (edge) {
      const sourceNodeForEdge = nodes?.find((item) => item.id === edge.source);

      if (
        sourceNodeForEdge &&
        [
          NODE_TYPE.BUTTONS_NODE,
          NODE_TYPE.CAROUSEL_NODE,
        ].includes(sourceNodeForEdge?.type)
      ) {
        return (
          sourceNodeForEdge?.data?.buttons?.length > 1 ||
          sourceNodeForEdge?.data?.cards?.length > 1
        );
      }
    }

    return true;
  }, [edges, id, nodes]);

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        // markerStart={markerStart}
        markerEnd={markerEnd}
        strokeWidth={50}
      />
      {/* <text dy="-10" onClick={() => deleteEdge(id)}>
                <textPath
                    href={`#${id}`}
                    style={{ fontSize: 12 }}
                    startOffset="50%"
                    textAnchor="middle"
                >
                    Delete
                </textPath>
            </text> */}
      {shouldShowDeleteButton && isEditable && (
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={labelX - foreignObjectSize / 2}
          y={labelY - foreignObjectSize / 2}
          className="edgebutton-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <div>
            <button className="edgebutton" onClick={() => deleteEdge(id)}>
              ×
            </button>
          </div>
        </foreignObject>
      )}
    </>
  );
}
