import styled from "styled-components";

export const Input = styled.input`
    color: #101010;
    padding: 6px 0px;
    font-size: 18px;
    background: #ffffff;
    line-height: normal;
    border: 0;
    border-bottom: 2px solid #afb2b3;
    width: 100%;
    font-weight: bold;
    font-family: "Plus Jakarta Sans", sans-serif;

    &:focus {
        outline: none;
    }
`;

export const TitleInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    p {
        color: #858585;
        font-size: 14px;
        text-align: left;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
    }
`;

// export const MessageInputContainer = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     margin-bottom: 20px;

//     p {
//         color: #101010;
//         font-size: 15px;
//         text-align: left;
//         margin-bottom: 4px;
//         font-family: "Plus Jakarta Sans", sans-serif;
//         font-weight: 500;
//         margin: 0;
//         padding: 0;
//         margin-bottom: 10px;
//     }

//     span.note {
//         font-size: 11px;
//         font-family: "Plus Jakarta Sans", sans-serif;
//         color: #858585;
//         margin-top: 5px;
//         margin-bottom: 20px;
//         text-align: left;
//     }

//     & > .ck {
//         width: 100%;
//     }

//     .ck-editor__editable {
//         min-height: 100px;
//     }

//     & > .select-container {
//         width: 100%;
//         text-align: left;
//     }
// `;

// export const InputContainer = styled.div`
//     width: 100%;
//     margin-bottom: 20px;
//     margin-top: 20px;
//     display: flex;
//     justify-content: flex-start;
//     flex-direction: column;

//     p {
//         color: #101010;
//         font-size: 15px;
//         text-align: left;
//         margin-bottom: 4px;
//         font-family: "Plus Jakarta Sans", sans-serif;
//         font-weight: 500;
//         margin: 0;
//         padding: 0;
//         margin-bottom: 10px;
//     }

//     .input {
//         width: 100%;
//         outline: none;
//         border: 1px solid rgb(133, 133, 133);
//         border-radius: 4px;
//         font-family: "Plus Jakarta Sans", sans-serif;
//         font-size: 15px;
//         color: rgb(16, 16, 16);
//         padding: 0px 10px;
//         height: 40px;
//     }

//     span {
//         font-size: 11px;
//         color: #858585;
//         text-align: left;
//         margin-top: 5px;
//     }
// `;

// export const CheckboxContainer = styled.div`
//     display: flex;
//     gap: 10px;
//     margin-top: 20px;

//     label {
//         color: #101010;
//         font-size: 15px;
//         text-align: left;
//         font-family: "Plus Jakarta Sans", sans-serif;
//     }
// `;

export const ConditionContainer = styled.div`
    width: 100%;
    // border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    // padding: 10px;
    display: flex;
    flex-direction: column;
    // gap: 20px;
    text-align: left;
    .input {
        width: 100%;
        outline: none;
        /* border: 1px solid rgb(133, 133, 133);
        border-bottom: 1.5px solid #afb2b3; */
        border: none;
        border: 1px solid hsl(0, 0%, 80%);
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 15px;
        color: rgb(16, 16, 16);
        padding: 0px 10px;
        height: 40px;

        &:hover {
            border-color: hsl(0, 0%, 70%);
        }
    }

    .label-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & span:nth-child(2) {
            color: #aaa;
            font-size: 14px;
            text-transform: capitalize;
        }
    }

    svg {
        align-self: flex-end;
        cursor: pointer;
    }
`;

export const AndOrContainer = styled.div`
    width: 100px;
    height: 40px;
    background-color: #f0f0f0;
    margin: 10px auto;
    margin-top: 20px;
    margin-bottom: 0px;
    padding: 4px;
    display: flex;

    span {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.2s linear;

        &.active {
            background-color: #fff;
        }
    }
`;

export const PlusIconContainer = styled.div`
    width: 40px;
    height: 40px;
    margin: 10px auto 40px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const ConfitionTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: normal;
    color: #101010;
`;
