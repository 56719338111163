import styled from "styled-components";

export const SaveButton = styled.button`
    border-radius: 4px;
    min-width: 64px;
    box-sizing: border-box;
    min-height: 36px;
    width: 80px;
    border: 1px solid #007cc5;
    background-color: #ffffff;
    color: #007cc5;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transform: none;
    transition: all 0.3s;
    font-weight: normal;
    line-height: normal;
    font-family:  "Plus Jakarta Sans", sans-serif;
    &:hover {
        color: #007cc5;
        background: #007cc529;
    }
`;

export const DeleteButton = styled.button`
    min-width: 36px;
    box-sizing: border-box;
    min-height: 36px;
    width: 50px;
    border: none;
    border-left: 1px solid #00000033;
    background-color: #ffffff;
    color: #ef4949;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transform: none;
    transition: all 0.3s;
    font-weight: normal;
    line-height: normal;
    font-family:  "Plus Jakarta Sans", sans-serif;
    &:hover {
        color:  #ef4949;
        background:  #ef494929;
    }
`;

export const PreviewButton = styled.button`
    top: unset;
    left: unset;
    font-size: 14px;
    transform: none;
    transition: all 0.3s;
    font-weight: normal;
    line-height: normal;
    font-family:  "Plus Jakarta Sans", sans-serif;
    color: #007cc5;
    height: 36px;
    border: 1px solid #007cc5;
    padding: 0px 5px;
    background: #ffffff;
    border-radius: 4px;
    width: 100px;
    align-items: center;
    box-sizing: border-box;
    min-height: 36px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    &:hover {
        color: #007cc5;
        background: #007cc529;
    }
}
`;

export const PublishButton = styled.button`
    top: unset;
    left: unset;
    font-size: 14px;
    transform: none;
    transition: all 0.3s;
    font-weight: normal;
    line-height: normal;
    width: 80px;
    font-family:  "Plus Jakarta Sans", sans-serif;;
    color: #007cc5;
    height: 36px;
    align-items: center;
    border: 1px solid #007cc5;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #007cc5;
    border-radius: 4px;
    min-width: 64px;
    box-sizing: border-box;
    min-height: 36px;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
`;