export const LABELS = {
    SHOW_MESSAGE: "Show message",
    ATS_FIELD: "Select ATS Field",
    SAVE_RESPONSE_VARIABLE: "Save the response in this variable",
    EMAIL_NODE_TYPE: "Select message type",
    EMAIL_NODE_SUBJECT: "Add subject for the email",
    EMAIL_NODE_ATTACHMENTS: "Attachments for email",
    EMAIL_NODE_MESSAGE_BODY: "Email body",
    MESSAGE_NODE_MESSAGE_BODY: "Send this message",
    RESPONSE_TYPE: "Response type",
    COLLECT_INPUT_NODE_MESSAGE_BODY: "Show this message",
    REGEX: "Regex",
    DATE_FORMAT: "Date format",
    INPUT_SUGGESTION: "Add input suggestion for the expected response",
    INPUT_VALIDATION: "Add a validation message to show user when input text is not in expected",
    FILE_TYPE: "Select type of files that can be uploaded",
    REQUEST_URL: "Request URL",
    REQUEST_HEADERS: "Request headers",
    REQUEST_BODY: "Request body",
    GO_TO_ACTION: "Go to this action block",
    GO_TO_PROFILE: "Go to this profile",
}