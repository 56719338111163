import styled from "styled-components";

export const DeleteBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
  padding: 10px;
`;

export const DeleteBoxBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 20px;
    font-weight: bold;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    font-family: "Plus Jakarta Sans", sans-serif;
  }
  transition: all 1s ease;
`;

export const DeleteBoxFooter = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: all 1s ease;
  div {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 10px;
    button {
      font-family: "Plus Jakarta Sans", sans-serif;
      background-color: #fff;
      color: black;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding: 10px 15px;
      cursor: pointer;
      &:hover {
        background-color: #e5e5e5dd;
      }
    }
    button:nth-child(2) {
      background-color: ${(props) => props.$color};
      color: white;
      &:hover {
        background-color: ${(props) => props.$color}dd;
      }
    }
  }
`;

export const DeleteBoxParent = styled.div`
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 350px;
  height: auto;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
`;

export const DeleteModalBox = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`;
