import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
  Controls,
  Background,
  MiniMap,
  // useViewport,
} from "reactflow";
import SidePanel from "../../components/SidePanel";
import NodeContext from "../../context/NodeContext";
import Buttons from "../../components/Buttons";
// import searchIcon from "../../assets/images/searchIcon.svg";

import {
  MessageNode,
  StartingNode,
  CollectInputNode,
  ButtonsNode,
  ButtonNode,
  FileUploadNode,
  CalendarNode,
  CodeBlockNode,
  ServiceCallNode,
  CarouselNode,
  CardNode,
  ConditionalNode,
  EndNode,
  JumpToNode,
  EmailNode,
  JumpToProfile,
} from "../../nodes";
import CustomEdge from "../../components/CustomEdge";
import DeleteModal from "../../components/DeleteModal";
import Loader from "../../components/Loader";
import { useLoaderData, useNavigate } from "react-router-dom";
import { authStore } from "../../store/authStore";

function FlowBoard() {
  const navigate = useNavigate();
  const { setAuth } = authStore();
  const authData = useLoaderData();
  const [showSearch, setShowSearch] = useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);

  useEffect(() => {
    setAuth(authData);
    if (!authData) {
      navigate("/unauthorized");
    }
  }, [authData, navigate, setAuth]);
  const {
    onNodesChange,
    onEdgesChange,
    onConnect,
    nodes,
    edges,
    onSave,
    onPreview,
    onPublish,
    onRestore,
    flowName,
    editFlowName,
    setEditFlowName,
    setVisible,
    visible,
    loadingFlow,
    isLoading,
    buttonClick,
    visibleDeploy,
    setVisibleDeploy,
    viewport,
    FlowType,
    isEditable,
    // setNewAddedNode,
    setIsEditable,
    isSuperAdmin,
    deleteNode,
    flowInstance,
    handleNodeClick,
    setNodes,
    setSelectedNodeId,
    reactFlowWrapperRef,
    atsModalVisibility,
    setAtsModalVisibility,
    checkIfMissingAtsValues,
    superAdminModalVisibility,
    setSuperAdminModalVisibility
  } = useContext(NodeContext);

  const nodeTypes = useMemo(
    () => ({
      startingNode: StartingNode,
      messageNode: MessageNode,
      collectInputNode: CollectInputNode,
      buttonsNode: ButtonsNode,
      buttonNode: ButtonNode,
      fileUploadNode: FileUploadNode,
      calendarNode: CalendarNode,
      codeBlockNode: CodeBlockNode,
      serviceCallNode: ServiceCallNode,
      carouselNode: CarouselNode,
      cardNode: CardNode,
      emailNode: EmailNode,
      conditionalNode: ConditionalNode,
      jumpToNode: JumpToNode,
      endNode: EndNode,
      jumpToProfileNode: JumpToProfile,
    }),
    []
  );

  const searchRef = useRef(null);

  const onDropCallAPI = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type !== "mousemove") return;
  };

  const edgeTypes = useMemo(
    () => ({
      custom: CustomEdge,
    }),
    []
  );

  const customHandlerForNodeChange = (nodes) => {
    setTimeout(() => {
      if (nodes && nodes.length > 0) onNodesChange(nodes);
    }, 50);
  };

  function onInitLoad(flowInstance) {
    flowInstance.setViewport(viewport ?? { x: 0, y: 0, zoom: 1 });
  }

  const getCorrectNodePosition = (nodeId) => {
    const node = nodes.find((node) => node.id === nodeId);
    if (node) {
      const { x, y } = node.position;
      const { width, height } =
        reactFlowWrapperRef.current.getBoundingClientRect();
      const nodeX = width / 2 - x - 100;
      const nodeY = height / 2 - y - 50;
      return { x: nodeX, y: nodeY, zoom: 1 };
    }
    return { x: 0, y: 0 };
  };

  const handleSearch = (id) => {
    const { x, y, zoom } = getCorrectNodePosition(id);
    flowInstance.setViewport({ x, y, zoom }, { duration: 800 });
    setSelectedNodeId(null);
    setNodes((nodes) => {
      return nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              active: true,
            },
          };
        } else {
          return {
            ...node,
            data: {
              ...node.data,
              active: false,
            },
          };
        }
      });
    });
    setSelectedOption(null)
    setShowSearch(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.keyCode === 70 && e.ctrlKey) || (e.keyCode === 70 && e.metaKey)) {
        e.preventDefault();
        searchRef.current.focus();
        // setShowSearch(!showSearch);
      } else if (e.keyCode === 27) {
        searchRef.current.blur();
        handleNodeClick(null);
        setShowSearch(false);
      }
    };


    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    }
  }, [showSearch, handleNodeClick, setSelectedOption]);

  return loadingFlow ? (
    <Loader visible={loadingFlow} />
  ) : (
    <div style={{ height: "96vh" }} tabIndex="0">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <Buttons
                onSave={onSave}
                onPreview={onPreview}
                onPublish={onPublish}
                flowName={flowName}
                editFlowName={editFlowName}
                setEditFlowName={setEditFlowName}
                setVisible={setVisible}
                buttonClick={buttonClick}
                isUpdating={isLoading}
                setAtsModalVisibility={setAtsModalVisibility}
                setVisibleDeploy={setVisibleDeploy}
                FlowType={FlowType}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                showSearch={showSearch}
                setShowSearch={setShowSearch}
                nodes={nodes}
                handleSearch={handleSearch}
                searchRef={searchRef}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                checkIfMissingAtsValues={checkIfMissingAtsValues}
              />
            </div>
          </div>
        </div>
      </nav>
      <DeleteModal
        title="Are you sure?"
        content="Do you really want to delete the flow? This process cannot be undone."
        okText="Delete"
        cancelText="Cancel"
        onOk={onRestore}
        onCancel={() => setVisible(false)}
        visible={visible}
        img={true}
        buttonColor="#ef4949"
      />
      <DeleteModal
        title="Are you sure?"
        content="Do you really want to publish the flow?"
        okText="Deploy"
        cancelText="Cancel"
        onOk={onPublish}
        onCancel={() => setVisibleDeploy(false)}
        visible={visibleDeploy}
        img={false}
        buttonColor="#007cc5"
      />
      <DeleteModal
        title="Forgetting something?"
        content="Seems like none of the responses are mapped back to the ATS fields. Are you sure you want to continue?"
        okText="Continue"
        cancelText="Cancel"
        onOk={() => {
          setAtsModalVisibility(false);
          onPreview();
        }}
        onCancel={() => setAtsModalVisibility(false)}
        visible={atsModalVisibility}
        img={false}
        buttonColor="#f0c46d"
      />
      {!isSuperAdmin && <DeleteModal
        title="WARNING"
        content="These sorts of items (grey nodes) are crucial for the chat flow to function and should not be removed.
        <br/><br/> 
        <b style='font-size: larger;'>Are you sure you want to delete this item? </b> 
        <br/><br/> 
        If unsure, check with our support team."
        okText="Continue"
        cancelText="Cancel"
        onOk={() => {
          deleteNode(superAdminModalVisibility.id);
          setSuperAdminModalVisibility({
            visible: false,
            id: null
          })
        }}
        onCancel={() => setSuperAdminModalVisibility({
          visible: false,
          id: null
        })}
        visible={superAdminModalVisibility.visible}
        img={false}
        buttonColor="#f0c46d"
      />}
      <ReactFlow
        nodes={nodes}
        onNodesChange={customHandlerForNodeChange}
        edges={edges}
        onInit={onInitLoad}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        deleteKeyCode={null}
        onNodeDragStop={onDropCallAPI}
        nodesDraggable={isEditable}
        nodesFocusable={isEditable}
        panOnScrollMode="free"
        panOnScroll={true}
        elevateNodesOnSelect={true}
        ref={reactFlowWrapperRef}
        proOptions={{
          hideAttribution: true,
        }}
      >
        <Background />

        <Controls
          showInteractive={false}
        //  showFitView={false} showZoom={false}
        >
          {/* <ControlButton
            onClick={() => {
              // console.log("Search");
              searchRef.current.focus();
            }}
            title="Search"
            style={{ order: 5, position: "relative" }}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </ControlButton>

          <ControlButton
            onClick={() => {
              flowInstance.zoomIn();
            }}
            title="Zoom In"
            style={{ order: 1 }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </ControlButton>
          <ControlButton
            onClick={() => {
              flowInstance.zoomOut();
            }}
            title="Zoom Out"
            style={{ order: 2 }}
          >
            <FontAwesomeIcon icon={faMinus} />
          </ControlButton>
          <ControlButton
            onClick={() => {
              flowInstance.fitView();
            }}
            title="Fit View"
            style={{ order: 3 }}
          >
            <FontAwesomeIcon icon={faExpand} />
          </ControlButton> */}
        </Controls>
        <MiniMap />
      </ReactFlow>
      <SidePanel />
    </div>
  );
}

export default FlowBoard;
