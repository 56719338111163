import styled from "styled-components";

export const UnauthorizedPageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(255, 255, 255);
`;

export const UnauthorizedPageContent = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    height: 300px;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
`;

export const UnauthorizedPageTitle = styled.p`
    color: rgb(16, 16, 16);
    font-size: 20px;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    margin: 0px 0px 20px;
    padding: 0px;
`;

export const UnauthorizedPageSubtitle = styled.p`
    color: rgb(16, 16, 16);
    font-size: 15px;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    margin: 0px 0px 20px;
    padding: 0px;
`;


export const UnauthorizedPageButton = styled.button`
    width: 50%;
    outline: none;
    border: none;
    border-radius: 4px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 15px;
    color: rgb(255, 255, 255);
    padding: 0px 10px;

    height: 40px;
    background-color: rgb(0, 0, 0);
    cursor: pointer;
`;

