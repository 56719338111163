// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  ActionButtonContainer,
  ButtonsListContainer,
  Input,
  MessageInputContainer,
  ImagePicker,
  TitleInputContainer,
} from "./CardNodeEditor.styled";
import NodeContext from "../../context/NodeContext";
import { useCallback, useContext, useRef, useState } from "react";
import { BUTTON_TYPE, toolbar } from "../../utils/constants";
import AddIcon from "../../assets/images/addIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import { v4 as uuidv4 } from "uuid";
import { Editor } from "react-draft-wysiwyg";
import { getHTMLToDraft } from "../../utils/helper";

import { flowModuleApi } from "../../api/FlowModule/api";
import LinkIcon from "@mui/icons-material/Link";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { LABELS } from "../../utils/labels";

function ButtonItem({ button, index }) {
  const { selectedNodeInfo, updateNode, deleteNode, isEditable } =
    useContext(NodeContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (e) => {
    const newButtons = selectedNodeInfo?.data?.buttons;
    newButtons[index].url = e.target.value;

    updateNode(selectedNodeInfo.id, {
      ...selectedNodeInfo?.data,
      buttons: newButtons,
    });
    updateNode(button?.buttonNodeId, {
      ...button,
      url: e.target.value,
    });
  };

  return (
    <div className="item">
      <div className="input-container">
        <div className="inp-cont">
          <input
            // style={{
            //   paddingRight: "85px",
            // }}
            value={button.titleText}
            onChange={(e) => {
              const newButtons = selectedNodeInfo?.data?.buttons;
              newButtons[index].titleText = e.target.value;

              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                buttons: newButtons,
              });
              updateNode(button?.buttonNodeId, {
                ...button,
                titleText: e.target.value,
              });
            }}
            placeholder="Button label"
            readOnly={!isEditable}
          />
          {/* <div className="icon-container">
            <MoreVertical onClick={() => setIsOpen(!isOpen)} />
          </div>
          <div className="absolute-select" ref={ref}>
            {isOpen && <input className="select-container" />}
            <Select
              options={buttonOptions}
              className="select-container"
              placeholder="Select date format"
              key={selectedNodeInfo?.id}
              menuIsOpen={isOpen}
              value={buttonOptions?.find(
                (item) => item.value === button?.assessmentValue
              )}
              onChange={(e) => {
                setIsOpen(false);

                const newButtons = selectedNodeInfo?.data?.buttons;
                newButtons[index].assessmentValue = e.value;

                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  buttons: newButtons,
                });
                updateNode(button?.buttonNodeId, {
                  ...button,
                  assessmentValue: e.value,
                });
              }}
              classNames={{
                control: () => "custom-controller",
                menu: () => "custom-menu",
              }}
            />
          </div> */}
          <div>
            <IconButton id="menu-activator" onClick={handleClick}>
              <LinkIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{ py: 1 }}
            >
              <MenuItem
                dense
                disableRipple
                disableTouchRipple
                autoFocus
                sx={{ px: 1, py: 0 }}
              >
                <TextField
                  value={button.url}
                  autoFocus
                  onChange={handleInputChange}
                  placeholder="Enter URL"
                  variant="outlined"
                  size="small"
                  disabled={!isEditable}
                />
                {/* <IconButton size="small">
                  <SendIcon />
                </IconButton> */}
              </MenuItem>
            </Menu>
          </div>
          <div className="label-item">{button?.assessmentValue}</div>
        </div>
        {selectedNodeInfo?.data?.buttons?.length > 1 && isEditable ? (
          <img
            src={DeleteIcon}
            alt="deleteIcon"
            onClick={() => {
              const newButtons = selectedNodeInfo?.data?.buttons;
              newButtons.splice(index, 1);
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                buttons: newButtons,
              });
              deleteNode(button?.buttonNodeId);
            }}
          />
        ) : null}
      </div>
      <span>
        {button.buttonType === BUTTON_TYPE.BRANCH_BUTTON
          ? "Add new blocks from the port to configure it"
          : "Click on the URL icon to add the URL"}
      </span>
    </div>
  );
}

function CardNodeEditor() {
  const {
    selectedNodeInfo,
    updateNode,
    variableList,
    saveData,
    isEditable,
    setNewAddedNode,
  } = useContext(NodeContext);
  const ref = useRef();
  const [showMsg, setShowMsg] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleAddButton = useCallback(() => {
    const buttonNode = {
      titleText: `Choice ${selectedNodeInfo?.data?.buttons?.length + 1}`,
      buttonType: BUTTON_TYPE.URL_BUTTON,
      buttonNodeId: uuidv4(),
      url: "",
    };

    // addButtonNode(selectedNodeInfo, buttonNode);
    updateNode(selectedNodeInfo.id, {
      ...selectedNodeInfo?.data,
      buttons: [...selectedNodeInfo?.data?.buttons, buttonNode],
    });
  }, [updateNode, selectedNodeInfo]);

  function handleFileUpload(e) {
    // console.log(e.target.files[0]);
    if (
      !e.target.files[0] ||
      !["image/png", "image/jpeg", "image/jpg"].includes(e.target.files[0].type)
    ) {
      return;
    }
    let file = e.target.files[0];
    if (e.target.files[0].size / 1024 / 1024 > 2) {
      setShowMsg("File size too large");
      setTimeout(() => {
        setShowMsg("");
      }, 2000);
      return;
    }
    const reader = new FileReader();
    setIsUploading(true);
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const base64 = reader.result;
      const imageToBase64 = base64.split(",")[1];
      const { fileUrl } = await flowModuleApi.uploadFile({
        fileName: file.name,
        filebase64: imageToBase64,
      });
      if (fileUrl) {
        setIsUploading(false);
      }
      updateNode(selectedNodeInfo?.id, {
        ...selectedNodeInfo?.data,
        image: fileUrl,
      });
      saveData();
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
      setShowMsg("Something went wrong");
      setTimeout(() => {
        setShowMsg("");
      }, 3000);
    };
  }
  function handleRemoveImage(e) {
    e.stopPropagation();
    const dataToUpdate = { ...selectedNodeInfo?.data };
    delete dataToUpdate.image;
    updateNode(selectedNodeInfo?.id, {
      ...dataToUpdate,
    });
    setTimeout(() => {
      saveData();
    }, 100);
  }

  if (!selectedNodeInfo) {
    return null;
  }

  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
        <p>
          Allow user to add Interactive card with URL buttons for concise,
          visually appealing information presentation in conversations.
        </p>
      </TitleInputContainer>

      <div style={{ position: "relative" }}>
        <ImagePicker
          onClick={() => {
            if (!isEditable) return;
            ref.current.click();
          }}
          iseditable={isEditable ? "true" : undefined}
          data-text={isUploading ? "Uploading..." : "Upload an Image"}
        >
          {showMsg && (
            <span
              style={{
                position: "absolute",
                bottom: "-20px",
                left: "0",
                fontSize: "12px",
                textAlign: "left",
                color: "#ef4949",
              }}
            >
              {showMsg}
            </span>
          )}
          {selectedNodeInfo?.data?.image ? (
            <img src={selectedNodeInfo?.data?.image} alt="selectedNodeInfo" />
          ) : null}
          {selectedNodeInfo?.data?.image ? (
            <IconButton
              aria-label="close"
              size="small"
              sx={{ position: "absolute", zIndex: 501, top: 0, right: 0, p: 1 }}
              onClick={handleRemoveImage}
            >
              <CloseIcon style={{ color: "#ffebee" }} />
            </IconButton>
          ) : null}
          <input
            type="file"
            ref={ref}
            accept="image/png,image/jpeg,image/jpg"
            onChange={(e) => {
              handleFileUpload(e);
            }}
          />
        </ImagePicker>
      </div>

      <MessageInputContainer>
        <p>{LABELS.SHOW_MESSAGE}</p>
        <Editor
          editorState={getHTMLToDraft(selectedNodeInfo?.data?.message)}
          onEditorStateChange={(data) => {
            const isMessageEmpty = data.getCurrentContent().hasText();
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              message: data,
              isMessageEmpty: !isMessageEmpty,
            });
          }}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={toolbar}
          mention={{
            separator: " ",
            trigger: "{",
            suggestions: variableList,
          }}
          onBlur={() => {
            setNewAddedNode(true);
          }}
          placeholder="What is your..."
          readOnly={!isEditable}
          stripPastedStyles={true}
        />
      </MessageInputContainer>
      <ButtonsListContainer>
        {selectedNodeInfo?.data?.buttons?.map((button, index) => {
          return (
            <ButtonItem
              button={button}
              index={index}
              key={button?.buttonNodeId}
            />
          );
        })}
      </ButtonsListContainer>

      {selectedNodeInfo?.data?.buttons?.length < 10 && isEditable ? (
        <ActionButtonContainer>
          <button onClick={() => handleAddButton()}>
            <img src={AddIcon} alt="addIcon" />
            Choice
          </button>
        </ActionButtonContainer>
      ) : null}
    </>
  );
}

export default CardNodeEditor;
