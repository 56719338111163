import { useContext, useMemo, useState } from "react";
import { Position } from "reactflow";
import StartImage from "../../assets/images/startingNode.svg";
import AddNodeSelector from "../../components/AddNodeSelector";
import { BottomEdge, StyledStartingNode } from "./StartingNode.styled";
import NodeContext from "../../context/NodeContext";

function StartingNode(props) {
  const { data } = props;
  const { startingText, isBottom } = data;
  const [showAddBox, setShowAddBox] = useState(false);
  const { edges, isEditable } = useContext(NodeContext);

  const isNodeConnected = useMemo(
    () =>
      edges.findIndex(
        (edge) => edge.source === props.id && edge.sourceHandle === "bottom"
      ) !== -1,
    [edges, props.id]
  );

  return (
    <>
      <StyledStartingNode>
        <div>
          <img src={StartImage} alt="startIcon" />
        </div>

        <span className="startingNode">{startingText}</span>

        {showAddBox ? (
          <AddNodeSelector nodeData={props} setShowAddBox={setShowAddBox} />
        ) : null}
      </StyledStartingNode>
      {isBottom ? (
        <BottomEdge
          type="source"
          position={Position.Bottom}
          id="bottom"
          isConnectable={!isNodeConnected}
          onClick={() => setShowAddBox(!showAddBox)}
          iseditable={isEditable ? "true": undefined}
        />
      ) : null}
    </>
  );
}

export default StartingNode;
