import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DeleteBox,
  DeleteBoxBody,
  DeleteBoxFooter,
  DeleteBoxParent,
  DeleteModalBox,
} from "./DeleteBox.styled";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

function DeleteModal(props) {
  const { visible, onOk, onCancel, okText, cancelText, title, content, img, buttonColor } = props;
  return (
    <>
      {visible && (
        <DeleteModalBox style={{
          width: '50% !important'
        }}>
          <DeleteBoxParent>
            <DeleteBox>
              <DeleteBoxBody>
                {img && <div style={{
                  backgroundColor: '#ef4949',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  cursor: 'pointer'
                }}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>}
                <h3>{title}</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: content }}
                  style={{
                    textAlign: 'center'
                  }}
                />
              </DeleteBoxBody>
              <DeleteBoxFooter $color={buttonColor}>
                <div>
                  <button onClick={onCancel}>{cancelText}</button>
                  <button onClick={onOk}>{okText}</button>
                </div>
              </DeleteBoxFooter>
            </DeleteBox>
          </DeleteBoxParent>
        </DeleteModalBox>
      )}
    </>
  );
}

export default DeleteModal;
