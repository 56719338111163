import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Redirect = () => {
  const location = useLocation();

  useEffect(() => {
    window.location.href = "https://app.smartai.com.au";
  }, [location]);
  return <div></div>;
};

export default Redirect;
