import { useContext, useMemo, useState } from "react";
import { Position } from "reactflow";
import CarouselNodeIcon from "../../assets/images/carouselIcon.svg";
import NodeContext from "../../context/NodeContext";
import {
  ActionButtonContainer,
  CarouselNode as StyledCarouselNode,
} from "./CarouselNode.styled";
import DeleteIconImg from "../../assets/images/deleteIcon.svg";
import AddNodeSelector from "../../components/AddNodeSelector";
import { BottomEdge, TopEdge } from "../StartingNode/StartingNode.styled";
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

function CarouselNode(props) {
  const { id, data } = props;
  const { titleText, isTop, isBottom, isVariableMissing, isMessageEmpty, active } =
    data;
  const { handleNodeClick, edges, deleteNode, selectedNodeInfo, isEditable } =
    useContext(NodeContext);
  const [showAddBox, setShowAddBox] = useState(false);
  const [visible, setVisible] = useState(false);

  const isBottomEdgeConnected = useMemo(
    () =>
      edges.findIndex(
        (edge) => edge.source === id && edge.sourceHandle === "bottom"
      ) !== -1,
    [edges, id]
  );

  return (
    <>
      {isTop ? (
        <TopEdge
          type="target"
          position={Position.Top}
          id="top"
          isConnectable={true}
          iseditable={isEditable ? "true" : undefined}
        />
      ) : null}

      <StyledCarouselNode
        $isActive={active ? active : selectedNodeInfo?.id === id}
        onClick={() => handleNodeClick(id)}
      >
        <div className="titleContainer">
          <img src={CarouselNodeIcon} alt="carouselNodeIcon" />
          <span className="titleText">{titleText}</span>
        </div>

        {isEditable && (
          <ActionButtonContainer
            className="action-btns"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={DeleteIconImg}
              alt="deleteIcon"
              onClick={() => {
                //   deleteNode(id);
                setVisible(true);
              }}
            />
          </ActionButtonContainer>
        )}

        {(isVariableMissing || isMessageEmpty) && (
          <div
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
            }}
          >
            <Tooltip
              title={
                isMessageEmpty
                  ? "Message is empty"
                  : "This node contains non-existing variable"
              }
            >
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  style={{ color: "#fdd626" }}
                />
              </span>
            </Tooltip>
          </div>
        )}

        {visible && isEditable && (
          <ActionButtonContainer
            className="action-btns"
            onClick={(e) => e.stopPropagation()}
            style={{
              right: "-315px",
              top: "-35px",
              height: "auto",
              width: "250px",
              padding: "10px",
            }}
          >
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "Plus Jakarta Sans, sans-serif",
                marginBottom: "10px",
                color: "#000",
              }}
            >
              Are you sure you want to delete?
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "150px",
                margin: "auto",
                padding: "10px 8px",
              }}
            >
              <button
                style={{
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  color: "#007cc5",
                  border: "1px solid #007cc5",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setVisible(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  color: "#ef4949",
                  border: "1px solid #ef4949",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  setVisible(false);
                  deleteNode(id);
                }}
              >
                Delete
              </button>
            </div>
          </ActionButtonContainer>
        )}
        {/* 
                {!isSuccessEdgeConnected ? (
                    <AddIcon
                        className="addIcon success"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowAddBox(!showAddBox);
                            setSourceHandle("bottom-success");
                        }}
                    />
                ) : null}

                {!isFailureEdgeConnected ? (
                    <AddIcon
                        className="addIcon failure"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowAddBox(!showAddBox);
                            setSourceHandle("bottom-failure");
                        }}
                    />
                ) : null} */}

        {showAddBox ? (
          <AddNodeSelector nodeData={props} setShowAddBox={setShowAddBox} />
        ) : null}
      </StyledCarouselNode>

      {isBottom ? (
        <BottomEdge
          type="source"
          position={Position.Bottom}
          id="bottom"
          iseditable={isEditable ? "true" : undefined}
          isConnectable={!isBottomEdgeConnected}
          onClick={(e) => {
            e.stopPropagation();
            handleNodeClick(id);
            setShowAddBox(!showAddBox);
          }}
        />
      ) : null}
    </>
  );
}

export default CarouselNode;
