import styled from "styled-components";

export const SidePanelContainer = styled.div`
    width: 377px;
    height: 100%;
    position: fixed;
    right: 0;
    top: 50px;
    padding-top: 20px;
    padding-bottom: 30px;
    border-left: 1px solid #e8e9ef;
    background-color: #ffffff;
    overflow-y: auto;
    transform: ${(props) => `translateX(${props.$xValue}%)`};
    transition: transform 0.25s ease 0s;
`;

export const SidePanelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background-color: #ffffff;

    .closeIcon {
        cursor: pointer;
    }
`;

export const SidePanelBody = styled.div`
    padding: 20px 30px;
`;
