import styled from "styled-components";

export const ImagePicker = styled.div`
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &::before {
        content: "Upload an image";
        position: absolute;
        width: 100%;
        height: 200px;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
    }

    img {
        width: 100%;
        height: 100%;
    }

    input {
        display: none;
    }
`;

export const Input = styled.input`
    color: #101010;
    padding: 6px 0px;
    font-size: 18px;
    background: #ffffff;
    line-height: normal;
    border: 0;
    border-bottom: 2px solid #afb2b3;
    width: 100%;
    font-weight: bold;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-bottom: 20px;

    &:focus {
        outline: none;
    }
`;

export const TitleInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    p {
        color: #858585;
        font-size: 14px;
        text-align: left;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
    }
`;

export const MessageInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    p {
        color: #101010;
        font-size: 15px;
        text-align: left;
        margin-bottom: 4px;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }

    span.note {
        font-size: 11px;
        font-family: "Plus Jakarta Sans", sans-serif;
        color: #858585;
        margin-top: 5px;
        margin-bottom: 20px;
        text-align: left;
    }

    & > .ck {
        width: 100%;
    }

    .ck-editor__editable {
        min-height: 100px;
    }

    & > .select-container {
        width: 100%;
        text-align: left;
    }

    .wrapper-class {
        border: 1px solid #ccc;
        width: 100%;
    }
    .editor-class {
        padding: 0 0.5rem;
        overflow: unset;
    }
    .toolbar-class {
        border-bottom: 1px solid #ccc;
    }
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;

    button {
        color: #2a7cff;
        border: 1px solid #2a7cff;
        padding: 8px 12px;
        background: #ffffff;
        font-size: 14px;
        font-family: "Plus Jakarta Sans", sans-serif;
        border-radius: 3px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        line-height: 1;

        img {
            width: 15px;
        }
    }
`;

export const CardsListContainer = styled.div`
    margin-bottom: 20px;

    .card {
        width: 100%;
        border: 1px solid #dadada;
        padding: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s linear;

        &:hover {
            border: 1px solid #2a7cff;
        }

        img {
            visibility: hidden;
        }

        &:hover {
            img {
                visibility: visible;
            }
        }

        p {
            margin: 0;
            padding: 0;
        }
    }
`;

export const InputContainer = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    p {
        color: #101010;
        font-size: 15px;
        text-align: left;
        margin-bottom: 4px;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }

    .input {
        width: 100%;
        outline: none;
        border: 1px solid rgb(133, 133, 133);
        border-radius: 4px;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 15px;
        color: rgb(16, 16, 16);
        padding: 0px 10px;
        height: 40px;
    }
`;

export const ButtonsListContainer = styled.div`
    margin-bottom: 20px;

    .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;

        &:hover {
            .input-container {
                img {
                    visibility: visible;
                }
            }
        }

        .input-container {
            width: 100%;
            display: flex;
            gap: 10px;

            .inp-cont {
                width: 100%;
                display: flex;
                position: relative;
            }

            .icon-container {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                height: 24px;
            }

            .label-item {
                position: absolute;
                right: 24px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 12px;
                border-radius: 2px;
            }

            .absolute-select {
                position: absolute;
                right: 35%;
                top: 0px;
                .custom-controller {
                    display: none;
                }

                .custom-menu {
                    width: fit-content;
                }
            }

            input {
                width: 100%;
                border: 1px solid #2a7cff;
                height: 40px;
                border-radius: 3px;
                text-align: left;
                font-family: "Plus Jakarta Sans", sans-serif;
                padding: 0 10px;

                &:focus {
                    outline: none;
                }
            }

            img {
                cursor: pointer;
            }
        }

        span {
            font-size: 11px;
            color: #858585;
            text-align: left;
            margin-top: 5px;
        }
    }
`;
