import styled from "styled-components";

export const ButtonNode = styled.div`
    width: 120px;
    height: 45px;
    background-color: ${(props) => props?.$bgColor ?? "#828d93"};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: ${(props) => props?.$textColor ?? "#fff"};
    padding: 5px 20px;
    border-radius: 35px;
    box-sizing: border-box;

    .titleContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        span {
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
`;
