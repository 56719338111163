import {
  Input,
  MessageInputContainer,
  TitleInputContainer,
} from "./JumpToProfileEditor.styled";
import NodeContext from "../../context/NodeContext";
import { useCallback, useContext } from "react";
import Select from "react-select";
import { LABELS } from "../../utils/labels";
import { useState } from "react";
import { useEffect } from "react";
// import { MyOption } from "../Searchbar";
// import { useReducer } from "react";
// import { matchSorter } from "match-sorter";

function JumpToProfileEditor() {
  const { selectedNodeInfo, updateNode, nodes, isEditable, profiles, getProfileIdQuestions, profileQuestionOptions } =
    useContext(NodeContext);
  const [selectedOption, setSelectedOption] = useState(selectedNodeInfo?.data?.value === "0" ? null : {
    value: selectedNodeInfo?.data?.value,
    label: selectedNodeInfo?.data?.label,
  });

  const getProfileQuestions = useCallback(async (profileId) => {
    await getProfileIdQuestions(profileId);
  }, [getProfileIdQuestions]);

  const createOptions = useCallback(() => {
    const options = [];
    profiles.forEach((profile) => {
      options.push({
        value: profile.profileId,
        label: profile.profileName,
      });
    });
    return options;
  }, [profiles]);


  useEffect(() => {
    if (selectedNodeInfo?.data?.value !== "0") {
      getProfileQuestions(selectedNodeInfo?.data?.value);
    }
  }, [selectedNodeInfo?.data?.value, getProfileQuestions]);

  if (!selectedNodeInfo) {
    return null;
  }

  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
        <p>
          Allows you to route the conversation flow from this point to the below mentioned profile.
        </p>
      </TitleInputContainer>
      <MessageInputContainer>
        <p>{LABELS.GO_TO_PROFILE}</p>
        <Select
          styles={{
            control: (base) => ({
              ...base,
              fontFamily: "'Plus Jakarta Sans', sans-serif",
            }),
            option: (base) => ({
              ...base,
              fontFamily: "'Plus Jakarta Sans', sans-serif",
            }),
          }}
          options={createOptions()}
          className="select-container"
          placeholder="Select A Profile"
          value={
            nodes
              .filter((node) => node?.id === selectedNodeInfo?.id)
              .map((node) => ({
                value: node?.data.value,
                label: node?.data.label,
              }))[0]
          }
          onChange={(e) => {
            getProfileQuestions(e ? e.value : null);
            setSelectedOption(e);
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              value: e ? e.value : "0",
              label: e ? e.label : "Select a profile",
              questionId: "0",
              questionValue: "Select a question",
            });
          }}
          defaultValue={{
            value: "0",
            label: "Select a profile",
          }}
          isClearable={selectedNodeInfo?.data?.value === "0" ? false : true}
          key={selectedNodeInfo?.id}
          isDisabled={!isEditable}
        />
      </MessageInputContainer>
      {(profileQuestionOptions.length > 0 || selectedOption) && <MessageInputContainer>
        <p>Select a question</p>
        <Select
          aria-labelledby="aria-label"
          inputId="aria-example-input"
          name="aria-live-color"
          placeholder="Select a question"
          options={profileQuestionOptions}
          styles={{
            control: (base) => ({
              ...base,
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              width: "315px",
            }),
            option: (base) => ({
              ...base,
              fontFamily: "'Plus Jakarta Sans', sans-serif",
            }),
          }}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              questionId: e ? e.value : "0",
              questionValue: e ? e.label : "Select a question",
            });
          }}
          value={
            nodes
              .filter((node) => node?.id === selectedNodeInfo?.id)
              .map((node) => ({
                value: node?.data.questionId,
                label: node?.data.questionValue,
              }))[0]
          }
          isClearable={!selectedNodeInfo?.data?.questionId || selectedNodeInfo?.data?.questionId === "0" ? false : true}
          defaultValue={{
            value: "0",
            label: "Select a question",
          }}
        />
        {/* <Select
            // ref={searchRef}
            openMenuOnFocus={true}
            className="basic-single"
            classNamePrefix="select"
            isSearchable={true}
            onChange={(e) => {
              setSelectedOption(e);
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                questionId: e ? e.value : "0",
                questionValue: e ? e.label : "Select a question",
              });
            }}
            menuIsOpen={isMenuOpen}
            onInputChange={handleInputChange}
            value={selectedOption}
            name="nodes"
            placeholder="Search"
            options={options}
            styles={{
              control: (provided, state) => ({
                ...provided,
                width: "100%",
                border: "1px solid #ccc",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 0px 0px 10px",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                fontSize: "15px",
                fontWeight: "400",
                color: "#007cc5",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #ccc",
                },
                textAlign: "left",
              }),
              menu: (provided, state) => ({
                ...provided,
                width: "100%",
                border: "1px solid #ccc",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                fontSize: "13px",
                fontWeight: "400",
                boxShadow: "none",
              }),
              option: (provided, state) => ({
                ...provided,
                width: "100%",
                textAlign: "left",
                marginLeft: "4px",
                marginRight: "4px",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                fontSize: "13px",
                fontWeight: "400",
                boxShadow: "none",
              }),
            }}
            components={{
              // DropdownIndicator: () => (
              //   <div style={{ position: "absolute", left: "10px" }}>
              //     <FontAwesomeIcon icon={faSearch} color="grey" />
              //   </div>
              // ),
              // IndicatorSeparator: () => null,
              Option: MyOption,
            }}
          /> */}
      </MessageInputContainer>}
    </>
  );
}

export default JumpToProfileEditor;
