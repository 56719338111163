// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  // CheckboxContainer,
  Input,
  InputContainer,
  MessageInputContainer,
  TitleInputContainer,
} from "./EmailNodeEditor.styled";
import NodeContext from "../../context/NodeContext";
import { useContext, useRef, useState } from "react";
import Select from "react-select";
import { emailTypeOptions, toolbar } from "../../utils/constants";
import { Editor } from "react-draft-wysiwyg";
import { getHTMLToDraft } from "../../utils/helper";
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { flowModuleApi } from "../../api/FlowModule/api";
import DeleteIconImg from "../../assets/images/deleteIcon.svg";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import DraggableList from "../DraggableList";
import { LABELS } from "../../utils/labels";
import CreateableSelect from "../CreateableSelect";
import { toast } from "react-toastify";

function EmailNodeEditor() {
  const { selectedNodeInfo, updateNode, variableList, isEditable, setNewAddedNode } =
    useContext(NodeContext);
  const [displayError, setDisplayError] = useState(
    !selectedNodeInfo.data.isValidLength ?? false
  );
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const ref = useRef();
  const [items, setItems] = useState(selectedNodeInfo?.data?.attachments);
  useEffect(() => {
    setItems(selectedNodeInfo?.data?.attachments);
  }, [selectedNodeInfo?.data?.attachments]);

  if (!selectedNodeInfo) {
    return null;
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const itemsCopy = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(itemsCopy);
    updateNode(selectedNodeInfo.id, {
      ...selectedNodeInfo?.data,
      attachments: itemsCopy,
    });
  };

  const itemRenderer = (item, index) => (
    <div
      key={index}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "5px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "5px 10px",
        width: "100%",
        marginBottom: "10px",
        marginLeft: isEditable ? "10px" : "0px",
        position: "relative",
      }}
    >
      <Link
        to={item.url}
        target="_blank"
        style={{
          color: "rgb(16, 16, 16)",
          fontSize: "14px",
          fontFamily: "Plus Jakarta Sans, sans-serif",
          fontWeight: "400",
          overflow: "hidden",
        }}
      >
        {item.name}
      </Link>
      {isEditable &&
        (loadingFileUpload && !item.url ? (
          <FontAwesomeIcon
            icon={faSpinner}
            style={{
              color: "#828d93",
              width: "13px",
              height: "25px",
            }}
            spin={true}
          />
        ) : (
          <img
            src={DeleteIconImg}
            style={{ cursor: "pointer" }}
            alt="deleteIcon"
            onClick={() => {
              const attachments = selectedNodeInfo?.data?.attachments.filter(
                (attachment) => attachment.id !== item.id
              );
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                attachments,
              });
            }}
          />
        ))}
    </div>
  );

  const updateNumbers = (numbers) => {
    updateNode(selectedNodeInfo.id, {
      ...selectedNodeInfo?.data,
      toNumbers: numbers,
    });
  };

  const updateEmails = (emails) => {
    updateNode(selectedNodeInfo.id, {
      ...selectedNodeInfo?.data,
      toEmails: emails,
    });
  };

  return (
    <div key={selectedNodeInfo.id}>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
        <p>Send Email or SMS is used to send email or sms</p>
      </TitleInputContainer>

      <MessageInputContainer>
        <p>{LABELS.EMAIL_NODE_TYPE}</p>
        <Select
          options={emailTypeOptions}
          className="select-container"
          placeholder="Select subtype"
          value={emailTypeOptions.find(
            (o) => o.value === selectedNodeInfo?.data?.messageType
          )}
          onChange={(e) => {
            let isMessageEmpty = true;
            if (
              e.value === "sms" &&
              selectedNodeInfo?.data?.smsMessage?.length === 0
            ) {
              isMessageEmpty = true;
            } else if (
              e.value === "email" &&
              !selectedNodeInfo?.data?.message.getCurrentContent().hasText()
            ) {
              isMessageEmpty = true;
            } else {
              isMessageEmpty = false;
            }
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              messageType: e.value,
              isMessageEmpty: isMessageEmpty,
            });
          }}
          key={selectedNodeInfo?.id}
          isDisabled={!isEditable}
        />
      </MessageInputContainer>

      {selectedNodeInfo?.data?.messageType === "sms" ? (
        <>
          <InputContainer>
            <p>To</p>
            <section key={selectedNodeInfo.id}>
              <CreateableSelect
                placeholder="Type number and press enter..."
                values={selectedNodeInfo?.data?.toNumbers}
                updateFn={updateNumbers}
                type={"numbers"}
              />
            </section>
          </InputContainer>
          <InputContainer>
            <p>SMS text</p>
            {displayError && (
              <Tooltip title="Message length should be less than 160 charachters">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  style={{
                    color: "#ffcc00",
                    fontSize: "18px",
                    display: "",
                    position: "absolute",
                    right: "30px",
                  }}
                />
              </Tooltip>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "2px",
                width: "100%",
              }}
            >
              <textarea
                style={{
                  position: "relative",
                  paddingRight: displayError ? "35px" : "10px",
                  minHeight: "100px",
                  padding: "2px",
                }}
                className="input"
                placeholder="Enter SMS Text"
                value={selectedNodeInfo?.data?.smsMessage}
                onChange={(e) => {
                  const isValidLength = e.target.value.length <= 160;
                  if (!isValidLength) {
                    setDisplayError(true);
                  } else {
                    setDisplayError(false);
                  }
                  const isMessageEmpty = e.target.value.length === 0;

                  updateNode(selectedNodeInfo.id, {
                    ...selectedNodeInfo?.data,
                    smsMessage: e.target.value,
                    isValidLength,
                    isMessageEmpty,
                  });
                }}
                readOnly={!isEditable}
              />
            </div>
          </InputContainer>
        </>
      ) : selectedNodeInfo?.data?.messageType === "email" ? (
        <>
          <InputContainer>
            <p>To</p>
            <div key={selectedNodeInfo.id}>
              <CreateableSelect
                values={selectedNodeInfo.data.toEmails}
                updateFn={updateEmails}
                placeholder="Type email and press enter..."
                type={"emails"}
              />
            </div>
          </InputContainer>
          <InputContainer>
            <p>{LABELS.EMAIL_NODE_SUBJECT}</p>
            <input
              className="input"
              placeholder="Subject for the email..."
              value={selectedNodeInfo?.data?.emailSubject}
              onChange={(e) => {
                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  emailSubject: e.target.value,
                });
              }}
              readOnly={!isEditable}
            />
          </InputContainer>
          <MessageInputContainer>
            <p>{LABELS.EMAIL_NODE_MESSAGE_BODY}</p>
            <Editor
              editorState={getHTMLToDraft(selectedNodeInfo?.data?.message)}
              onEditorStateChange={(data) => {
                const isMessageEmpty = data.getCurrentContent().hasText();
                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  message: data,
                  isMessageEmpty: !isMessageEmpty,
                });
              }}
              onBlur={() => {
                setNewAddedNode(true);
              }}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              toolbar={toolbar}
              mention={{
                separator: " ",
                trigger: "{",
                suggestions: variableList,
              }}
              placeholder="What is your..."
              readOnly={!isEditable}
              stripPastedStyles={true}
            />
            {/* <MyEditor
              selectedNodeInfo={selectedNodeInfo}
              updateNode={updateNode}
            /> */}
          </MessageInputContainer>
          <InputContainer>
            <p>{LABELS.EMAIL_NODE_ATTACHMENTS}</p>
            {selectedNodeInfo?.data?.attachments.length > 0 ? (
              <div style={{ width: "100%" }}>
                <DraggableList
                  itemsArray={items}
                  itemRenderer={itemRenderer}
                  handleOnDragEnd={handleOnDragEnd}
                  isEditable={isEditable}
                />
              </div>
            ) : (
              <span
                style={{
                  color: "#101010",
                  fontSize: "14px",
                  textAlign: "left",
                  fontFamily: "Plus Jakarta Sans, sans-serif",
                  fontWeight: "300",
                  padding: "0",
                  margin: "auto auto 10px auto",
                }}
              >
                No attachments added
              </span>
            )}

            {selectedNodeInfo.data.attachments.length < 5 && isEditable && (
              <>
                <button
                  style={{
                    width: "100%",
                    outline: "none",
                    border: `1px solid ${loadingFileUpload ? "rgba(133, 133, 133,0.5)" : "rgb(133, 133, 133)"}`,
                    borderRadius: "4px",
                    fontFamily: "Plus Jakarta Sans, sans-serif",
                    fontSize: "15px",
                    color: `${loadingFileUpload ? "rgba(16, 16, 16, 0.5)" : "rgb(16, 16, 16)"}`,
                    padding: "0px 10px",
                    height: "40px",
                    cursor: `${loadingFileUpload ? "not-allowed" : "pointer"}`,
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    ref.current.click();
                  }}
                  disabled={loadingFileUpload}
                >
                  {loadingFileUpload ? <FontAwesomeIcon
                    icon={faSpinner}
                    style={{
                      color: "#828d93",
                      width: "13px",
                      height: "25px",
                    }}
                    spin={true}
                  /> : "Add New Attachment"}
                </button>

                <input
                  ref={ref}
                  className="input"
                  style={{ display: "none" }}
                  type="file"
                  accept=".pdf, .doc, .docx, .png, .jpg"
                  placeholder="Upload Attachment"
                  onChange={(e) => {
                    if (!e.target.files[0]) return;
                    const allowedExtensions = [
                      "pdf",
                      "doc",
                      "docx",
                      "png",
                      "jpg",
                    ];
                    const fileExtension = e.target.files[0].name
                      .split(".")
                      .pop();
                    if (!allowedExtensions.includes(fileExtension)) {
                      toast.error("Please change the error message with. File type is not supported, Please choose pdf, jpg, png, doc or docx only.", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      return;
                    }
                    if (e.target.files[0].size / 1024 / 1024 > 2) {
                      toast.error("File size should be less than 2 MB", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      return;
                    }
                    updateNode(selectedNodeInfo.id, {
                      ...selectedNodeInfo?.data,
                      attachments: [
                        ...selectedNodeInfo?.data?.attachments,
                        {
                          id: uuidv4(),
                          name: e.target.files[0].name,
                        },
                      ],
                    });
                    setLoadingFileUpload(true);
                    const reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = async () => {
                      const base64 = reader.result;
                      const imageToBase64 = base64.split(",")[1];
                      try {
                        const { fileUrl } = await flowModuleApi.uploadFile({
                          fileName: e.target.files[0].name,
                          filebase64: imageToBase64,
                        });
                        const attachments =
                          selectedNodeInfo?.data?.attachments.map(
                            (attachment) => {
                              if (attachment.name === e.target.files[0].name) {
                                return {
                                  ...attachment,
                                  url: fileUrl,
                                };
                              }
                              return attachment;
                            }
                          );
                        updateNode(selectedNodeInfo.id, {
                          ...selectedNodeInfo?.data,
                          attachments,
                        });
                      } catch {
                        toast.error("Error while uploading file", {
                          position: "top-center",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                        const attachments =
                          selectedNodeInfo?.data?.attachments.filter(
                            (attachment) =>
                              attachment.name !== e.target.files[0].name
                          );
                        updateNode(selectedNodeInfo.id, {
                          ...selectedNodeInfo?.data,
                          attachments,
                        });
                      }
                      e.target.value = null;
                      e.target.files = null;
                      setLoadingFileUpload(false);
                    };
                  }}
                  multiple={false}
                />
              </>
            )}
          </InputContainer>
        </>
      ) : null}
    </div>
  );
}

export default EmailNodeEditor;
