import { BoxContainer, NodeItem, SearchBar } from "./AddNodeSelector.styled";
import MessageIcon from "../../assets/images/messageIcon.svg";
import EmailIcon from "../../assets/images/emailIcon.svg";
import CollectInputIcon from "../../assets/images/collectInput.svg";
import ButtonNodeIcon from "../../assets/images/buttonIcon.svg";
import FileNodeIcon from "../../assets/images/fileIcon.svg";
import CodeBlockIcon from "../../assets/images/codeBlockIcon.svg";
import EndIcon from "../../assets/images/endIcon.svg";
import ServiceCallNodeIcon from "../../assets/images/serviceCallIcon.svg";
import CarouselNodeIcon from "../../assets/images/carouselIcon.svg";
import ConditionalIcon from "../../assets/images/conditional.svg";
import JumpToIcon from "../../assets/images/gotoicon.svg";
import JumpToPurpleIcon from "../../assets/images/gotoicon-purple.svg";
import searchIcon from "../../assets/images/searchIcon.svg";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import NodeContext from "../../context/NodeContext";
import { BUTTON_TYPE, NODE_TYPE } from "../../utils/constants";
import { v4 as uuidv4 } from "uuid";
import { getHTMLToDraft } from "../../utils/helper";

const AddNodeSelector = ({ setShowAddBox, nodeData }) => {
  const ref = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const { onAdd, nodeTextCounter, isSuperAdmin } = useContext(NodeContext);

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const handleAddMessageNode = useCallback(
    (e) => {
      e.stopPropagation();
      const textNumber = nodeTextCounter[NODE_TYPE.MESSAGE_NODE];

      onAdd(nodeData, {
        type: NODE_TYPE.MESSAGE_NODE,
        titleText: `Send message ${textNumber}`,
        messages: [
          {
            id: uuidv4(),
            text: getHTMLToDraft(""),
          },
        ],
        isVariableMissing: false,
        isMessageEmpty: true,
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox, nodeTextCounter]
  );

  const handleAddEmailNode = useCallback(
    (e) => {
      e.stopPropagation();
      const textNumber = nodeTextCounter[NODE_TYPE.EMAIL_NODE];

      onAdd(nodeData, {
        type: NODE_TYPE.EMAIL_NODE,
        titleText: `Send Email or SMS ${textNumber}`,
        messageType: "email",
        emailSubject: "",
        subjectPlaceholder: "Subject for the email...",
        message: getHTMLToDraft(""),
        attachments: [],
        smsMessage: "",
        messagePlaceholder: "Enter SMS text...",
        isVariableMissing: false,
        isMessageEmpty: true,
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox, nodeTextCounter]
  );

  const handleAddCollectInputNode = useCallback(
    (e) => {
      e.stopPropagation();
      const textNumber = nodeTextCounter[NODE_TYPE.COLLECT_INPUT_NODE];

      onAdd(nodeData, {
        type: NODE_TYPE.COLLECT_INPUT_NODE,
        titleText: `Ask a Text Question ${textNumber}`,
        message: ``,
        variableName: "",
        regexValue: "",
        subType: "",
        trackLinks: false,
        suggestionText: "",
        validationText: "",
        dateFormat: "",
        isVariableMissing: false,
        isMessageEmpty: true,
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox, nodeTextCounter]
  );

  const handleAddButtonNode = useCallback(
    (e) => {
      e.stopPropagation();
      const textNumber = nodeTextCounter[NODE_TYPE.BUTTONS_NODE];

      const buttonNode = {
        type: NODE_TYPE.BUTTON_NODE,
        assessmentValue: "Shortlisted",
        buttonType: BUTTON_TYPE.BRANCH_BUTTON,
        titleText: `Choice 1`,
        buttonNodeId: uuidv4(),
        url: "",
        newTab: true,
        id: uuidv4(),
      };

      onAdd(nodeData, {
        type: NODE_TYPE.BUTTONS_NODE,
        titleText: `Ask a Choice Question ${textNumber}`,
        message: getHTMLToDraft(""),
        buttons: [buttonNode],
        isVariableMissing: false,
        isMessageEmpty: true,
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox, nodeTextCounter]
  );

  const handleAddFileNode = useCallback(
    (e) => {
      e.stopPropagation();
      const textNumber = nodeTextCounter[NODE_TYPE.FILE_UPLOAD_NODE];

      onAdd(nodeData, {
        type: NODE_TYPE.FILE_UPLOAD_NODE,
        titleText: `Collect Document ${textNumber}`,
        message: getHTMLToDraft(""),
        fileTypes: [],
        variableName: "",
        suggestionText: "",
        validationText: "",
        isVariableMissing: false,
        isMessageEmpty: true,
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox, nodeTextCounter]
  );

  // const handleAddCalendarNode = useCallback(
  //     (e) => {
  //         e.stopPropagation();
  //         const textNumber = nodeTextCounter[NODE_TYPE.CALENDAR_NODE];

  //         onAdd(nodeData, {
  //             type: NODE_TYPE.CALENDAR_NODE,
  //             titleText: `Calendar ${textNumber}`,
  //             message: ``,
  //         });
  //         setShowAddBox(false);
  //     },
  //     [nodeData, onAdd, setShowAddBox, nodeTextCounter]
  // );

  const handleAddCodeBlockNode = useCallback(
    (e) => {
      e.stopPropagation();
      const textNumber = nodeTextCounter[NODE_TYPE.CODE_BLOCK_NODE];

      onAdd(nodeData, {
        type: NODE_TYPE.CODE_BLOCK_NODE,
        titleText: `Code Block ${textNumber}`,
        code: ``,
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox, nodeTextCounter]
  );

  const handleAddServiceCallNode = useCallback(
    (e) => {
      e.stopPropagation();
      const textNumber = nodeTextCounter[NODE_TYPE.SERVICE_CALL_NODE];

      onAdd(nodeData, {
        type: NODE_TYPE.SERVICE_CALL_NODE,
        titleText: `Service Call ${textNumber}`,
        requestType: "GET",
        requestUrl: "",
        requestHeaders: [
          {
            id: uuidv4(),
            key: "",
            value: "",
          },
        ],
        requestBodyType: "raw",
        rawRequestBody: "",
        requestBodyFormData: [
          {
            id: uuidv4(),
            key: "",
            value: "",
          },
        ],
        saveResponseFields: [
          {
            id: uuidv4(),
            key: "",
            value: "",
          },
        ],
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox, nodeTextCounter]
  );

  // const handleAddCarouselNode = useCallback(
  //   (e) => {
  //     e.stopPropagation();
  //     const textNumber = nodeTextCounter[NODE_TYPE.CAROUSEL_NODE];

  //     const buttonNode = {
  //       type: NODE_TYPE.BUTTON_NODE,
  //       assessmentValue: "",
  //       buttonType: BUTTON_TYPE.BRANCH_BUTTON,
  //       titleText: `Choice 1`,
  //       buttonNodeId: uuidv4(),
  //       url: "",
  //       newTab: true,
  //     };

  //     const cardNode = {
  //       titleText: `Card 1`,
  //       message: "",
  //       cardNodeId: uuidv4(),
  //       buttons: [buttonNode],
  //       image: "",
  //     };

  //     onAdd(nodeData, {
  //       type: NODE_TYPE.CAROUSEL_NODE,
  //       titleText: `Carousel ${textNumber}`,
  //       cards: [cardNode],
  //     });
  //     setShowAddBox(false);
  //   },
  //   [nodeData, onAdd, setShowAddBox, nodeTextCounter]
  // );

  const handleAddCardNode = useCallback(
    (e) => {
      e.stopPropagation();
      const textNumber = nodeTextCounter[NODE_TYPE.CARD_NODE];

      const buttonNode = {
        buttonType: BUTTON_TYPE.URL_BUTTON,
        titleText: `Choice 1`,
        buttonNodeId: uuidv4(),
        url: "",
      };

      onAdd(nodeData, {
        type: NODE_TYPE.CARD_NODE,
        titleText: `Card View ${textNumber}`,
        message: getHTMLToDraft(""),
        buttons: [buttonNode],
        isVariableMissing: false,
        isMessageEmpty: true,
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox, nodeTextCounter]
  );

  const handleAddEndNode = useCallback(
    (e) => {
      e.stopPropagation();
      // const textNumber = nodeTextCounter[NODE_TYPE.END_NODE];

      onAdd(nodeData, {
        type: NODE_TYPE.END_NODE,
        titleText: "End Chat",
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox]
  );

  const handleAddJumpToNode = useCallback(
    (e) => {
      e.stopPropagation();

      onAdd(nodeData, {
        type: NODE_TYPE.JUMP_TO_NODE,
        titleText: "Go To",
        label: "Select an action block",
        value: "0",
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox]
  );

  const handleAddJumpToProfileNode = useCallback(
    (e) => {
      e.stopPropagation();

      onAdd(nodeData, {
        type: NODE_TYPE.JUMP_TO_PROFILE_NODE,
        titleText: "Go To Profile",
        label: "Select a profile",
        value: "0",
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox]
  );

  const handleAddConditionalBlock = useCallback(
    (e) => {
      e.stopPropagation();
      const textNumber = nodeTextCounter[NODE_TYPE.CONDITIONAL_NODE];

      onAdd(nodeData, {
        type: NODE_TYPE.CONDITIONAL_NODE,
        titleText: `Conditional Block ${textNumber}`,
        conditions: [
          {
            variableName: "",
            condition: "",
            value: "",
            andOr: "and",
            conditionTitle: `1`,
          },
        ],
      });
      setShowAddBox(false);
    },
    [nodeData, onAdd, setShowAddBox, nodeTextCounter]
  );

  const handleNodeClick = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowAddBox(false);
      }
    },
    [ref, setShowAddBox]
  );

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("click", handleNodeClick);
    }, 300);

    return () => {
      window.removeEventListener("click", handleNodeClick);
    };
  }, [ref, setShowAddBox, handleNodeClick]);

  let nodesTypeList = [
    {
      type: NODE_TYPE.MESSAGE_NODE,
      title: "Send Message",
      icon: MessageIcon,
      onClick: handleAddMessageNode,
    },
    {
      type: NODE_TYPE.COLLECT_INPUT_NODE,
      title: "Ask a Text Question",
      icon: CollectInputIcon,
      onClick: handleAddCollectInputNode,
    },
    {
      type: NODE_TYPE.BUTTON_NODE,
      title: "Ask a Choice Question",
      icon: ButtonNodeIcon,
      onClick: handleAddButtonNode,
    },
    {
      type: NODE_TYPE.CARD_NODE,
      title: "Card View",
      icon: CarouselNodeIcon,
      onClick: handleAddCardNode,
    },
    {
      type: NODE_TYPE.FILE_NODE,
      title: "Collect Document",
      icon: FileNodeIcon,
      onClick: handleAddFileNode,
    },
    {
      type: NODE_TYPE.EMAIL_NODE,
      title: "Email or SMS",
      icon: EmailIcon,
      onClick: handleAddEmailNode,
    },
    {
      type: NODE_TYPE.JUMP_TO_PROFILE_NODE,
      title: "Go to Profile",
      icon: JumpToPurpleIcon,
      onClick: handleAddJumpToProfileNode,
    },
    {
      type: NODE_TYPE.JUMP_TO_NODE,
      title: "Go to Node",
      icon: JumpToIcon,
      onClick: handleAddJumpToNode,
    },
    {
      type: NODE_TYPE.END_NODE,
      title: "End Chat",
      icon: EndIcon,
      onClick: handleAddEndNode,
    },
    {
      type: NODE_TYPE.CODE_BLOCK_NODE,
      title: "Code Block",
      icon: CodeBlockIcon,
      onClick: handleAddCodeBlockNode,
    },
    {
      type: NODE_TYPE.SERVICE_CALL_NODE,
      title: "Service Call",
      icon: ServiceCallNodeIcon,
      onClick: handleAddServiceCallNode,
    },
    {
      type: NODE_TYPE.CONDITIONAL_NODE,
      title: "Conditional Block",
      icon: ConditionalIcon,
      onClick: handleAddConditionalBlock,
    }
  ];

  // if (isSuperAdmin) {
  //   nodesTypeList.insert(7, {
  //     type: NODE_TYPE.CODE_BLOCK_NODE,
  //     title: "Code Block",
  //     icon: CodeBlockIcon,
  //     onClick: handleAddCodeBlockNode,
  //   },
  //     {
  //       type: NODE_TYPE.SERVICE_CALL_NODE,
  //       title: "Service Call",
  //       icon: ServiceCallNodeIcon,
  //       onClick: handleAddServiceCallNode,
  //     },
  //     {
  //       type: NODE_TYPE.CONDITIONAL_NODE,
  //       title: "Conditional Block",
  //       icon: ConditionalIcon,
  //       onClick: handleAddConditionalBlock,
  //     },)
  // }

  if (searchInput.length > 0) {
    nodesTypeList = nodesTypeList.filter((node) =>
      node.title.trim().toLowerCase().includes(searchInput.trim().toLowerCase())
    );
  }

  return (
    <BoxContainer className="nowheel" ref={ref}>
      <SearchBar>
        <img src={searchIcon} alt="searchIcon" />
        <input onChange={handleChange} />
      </SearchBar>
      {nodesTypeList.map((nodeType, index) => (
        <NodeItem key={index}>
          <img src={nodeType.icon} alt={nodeType.title} />
          <p onClick={nodeType.onClick}>{nodeType.title}</p>
        </NodeItem>
      ))}
    </BoxContainer>
  );
};

export default AddNodeSelector;
