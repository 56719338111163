import { create } from "zustand";

const useAuth = (set) => ({
  token: null,
  requestIdentity: null,
  profileId: null,
  flowType: null,
  id: null,
  isEdit: false,
  isSuperAdmin: false,
  setAuth: (token, requestIdentity, id, flowType, isEdit, isSuperAdmin) =>
    set({
      token,
      requestIdentity,
      id,
      flowType,
      isEdit,
      isSuperAdmin,
    }),
});

const flowData = (set) => ({
  nodes: [],
  edges: [],
  viewport: {},
  setFlowData: (nodes, edges, viewport) => set({ nodes, edges, viewport }),
});

export const authStore = create(useAuth);
export const flowStore = create(flowData);
