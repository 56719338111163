import styled from "styled-components";

export const FileUploadNode = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 15px;
    background-color: white;
    border-radius: 3px;
    width: 280px;
    height: 105px;
    box-shadow: 0px 4px 15px rgba(0, 124, 197, 0.15),
        inset 0px -1px 0px rgba(175, 112, 221, 0.6);
    backdrop-filter: blur(18.5px);
    border-radius: 5px 0px 5px 5px;
    position: relative;
    transition: box-shadow 0.2s ease-in;
    outline: ${(props) => (props?.$isActive ? "3px solid #af70dd" : "none")};

    &:hover {
        box-shadow: 0px 5px 15px rgba(53, 156, 236, 0.2),
            inset 0px -1px 0px rgba(175, 112, 221, 0.3);
    }

    &:hover {
        .action-btns {
            visibility: visible;
        }
    }

    .titleContainer {
        display: flex;
        gap: 10px;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #af70dd;

        span {
            font-family: "Poppins";
            font-weight: 500;
            font-size: 14px;
            color: #af70dd;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    span.messageText {
        color: #222222;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 12px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        text-align: left;

        p {
            margin: 0;
            padding: 0;
        }
    }
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    position: absolute;
    top: 0;
    right: -50px;
    border: solid 1px #e8e9ef;
    background-color: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(232, 233, 239, 0.5);
    transition: all 1s ease;
    visibility: hidden;

    img {
        cursor: pointer;
    }
`;
