import {
  Input,
  InputContainer,
  MessageInputContainer,
  TitleInputContainer,
} from "./CollectInputNodeEditor.styled";
import NodeContext from "../../context/NodeContext";
import { useContext, useState } from "react";
import Select from "react-select";
import {
  dateFormatOptions,
  inputSubTypeOptions,
  toolbar,
} from "../../utils/constants";
import { Editor } from "react-draft-wysiwyg";
import { getHTMLToDraft } from "../../utils/helper";
import ResponseVariable from "../ResponseVariable/index";
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { LABELS } from "../../utils/labels";

function CollectInputNodeEditor() {
  const {
    selectedNodeInfo,
    updateNode,
    variableList,
    atsData,
    isEditable,
    saveData,
    setNewAddedNode,
  } = useContext(NodeContext);
  const [displayError, setDisplayError] = useState(
    selectedNodeInfo.data.isVariableNameAlreadyUsed ?? false
  );

  if (!selectedNodeInfo) {
    return null;
  }

  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
        <p>Collect input asks a question to a user which requires an answer</p>
      </TitleInputContainer>
      <MessageInputContainer>
        <p>{LABELS.COLLECT_INPUT_NODE_MESSAGE_BODY}</p>
        <Editor
          editorState={getHTMLToDraft(selectedNodeInfo?.data?.message)}
          onEditorStateChange={(data) => {
            const isMessageEmpty = data.getCurrentContent().hasText();
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              message: data,
              isMessageEmpty: !isMessageEmpty,
            });
          }}
          onBlur={() => {
            setNewAddedNode(true);
          }}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={toolbar}
          mention={{
            separator: " ",
            trigger: "{",
            suggestions: variableList,
          }}
          placeholder="What is your..."
          readOnly={!isEditable}
          stripPastedStyles={true}
        />
      </MessageInputContainer>
      <InputContainer>
        <p>{LABELS.RESPONSE_TYPE}</p>
        <Select
          options={inputSubTypeOptions}
          className="select-container"
          placeholder="Select subtype"
          value={
            inputSubTypeOptions.find(
              (o) => o.value === selectedNodeInfo?.data?.subType
            ) ?? { label: "Text", value: "text" }
          }
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              subType: e.value,
            });
          }}
          key={selectedNodeInfo?.id}
          isDisabled={!isEditable}
        />
      </InputContainer>
      {selectedNodeInfo?.data?.subType === "regex" ? (
        <InputContainer>
          <p>{LABELS.REGEX}</p>
          <input
            className="input"
            placeholder="Enter Regular Expression"
            value={selectedNodeInfo?.data?.regexValue}
            onChange={(e) => {
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                regexValue: e.target.value,
              });
            }}
            readOnly={!isEditable}
          />
        </InputContainer>
      ) : selectedNodeInfo?.data?.subType === "date" ? (
        <MessageInputContainer>
          <p>{LABELS.DATE_FORMAT}</p>
          <Select
            options={dateFormatOptions}
            className="select-container"
            placeholder="Select date format"
            value={dateFormatOptions.find(
              (o) => o.value === selectedNodeInfo?.data?.dateFormat
            )}
            onChange={(e) => {
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                dateFormat: e.value,
              });
            }}
            key={selectedNodeInfo?.id}
            isDisabled={!isEditable}
          />
        </MessageInputContainer>
      ) : null}
      <InputContainer>
        <p>{LABELS.INPUT_SUGGESTION}</p>
        <input
          className="input"
          placeholder="Enter Suggestion"
          value={selectedNodeInfo?.data?.suggestionText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              suggestionText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
      </InputContainer>
      <InputContainer>
        <p>{LABELS.INPUT_VALIDATION}</p>
        <input
          className="input"
          placeholder="Enter Validation Message"
          value={selectedNodeInfo?.data?.validationText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              validationText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
      </InputContainer>
      <InputContainer>
        {atsData && (
          <>
            <p>{LABELS.ATS_FIELD}</p>
            <Select
              options={atsData}
              className="select-container"
              defaultValue=""
              isClearable
              value={atsData.find(
                (o) => o.value === (selectedNodeInfo?.data?.atsValue || "")
              )}
              onChange={(e) => {
                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  atsValue: e ? e.value : e,
                });
                setTimeout(() => {
                  saveData();
                }, 10);
              }}
              key={selectedNodeInfo?.id}
              isDisabled={!isEditable}
              placeholder="Select ATS Field"
            />
          </>
        )}
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <ResponseVariable
            updateNodeFun={updateNode}
            selectedNodeInfo={selectedNodeInfo}
            iseditable={isEditable ? "true" : undefined}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "2px",
            width: "100%",
          }}
        >
          <input
            style={{
              position: "relative",
            }}
            className="input"
            placeholder="Enter Variable Name"
            value={selectedNodeInfo?.data?.variableName}
            onBlur={(e) => {
              setNewAddedNode(true);
            }}
            onChange={(e) => {
              if (e.target.value.length > 60) {
                return;
              }
              const isVariableNameAlreadyUsed = variableList.find(
                (item) => item.value === `{${e.target.value.trim()}}}`
              );
              if (isVariableNameAlreadyUsed) {
                setDisplayError(true);
              } else {
                setDisplayError(false);
              }
              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                variableName: e.target.value,
                isVariableNameAlreadyUsed: isVariableNameAlreadyUsed
                  ? true
                  : false,
              });
            }}
            readOnly={!isEditable}
          />
          {displayError && (
            <Tooltip title="This variable has already been used.">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                style={{
                  color: "#ffcc00",
                  fontSize: "18px",
                  display: "",
                  position: "absolute",
                  right: "40px",
                }}
              />
            </Tooltip>
          )}
        </div>
        <span className="note">
          You can add a variable that can be referenced later in the
          conversation.
        </span>
      </InputContainer>
    </>
  );
}

export default CollectInputNodeEditor;
