import { useContext, useMemo, useState } from "react";
import { Position } from "reactflow";
import CalendarIcon from "../../assets/images/calendarIcon.svg";
import NodeContext from "../../context/NodeContext";
import {
  ActionButtonContainer,
  CalendarNode as StyledCalendarNode,
} from "./CalendarNode.styled";
import DeleteIconImg from "../../assets/images/deleteIcon.svg";
import AddNodeSelector from "../../components/AddNodeSelector";
import { BottomEdge, TopEdge } from "../StartingNode/StartingNode.styled";
import { getDraftToHTML } from "../../utils/helper";
import { EMPTY_DRAFT } from "../../utils/constants";

function CalendarNode(props) {
  const { id, data } = props;
  const { titleText, message, isTop, isBottom } = data;
  const { handleNodeClick, edges, deleteNode, selectedNodeInfo } =
    useContext(NodeContext);
  const [showAddBox, setShowAddBox] = useState(false);
  const [visible, setVisible] = useState(false);

  const isBottomEdgeConnected = useMemo(
    () =>
      edges.findIndex(
        (edge) => edge.source === id && edge.sourceHandle === "bottom"
      ) !== -1,
    [edges, id]
  );

  const draftToHTML = useMemo(() => getDraftToHTML(message), [message]);

  return (
    <>
      {isTop ? (
        <TopEdge
          type="target"
          position={Position.Top}
          id="top"
          isConnectable={true}
        />
      ) : null}

      <StyledCalendarNode
        $isActive={selectedNodeInfo?.id === id}
        onClick={() => handleNodeClick(id)}
      >
        <div className="titleContainer">
          <img src={CalendarIcon} alt="calendarNodeIcon" />
          <span className="titleText">{titleText}</span>
        </div>

        {draftToHTML !== EMPTY_DRAFT && draftToHTML !== "" ? (
          <span
            className="messageText"
            dangerouslySetInnerHTML={{ __html: draftToHTML }}
          />
        ) : (
          <span className="messageText">What is your...</span>
        )}

        <ActionButtonContainer
          className="action-btns"
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src={DeleteIconImg}
            alt="deleteIcon"
            onClick={() => {
              // deleteNode(id);
              setVisible(true);
            }}
          />
        </ActionButtonContainer>

        {visible && (
          <ActionButtonContainer
            className="action-btns"
            onClick={(e) => e.stopPropagation()}
            style={{
              right: "-315px",
              top: "-35px",
              height: "auto",
              width: "250px",
              padding: "10px",
            }}
          >
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "Plus Jakarta Sans, sans-serif",
                marginBottom: "10px",
                color: "#000",
              }}
            >
              Are you sure you want to delete?
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "150px",
                margin: "auto",
                padding: "10px 8px",
              }}
            >
              <button
                style={{
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  color: "#007cc5",
                  border: "1px solid #007cc5",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setVisible(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  color: "#ef4949",
                  border: "1px solid #ef4949",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                onClick={() => {
                  setVisible(false);
                  deleteNode(id);
                }}
              >
                Delete
              </button>
            </div>
          </ActionButtonContainer>
        )}

        {showAddBox ? (
          <AddNodeSelector nodeData={props} setShowAddBox={setShowAddBox} />
        ) : null}
      </StyledCalendarNode>

      {isBottom ? (
        <BottomEdge
          type="source"
          position={Position.Bottom}
          id="bottom"
          isConnectable={!isBottomEdgeConnected}
          onClick={(e) => {
            e.stopPropagation();
            handleNodeClick(id);
            setShowAddBox(!showAddBox);
          }}
        />
      ) : null}
    </>
  );
}

export default CalendarNode;
