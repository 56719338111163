export const defaultNodeState = {
    "nodes": [
        {
            "id": "starting-node",
            "type": "startingNode",
            "position": {
                "x": 220,
                "y": 100
            },
            "data": {
                "startingText": "Invite Link",
                "isBottom": true,
                "type": "startingNode"
            },
            "width": 280,
            "height": 60
        }
    ],
    "edges": [],
    "viewport": {
        "x": 0,
        "y": 0,
        "zoom": 1
    }
}

export const defaultNodeScreeningState = {
    "nodes": [
        {
            "id": "starting-node",
            "type": "startingNode",
            "position": {
                "x": 220,
                "y": 100
            },
            "data": {
                "startingText": "Invite Link",
                "isBottom": true,
                "type": "startingNode"
            },
            "width": 280,
            "height": 60
        },
        {
            "id": "starting-node-2",
            "type": "startingNode",
            "position": {
                "x": 820,
                "y": 100
            },
            "data": {
                "startingText": "Apply Link",
                "isBottom": true,
                "type": "startingNode"
            },
            "width": 280,
            "height": 60
        }
    ],
    "edges": [],
    "viewport": {
        "x": 0,
        "y": 0,
        "zoom": 1
    }
}

export const FLOW_TYPE = {
    "TEMPLATE": "Template",
    "PROFILE": "Profile"
}

export const FLOW_TYPE_SCREENING = 36