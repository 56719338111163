// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  Input,
  InputContainer,
  MessageInputContainer,
  TitleInputContainer,
} from "./CalendarNodeEditor.styled";
import NodeContext from "../../context/NodeContext";
import { useContext } from "react";
import { Editor } from "react-draft-wysiwyg";
import { getHTMLToDraft } from "../../utils/helper";
import { toolbar } from "../../utils/constants";
import { LABELS } from "../../utils/labels";

function CalendarNodeEditor() {
  const { selectedNodeInfo, updateNode, variableList, setNewAddedNode } =
    useContext(NodeContext);

  if (!selectedNodeInfo) {
    return null;
  }

  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
        />
        <p>
          Opens a calendar UI in the chat interface through which the user can
          select their desired date
        </p>
      </TitleInputContainer>
      <MessageInputContainer>
        <p>{LABELS.SHOW_MESSAGE}</p>
        {/* <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: [
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                        ],
                        placeholder: "What is your...",
                    }}
                    onChange={(_, editor) => {
                        const data = editor.getData();
                        updateNode(selectedNodeInfo.id, {
                            ...selectedNodeInfo?.data,
                            message: data,
                        });
                    }}
                    data={selectedNodeInfo?.data?.message}
                /> */}
        <Editor
          editorState={getHTMLToDraft(selectedNodeInfo?.data?.message)}
          onEditorStateChange={(data) => {
            const isMessageEmpty = data.getCurrentContent().hasText();
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              message: data,
              isMessageEmpty: !isMessageEmpty,
            });
          }}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={toolbar}
          mention={{
            separator: " ",
            trigger: "{",
            suggestions: variableList,
          }}
          onBlur={() => {
            setNewAddedNode(true);
          }}
          placeholder="What is your..."
          stripPastedStyles={true}
        />
      </MessageInputContainer>

      <MessageInputContainer></MessageInputContainer>

      <InputContainer>
        <p>{LABELS.SAVE_RESPONSE_VARIABLE}</p>
        <input
          className="input"
          placeholder="Enter Variable Name"
          value={selectedNodeInfo?.data?.variableName}
          onBlur={() => {
            setNewAddedNode(true);
          }}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              variableName: e.target.value,
            });
          }}
        />
        <span className="note">
          You can add a variable that can be referenced later in the
          conversation.
        </span>
      </InputContainer>
    </>
  );
}

export default CalendarNodeEditor;
