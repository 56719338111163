import { MarkerType } from "reactflow";

export const STARTING_NODE_ID = "starting-node";
export const STARTING_NODE_ID2 = "starting-node-2";

export const NODE_TYPE = {
    STARTING_NODE: "startingNode",
    MESSAGE_NODE: "messageNode",
    COLLECT_INPUT_NODE: "collectInputNode",
    BUTTONS_NODE: "buttonsNode",
    BUTTON_NODE: "buttonNode",
    FILE_UPLOAD_NODE: "fileUploadNode",
    CALENDAR_NODE: "calendarNode",
    CODE_BLOCK_NODE: "codeBlockNode",
    SERVICE_CALL_NODE: "serviceCallNode",
    // CAROUSEL_NODE: "carouselNode",
    CARD_NODE: "cardNode",
    CONDITIONAL_NODE: "conditionalNode",
    JUMP_TO_NODE: "jumpToNode",
    END_NODE: "endNode",
    EMAIL_NODE: "emailNode",
    JUMP_TO_PROFILE_NODE: "jumpToProfileNode",
};


export const BUTTON_TYPE = {
    BRANCH_BUTTON: "branchButton",
    URL_BUTTON: "linkButton",
};

export const STARTING_NODE_POSITION = {
    id: STARTING_NODE_ID,
    type: NODE_TYPE.STARTING_NODE,
    position: { x: window.innerWidth / 4 - 140, y: 100 },
    data: {
        startingText: "Invite Link",
        isBottom: true,
        type: NODE_TYPE.STARTING_NODE,
    },
};

export const STARTING_NODE_POSITION2 = {
    id: STARTING_NODE_ID2,
    type: NODE_TYPE.STARTING_NODE,
    position: { x: window.innerWidth / 1.5 - 140, y: 100 },
    data: {
        startingText: "Apply Link",
        isBottom: true,
        type: NODE_TYPE.STARTING_NODE,
    },
};

export const options = [
    { label: "contact_created_by", value: "contact_created_by" },
    { label: "df", value: "df" },
    { label: "email", value: "email" },
    { label: "gcal_email", value: "gcal_email" },
    { label: "gcal_name", value: "gcal_name" },
    { label: "name", value: "name" },
    { label: "phone", value: "phone" },
];

export const ALLOWED_FILE_TYPES = [".jpg", ".png", ".pdf", ".doc", ".docx"];

export const inputSubTypeOptions = [
    { label: "Text", value: "text" },
    { label: "Number", value: "number" },
    { label: "Date", value: "date" },
    { label: "Email", value: "email" },
    { label: "Regular Expression", value: "regex" },
];

export const emailTypeOptions = [
    { label: "Email", value: "email" },
    { label: "SMS", value: "sms" },
];

export const nodeTypes = [
    { label: "Send Message", value: NODE_TYPE.MESSAGE_NODE },
    { label: "Ask a Text Question", value: NODE_TYPE.COLLECT_INPUT_NODE },
    { label: "Ask a Choice Question", value: NODE_TYPE.BUTTON_NODE },
    { label: "Collect Document", value: NODE_TYPE.FILE_NODE },
    { label: "Code Block", value: NODE_TYPE.CODE_BLOCK_NODE },
    { label: "Service Call", value: NODE_TYPE.SERVICE_CALL_NODE },
    { label: "Carousel", value: NODE_TYPE.CAROUSEL_NODE },
    { label: "Conditional Block", value: NODE_TYPE.CONDITIONAL_NODE },
    { label: "Jump to Node", value: NODE_TYPE.JUMP_TO_NODE },
    { label: "End Chat", value: NODE_TYPE.END_NODE },
];

export const servceCallTypes = [
    { label: "GET", value: "GET" },
    { label: "POST", value: "POST" },
    { label: "PUT", value: "PUT" },
    { label: "PATCH", value: "PATCH" },
];

export const bodyTypes = [
    { label: "Raw", value: "raw" },
    { label: "Form", value: "form" },
];

export const dateFormatOptions = [
    { label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
    { label: "DD-MM-YYYY", value: "DD-MM-YYYY" },
    { label: "YYYY/MM/DD", value: "YYYY/MM/DD" },
    { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
];

export const buttonOptions = [
    { label: "Shortlisted", value: "Shortlisted" },
    { label: "Maybe", value: "Maybe" },
    { label: "Rejected", value: "Rejected" },
];

export const conditionOptions = [
    { label: "equals to", value: "equals to" },
    { label: "not equals to", value: "not equals to" },
    { label: "contains", value: "contains" },
    { label: "does not contains", value: "does not contains" },
    { label: "equals one of", value: "Equals one of" },
    { label: "does not equals one of", value: "Does not equals one of" },
    { label: "is empty", value: "is empty" },
    { label: "is not empty", value: "is not empty" },
    { label: "is greater than", value: "is greater than" },
    { label: "is less than", value: "is less than" },
    {
        label: "choices selected greater than",
        value: "choices selected greater than",
    },
    {
        label: "choices selected less than",
        value: "choices selected less than",
    }
];

export const edgeConfig = {
    markerEnd: {
        type: MarkerType.Arrow,
        color: "#b3d8ee",
    },
    markerStart: {
        type: MarkerType.Arrow,
        color: "#b3d8ee",
        orient: "auto-start-reverse",
    },
    strokeWidth: 2,
    className: "custom-edge-class",
};

export const toolbar = {
    options: ["inline", "list", "link"],
    inline: {
        options: ["bold", "italic", "underline"],
    },
    list: {
        options: ["unordered", "ordered"],
    },
    link:{
        popupClassName: "link-popup-class"
    }
};

export const emptyToolbar = {
    options: [],
    inline: {},
    list: {},
};

export const VAR_DEFAULT_VARIABLE_LIST = [
    {
        label: "ApplicationId",
        value: "{applicationId}}",
    },
    {
        label: "Candidate FirstName",
        value: "{firstName}}",
    },
    {
        label: "Candidate LastName",
        value: "{lastName}}",
    },
    {
        label: "Candidate EmailId",
        value: "{emailId}}",
    },
    {
        label: "Candidate Mobile",
        value: "{mobile}}",
    },
    {
        label: "Candidate Suburb",
        value: "{suburb}}",
    },
    {
        label: "Candidate State",
        value: "{state}}",
    },
    {
        label: "Candidate PostCode",
        value: "{postCode}}",
    },
    {
        label: "Candidate Address",
        value: "{address}}",
    },
    {
        label: "Candidate Id",
        value: "{hostSystemUserId}}",
    },
    {
        label: "Candidate Current Job Title",
        value: "{jobTitle}}",
    },
    {
        label: "Candidate Current Employer",
        value: "{currentEmployer}}",
    },
    {
        label: "Job Position",
        value: "{position}}",
    },
    {
        label: "Job Location",
        value: "{jobLocation}}",
    },
    {
        label: "JobOrder Id",
        value: "{jobOrderId}}",
    },
    {
        label: "JobOrder Expired ",
        value: "{isJobOrderExpired}}",
    },
    {
        label: "Interview Completed",
        value: "{interviewCompleted}}",
    },
    {
        label: "BotName",
        value: "{botName}}",
    },
    {
        label: "Bot AdminEmail",
        value: "{botAdminEmail}}",
    },
    {
        label: "Company Name",
        value: "{companyName}}",
    },
    {
        label: "Invite Source",
        value: "{inviteSource}}",
    },
    {
        label: "Screening Assessment",
        value: "{Assessment}}",
    },
    {
        label: "Custom QuestionOne",
        value: "{questionOne}}",
    },
    {
        label: "Custom QuestionTwo",
        value: "{questionTwo}}",
    },
    {
        label: "Custom QuestionThree",
        value: "{questionThree}}",
    },
    {
        label: "Custom QuestionFour",
        value: "{questionFour}}",
    },
];

export const DEFAULT_VARIABLE_LIST = VAR_DEFAULT_VARIABLE_LIST?.map((item) => ({
    ...item,
    text: (
        <>
            <span className="var-text">{item.label}</span>
            <span className="var-type">System</span>
        </>
    ),
}));

export const EMPTY_DRAFT = "<p></p>";

export const INITIAL_GAP_BETWEEN_TWO_NODES = 175;
