// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  CheckboxContainer,
  Input,
  InputContainer,
  MessageInputContainer,
  TitleInputContainer,
} from "./FileUploadNodeEditor.styled";
import NodeContext from "../../context/NodeContext";
import { useContext } from "react";
import { ALLOWED_FILE_TYPES, toolbar } from "../../utils/constants";
import { Editor } from "react-draft-wysiwyg";
import { getHTMLToDraft } from "../../utils/helper";
import ResponseVariable from "../ResponseVariable/index";
import Select from "react-select";
import { LABELS } from "../../utils/labels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function FileUploadNodeEditor() {
  const {
    selectedNodeInfo,
    updateNode,
    variableList,
    atsData,
    isEditable,
    setNewAddedNode,
    saveData,
  } = useContext(NodeContext);
  const [displayError, setDisplayError] = useState(
    selectedNodeInfo.data.isVariableNameAlreadyUsed ?? false
  );

  if (!selectedNodeInfo) {
    return null;
  }

  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
        <p>Allows the user to upload files in the defined format as below</p>
      </TitleInputContainer>
      <MessageInputContainer>
        <p>{LABELS.SHOW_MESSAGE}</p>
        {/* <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: [
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                        ],
                        placeholder: "What is your...",
                    }}
                    onChange={(_, editor) => {
                        const data = editor.getData();
                        updateNode(selectedNodeInfo.id, {
                            ...selectedNodeInfo?.data,
                            message: data,
                        });
                    }}
                    data={selectedNodeInfo?.data?.message}
                /> */}
        <Editor
          editorState={getHTMLToDraft(selectedNodeInfo?.data?.message)}
          onEditorStateChange={(data) => {
            const isMessageEmpty = data.getCurrentContent().hasText();
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              message: data,
              isMessageEmpty: !isMessageEmpty,
            });
          }}
          onBlur={() => {
            setNewAddedNode(true);
          }}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={toolbar}
          mention={{
            separator: " ",
            trigger: "{",
            suggestions: variableList,
          }}
          placeholder="What is your..."
          readOnly={!isEditable}
          stripPastedStyles={true}
        />
      </MessageInputContainer>

      <MessageInputContainer key={selectedNodeInfo?.id}>
        <p>{LABELS.FILE_TYPE}</p>
        <CheckboxContainer>
          {ALLOWED_FILE_TYPES.map((type) => (
            <div className="itemContainer" key={type}>
              <input
                type="checkbox"
                id={type}
                checked={selectedNodeInfo?.data?.allowedFileTypes?.includes(
                  type
                )}
                onClick={() => {
                  const allowedFileTypes =
                    selectedNodeInfo?.data?.allowedFileTypes || [];
                  if (allowedFileTypes.includes(type)) {
                    updateNode(selectedNodeInfo.id, {
                      ...selectedNodeInfo?.data,
                      allowedFileTypes: allowedFileTypes.filter(
                        (t) => t !== type
                      ),
                    });
                  } else {
                    updateNode(selectedNodeInfo.id, {
                      ...selectedNodeInfo?.data,
                      allowedFileTypes: [...allowedFileTypes, type],
                    });
                  }
                }}
                disabled={!isEditable}
              />
              <label htmlFor={type}>{type}</label>
            </div>
          ))}
        </CheckboxContainer>
      </MessageInputContainer>

      <InputContainer>
        <p>{LABELS.INPUT_SUGGESTION}</p>
        <input
          className="input"
          placeholder="Enter Suggestion"
          value={selectedNodeInfo?.data?.suggestionText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              suggestionText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
      </InputContainer>
      <InputContainer>
        <p>{LABELS.INPUT_VALIDATION}</p>
        <input
          className="input"
          placeholder="Enter Validation Message"
          value={selectedNodeInfo?.data?.validationText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              validationText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
      </InputContainer>
      <InputContainer>
        {atsData && (
          <>
            <p>{LABELS.ATS_FIELD}</p>
            <Select
              options={atsData}
              className="select-container"
              defaultValue=""
              isClearable
              value={atsData.find(
                (o) => o.value === (selectedNodeInfo?.data?.atsValue || "")
              )}
              onChange={(e) => {
                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  atsValue: e ? e.value : e,
                });
                setTimeout(() => {
                  saveData();
                }, 10);
              }}
              key={selectedNodeInfo?.id}
              isDisabled={!isEditable}
              placeholder="Select ATS Field"
            />
          </>
        )}
        <ResponseVariable
          updateNodeFun={updateNode}
          selectedNodeInfo={selectedNodeInfo}
          iseditable={isEditable ? "true" : undefined}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "2px",
            width: "100%",
          }}
        >
          <input
            className="input"
            placeholder="Enter Variable Name"
            value={selectedNodeInfo?.data?.variableName}
            onBlur={(e) => {
              setNewAddedNode(true);
            }}
            onChange={(e) => {
              if (e.target.value.length > 60) {
                return;
              }
              const isVariableNameAlreadyUsed = variableList.find(
                (item) => item.value === `{${e.target.value.trim()}}}`
              );
              if (isVariableNameAlreadyUsed) {
                setDisplayError(true);
              } else {
                setDisplayError(false);
              }

              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                variableName: e.target.value,
                isVariableNameAlreadyUsed: isVariableNameAlreadyUsed
                  ? true
                  : false,
              });
            }}
            readOnly={!isEditable}
          />
          {displayError && (
            <Tooltip title="This variable has already been used.">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                style={{
                  color: "#ffcc00",
                  fontSize: "18px",
                  display: "",
                  position: "absolute",
                  right: "40px",
                }}
              />
            </Tooltip>
          )}
        </div>
        <span className="note">
          You can add a variable that can be referenced later in the
          conversation.
        </span>
      </InputContainer>
    </>
  );
}

export default FileUploadNodeEditor;
