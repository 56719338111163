import {
  Input,
  MessageInputContainer,
  TitleInputContainer,
} from "./JumpToNodeEditor.styled";
import NodeContext from "../../context/NodeContext";
import { useContext } from "react";
import Select from "react-select";
import { NODE_TYPE } from "../../utils/constants";
import { LABELS } from "../../utils/labels";
import { useCallback } from "react";

function JumpToNodeEditor() {
  const { selectedNodeInfo, updateNode, nodes, isEditable } =
    useContext(NodeContext);

  const createOptions = useCallback(() => {
    const options = [];
    options.push({ value: "0", label: "Select an action block" });
    nodes
      .filter(
        (node) =>
          node?.type !== NODE_TYPE.END_NODE &&
          node?.type !== NODE_TYPE.STARTING_NODE &&
          node?.type !== NODE_TYPE.JUMP_TO_NODE &&
          node.data.buttonType !== "branchButton"
      )
      .forEach((node) => {
        options.push({
          value: node?.id,
          label: node?.data?.titleText,
        });
      });
    return options;
  }, [nodes]);

  if (!selectedNodeInfo) {
    return null;
  }

  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
        <p>
          Allows you to route the conversation flow from this point to the
          action block mentioned below.
        </p>
      </TitleInputContainer>
      <MessageInputContainer>
        <p>{LABELS.GO_TO_ACTION}</p>
        <Select
          styles={{
            control: (base) => ({
              ...base,
              fontFamily: "'Plus Jakarta Sans', sans-serif",
            }),
            option: (base) => ({
              ...base,
              fontFamily: "'Plus Jakarta Sans', sans-serif",
            }),
          }}
          options={createOptions()}
          className="select-container"
          placeholder="Select an action block"
          value={
            nodes
              .filter((node) => node?.id === selectedNodeInfo?.id)
              .map((node) => ({
                value: node?.data.value,
                label: node?.data.label,
              }))[0]
          }
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              value: e ? e.value : "0",
              label: e ? e.label : "Select an action block",
            });
          }}
          defaultValue={{
            value: "0",
            label: "Select an action block",
          }}
          isClearable={selectedNodeInfo?.data?.value === "0" ? false : true}
          key={selectedNodeInfo?.id}
          isDisabled={!isEditable}
        />
      </MessageInputContainer>
    </>
  );
}

export default JumpToNodeEditor;
