import styled from "styled-components";

export const Input = styled.input`
    color: #101010;
    padding: 6px 0px;
    font-size: 18px;
    background: #ffffff;
    line-height: normal;
    border: 0;
    border-bottom: 2px solid #afb2b3;
    width: 100%;
    font-weight: bold;
    font-family: "Plus Jakarta Sans", sans-serif;

    &:focus {
        outline: none;
    }
`;

export const TitleInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    p {
        color: #858585;
        font-size: 14px;
        text-align: left;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
    }
`;

export const TypeContainer = styled.div`
    width: 100%;
    margin-bottom: 20px;

    div {
        p {
            color: rgb(16, 16, 16);
            font-size: 15px;
            text-align: left;
            font-family: "Plus Jakarta Sans", sans-serif;
            font-weight: 500;
            margin: 0px 0px 20px;
            padding: 0px;
        }

        .input {
            width: 100%;
            outline: none;
            border: 1px solid rgb(133, 133, 133);
            border-radius: 4px;
            font-family: "Plus Jakarta Sans", sans-serif;
            font-size: 15px;
            color: rgb(16, 16, 16);
            padding: 0px 10px;
            height: 40px;
        }
    }
`;

export const RequestOptionContainer = styled.div`
    margin-bottom: 20px;

    p {
        color: rgb(16, 16, 16);
        font-size: 15px;
        text-align: left;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
        margin: 0px 0px 20px;
        padding: 0px;
    }

    .inputContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        margin-bottom: 10px;

        .input {
            width: 50%;
            outline: none;
            border: 1px solid rgb(133, 133, 133);
            border-radius: 4px;
            font-family: "Plus Jakarta Sans", sans-serif;
            font-size: 15px;
            color: rgb(16, 16, 16);
            padding: 0px 10px;
            height: 40px;
        }
    }
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;

    button {
        color: #2a7cff;
        border: 1px solid #2a7cff;
        padding: 8px 12px;
        background: #ffffff;
        font-size: 14px;
        font-family: "Plus Jakarta Sans", sans-serif;
        border-radius: 3px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        line-height: 1;

        img {
            width: 15px;
        }
    }
`;

export const BodyTypeContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    p {
        color: rgb(16, 16, 16);
        font-size: 15px;
        text-align: left;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
    }
`;

export const BodyField = styled.textarea`
    width: 100%;
    min-height: 150px;
    resize: none;
    margin-bottom: 20px;
`;

export const FormDataContainer = styled.div`
    margin-bottom: 20px;

    p {
        color: rgb(16, 16, 16);
        font-size: 15px;
        text-align: left;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
        margin: 0px 0px 20px;
        padding: 0px;
    }

    .inputContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        margin-bottom: 10px;

        .input {
            width: 50%;
            outline: none;
            border: 1px solid rgb(133, 133, 133);
            border-radius: 4px;
            font-family: "Plus Jakarta Sans", sans-serif;
            font-size: 15px;
            color: rgb(16, 16, 16);
            padding: 0px 10px;
            height: 40px;
        }
    }
`;

export const ResponseFieldsContainer = styled.div`
    margin-bottom: 20px;

    p {
        color: rgb(16, 16, 16);
        font-size: 15px;
        text-align: left;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
    }

    .inputContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        margin-bottom: 10px;

        .input {
            width: 50%;
            outline: none;
            border: 1px solid rgb(133, 133, 133);
            border-radius: 4px;
            font-family: "Plus Jakarta Sans", sans-serif;
            font-size: 15px;
            color: rgb(16, 16, 16);
            padding: 0px 10px;
            height: 40px;
        }
    }
`;
