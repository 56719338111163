import styled from "styled-components";

export const Input = styled.input`
    color: #101010;
    padding: 6px 0px;
    font-size: 18px;
    background: #ffffff;
    line-height: normal;
    border: 0;
    border-bottom: 2px solid #afb2b3;
    width: 100%;
    font-weight: bold;
    font-family: "Plus Jakarta Sans", sans-serif;

    &:focus {
        outline: none;
    }
`;

export const TitleInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    p {
        color: #858585;
        font-size: 14px;
        text-align: left;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
    }
`;

export const TextAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    img {
        width: 15px;
        align-self: flex-end;
        cursor: pointer;
    }

    textarea {
        width: 100%;
        min-height: 400px;
    }
`;

export const FullScreenContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000bb;
    z-index: 10000000;

    .codeEditorContainer {
        padding: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: 80%;
        background-color: #ffffff;

        .header {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            img {
                width: 15px;
                align-self: flex-end;
                cursor: pointer;
            }
        }

        textarea {
            width: 100%;
            margin-top: 20px;
            height: calc(100% - 40px);
            resize: none;

            &:focus {
                outline: none;
            }
        }
    }
`;

export const InputContainer = styled.div`
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    p {
        color: #101010;
        font-size: 15px;
        text-align: left;
        margin-bottom: 4px;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }

    .input {
        width: 100%;
        outline: none;
        border: 1px solid rgb(133, 133, 133);
        border-radius: 4px;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 15px;
        color: rgb(16, 16, 16);
        padding: 0px 10px;
        height: 40px;
    }
`;