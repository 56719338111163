// import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import upAndDownArrow from "../../assets/images/up-and-down-arrow.png";

const DraggableList = ({
  itemsArray,
  setItemsArray,
  itemRenderer,
  handleOnDragEnd,
  isEditable,
}) => {
  // const [items, setItems] = useState(
  //   itemsArray ?? [
  //     { id: "item-1", content: "Item 1" },
  //     { id: "item-2", content: "Item 2" },
  //     { id: "item-3", content: "Item 3" },
  //     { id: "item-4", content: "Item 4" },
  //     { id: "item-5", content: "Item 5" },
  //   ]
  // );
  // const grid = 8;
  // const getItemStyle = (isDragging, draggableStyle) => ({
  //   // some basic styles to make the items look a bit nicer
  //   userSelect: "none",
  //   padding: grid * 2,
  //   margin: `0 0 ${grid}px 0`,

  //   // change background colour if dragging
  //   background: isDragging ? "lightgreen" : "grey",

  //   // styles we need to apply on draggables
  //   ...draggableStyle,
  // });

  // const getListStyle = (isDraggingOver) => ({
  //   background: isDraggingOver ? "#007cc5d" : "",
  //   width: "100%",
  // });

  // const reorder = (list, startIndex, endIndex) => {
  //   const result = Array.from(list);
  //   const [removed] = result.splice(startIndex, 1);
  //   result.splice(endIndex, 0, removed);

  //   return result;
  // };

  // const handleOnDragEnd = (result) => {
  //   if (!result.destination) return;
  //   const itemsCopy = reorder(
  //     items,
  //     result.source.index,
  //     result.destination.index
  //   );
  //   setItems(itemsCopy);
  // };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              // ...getListStyle(snapshot.isDraggingOver),
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            {itemsArray.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
                isDragDisabled={!isEditable}
              >
                {(provided, snapshot) => (
                  <DraggableComponent
                    provided={provided}
                    item={item}
                    index={index}
                    isEditable={isEditable}
                    itemRenderer={itemRenderer}
                    setItemsArray={setItemsArray}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;

function DraggableComponent({
  provided,
  item,
  index,
  isEditable,
  itemRenderer,
}) {
  const [isVisisble, setIsVisible] = useState(false);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      style={{
        ...provided.draggableProps.style,
        position: "static",
        display: "flex",
        alignItems: "start",
        width: "100%",
        marginLeft: isEditable ? "-10px" : "0px",
      }}
    >
      <img
        {...provided.dragHandleProps}
        src={upAndDownArrow}
        alt="grip-dots-icon"
        width={16}
        style={{
          position: "absolute",
          left: "10px",
          marginTop: "10px",
          cursor: "grab",
          visibility: isEditable && isVisisble ? "visible" : "hidden",
        }}
      />
      <div style={{
        width: "100%",
      }}>{itemRenderer(item, index)}</div>
    </div>
  );
}
