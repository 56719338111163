// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  // ActionButtonContainer,
  Input,
  MessageInputContainer,
  TitleInputContainer,
} from "./MessageNodeEditor.styled";
import NodeContext from "../../context/NodeContext";
import { Fragment, useContext } from "react";
// import AddIcon from "../../assets/images/addIcon.svg";
// import { v4 as uuidv4 } from "uuid";
import { Editor } from "react-draft-wysiwyg";
import { getHTMLToDraft } from "../../utils/helper";
import { toolbar } from "../../utils/constants";
import { LABELS } from "../../utils/labels";

function MessageNodeEditor() {
  const { selectedNodeInfo, updateNode, variableList, isEditable, setNewAddedNode } =
    useContext(NodeContext);

  if (!selectedNodeInfo) {
    return null;
  }
  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isEditable}
        />
        <p>
          Send message will only send messages to the user and not require a
          response back.
        </p>
      </TitleInputContainer>

      <MessageInputContainer>
        <p>{LABELS.MESSAGE_NODE_MESSAGE_BODY}</p>
        {selectedNodeInfo?.data?.messages?.map((item, index) => {
          return (
            <Fragment key={item?.id}>
              {/* <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                ],
                placeholder: "What is your...",
              }}
              onChange={(_, editor) => {
                const data = editor.getData();
                const messages = selectedNodeInfo?.data?.messages;
                messages[index].text = data;
                updateNode(selectedNodeInfo.id, {
                  ...selectedNodeInfo?.data,
                  messages,
                });
              }}
              data={item?.text}
            /> */}
              <Editor
                editorState={getHTMLToDraft(item?.text)}
                onEditorStateChange={(data) => {
                  const messages = selectedNodeInfo?.data?.messages;
                  messages[index].text = data;
                  const isMessageEmpty = data?.getCurrentContent()?.hasText();
                  updateNode(selectedNodeInfo.id, {
                    ...selectedNodeInfo?.data,
                    messages,
                    isMessageEmpty: !isMessageEmpty,
                  });
                }}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={toolbar}
                mention={{
                  separator: " ",
                  trigger: "{",
                  suggestions: variableList,
                }}
                onBlur={() => {
                  setNewAddedNode(true);
                }}
                // }}
                placeholder="What is your..."
                readOnly={!isEditable}
                stripPastedStyles={true}
              />
            </Fragment>
          );
        })}
      </MessageInputContainer>
      {/* <ActionButtonContainer>
                <button
                    onClick={() =>
                        updateNode(selectedNodeInfo.id, {
                            ...selectedNodeInfo?.data,
                            messages: [
                                ...selectedNodeInfo?.data?.messages,
                                { id: uuidv4(), text: "Sample message" },
                            ],
                        })
                    }
                >
                    <img src={AddIcon} alt="addIcon" />
                    Text
                </button>
            </ActionButtonContainer> */}
    </>
  );
}

export default MessageNodeEditor;
