import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { LABELS } from "../../utils/labels";

const ResponseVariable = ({
  updateNodeFun,
  selectedNodeInfo,
  iseditable,
}) => {
  return (
    <div className="response-container">
      <p>{LABELS.SAVE_RESPONSE_VARIABLE}</p>
      <Tooltip title="Enable to show in chat summary">
        <Switch
          checked={
            selectedNodeInfo?.data.isShowInChatHistory
              ? selectedNodeInfo?.data.isShowInChatHistory
              : false
          }
          onChange={(e) => {
            updateNodeFun(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              isShowInChatHistory: e.target.checked,
            });
          }}
          disabled={!iseditable}
        />
      </Tooltip>
    </div>
  );
};

export default ResponseVariable;
