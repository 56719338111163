import {
  FullScreenContainer,
  Input,
  TextAreaContainer,
  TitleInputContainer,
  InputContainer,
} from "./CodeBlockNodeEditor.styled";
import NodeContext from "../../context/NodeContext";
import { useContext, useEffect, useState } from "react";
import ExternalIcon from "../../assets/images/external.svg";
import CloseIcon from "../../assets/images/closeIcon.svg";
import { createPortal } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import { LABELS } from "../../utils/labels";
import { toast } from "react-toastify";


function CodeBlockNodeEditor() {
  const {
    selectedNodeInfo,
    updateNode,
    variableList,
    setNewAddedNode,
    isEditable,
    isSuperAdmin,
  } = useContext(NodeContext);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [displayError, setDisplayError] = useState(
    selectedNodeInfo.data.isVariableNameAlreadyUsed ?? false
  );
  useEffect(() => {
    if (!isSuperAdmin) {
      toast.error("You don't have permission to edit this node", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    }
  }, [isSuperAdmin, selectedNodeInfo?.id, isEditable]);

  if (!selectedNodeInfo) {
    return null;
  }

  return (
    <>
      <TitleInputContainer>
        <Input
          placeholder="Label"
          value={selectedNodeInfo?.data?.titleText}
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              titleText: e.target.value,
            });
          }}
          readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
        />
        <p>
          Trigger Javascript functions within the flow to perform your required
          actions like math calculations, firing analytics events and more.
        </p>
      </TitleInputContainer>
      {showFullScreen
        ? createPortal(
          <FullScreenContainer>
            <div className="codeEditorContainer">
              <div className="header">
                <img
                  src={CloseIcon}
                  alt="closeIcon"
                  onClick={() => setShowFullScreen(false)}
                />
              </div>
              <textarea
                onChange={(e) => {
                  updateNode(selectedNodeInfo.id, {
                    ...selectedNodeInfo?.data,
                    code: e.target.value,
                  });
                }}
                value={selectedNodeInfo?.data?.code}
                readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
              ></textarea>
            </div>
          </FullScreenContainer>,
          document.getElementById("portal")
        )
        : null}
      <TextAreaContainer>
        <img
          src={ExternalIcon}
          alt="externalIcon"
          onClick={() => setShowFullScreen(true)}
        />
        <textarea
          onChange={(e) => {
            updateNode(selectedNodeInfo.id, {
              ...selectedNodeInfo?.data,
              code: e.target.value,
            });
          }}
          value={selectedNodeInfo?.data?.code}
          readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
        ></textarea>
      </TextAreaContainer>
      <InputContainer>
        <div className="response-container" style={{
          marginBottom: "10px",
        }}>
          <p>{LABELS.SAVE_RESPONSE_VARIABLE}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "2px",
            width: "100%",
          }}
        >
          <input
            style={{
              position: "relative",
            }}
            className="input"
            placeholder="Enter Variable Name"
            value={selectedNodeInfo?.data?.variableName}
            onBlur={(e) => {
              setNewAddedNode(true);
            }}
            onChange={(e) => {
              if (e.target.value.length > 60) {
                return;
              }
              const isVariableNameAlreadyUsed = variableList.find(
                (item) => item.value === `{${e.target.value.trim()}}}`
              );
              if (isVariableNameAlreadyUsed) {
                setDisplayError(true);
              } else {
                setDisplayError(false);
              }

              updateNode(selectedNodeInfo.id, {
                ...selectedNodeInfo?.data,
                variableName: e.target.value,
                isVariableNameAlreadyUsed: isVariableNameAlreadyUsed
                  ? true
                  : false,
              });
            }}
            readOnly={!isSuperAdmin ? !isSuperAdmin : !isEditable}
          />
          {displayError && (
            <Tooltip title="This variable has already been used.">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                style={{
                  color: "#ffcc00",
                  fontSize: "18px",
                  display: "",
                  position: "absolute",
                  right: "40px",
                }}
              />
            </Tooltip>
          )}
        </div>
        <span className="note">
          You can add a unique variable that can be referenced later in the
          conversation.
        </span>
      </InputContainer>
    </>
  );
}

export default CodeBlockNodeEditor;
