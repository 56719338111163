import { TailSpin } from  'react-loader-spinner'

function ButtonLoader(props) {
  const { visible , color} = props;
  return (
    <TailSpin
        height="15"
        width="15"
        color={ color ? color : "#007cc5"}
        ariaLabel="tail-spin-loading"
        radius="5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={visible}
  />
  );
}

export default ButtonLoader;
