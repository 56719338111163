import styled from "styled-components";
import { Handle } from "reactflow";
import AddIcon from "../../assets/images/addIcon.svg";

export const StyledStartingNode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background-color: white;
  border-radius: 3px;
  width: 160px;
  height: 55px;
  border: 3px solid #b3d8ee;
  border-radius: 10px;
  transition: box-shadow 0.2s ease-in;
  font-size: 16px;
  color: #007cc5;
  border-radius: 28px;

  &:hover {
    box-shadow: 0px 0px 0px #ed2b2b, 0px 0px 0px #ed2b2b, 0px 0px 0px #ed2b2b,
      0px 0px 0px #ed2b2b;
  }

  position: relative;

  span {
    font-weight: bold;
    text-align: center;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
  }
`;

export const TopEdge = styled(Handle)`
  ${(props) => {
    if (!props.iseditable) return `visibility: hidden;`;
    return props.isConnectable
      ? `width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 4px solid #fff;
                background-color: #afb2b3;
                z-index: 1;
                top: -12px;
            `
      : `width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 4px solid #fff;
                background-color: #afb2b3;
                z-index: 1;
                top: -6px; 
                visibility: hidden;
            `;
  }}
`;

export const BottomEdge = styled(Handle)`
  ${(props) => {
    if (!props.iseditable) return `visibility: hidden;`;
    return props.isConnectable
      ? `
                width: 20px;
                height: 20px;
                border: 0;
                cursor: pointer !important;
                background: url(${
                  props?.$addIcon ?? AddIcon
                }) no-repeat center center;
                bottom: -12px;
            `
      : `
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 4px solid #fff;
                background-color: ${props?.bgcolor ?? "#afb2b3"};
                bottom: -12px;   
            `;
  }};
`;
