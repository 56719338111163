import ApiUtil from "..";

class FlowModule extends ApiUtil {
  async getFlowModule() {
    const response = await this.get("flow");
    return response.data;
  }

  async postFlowModule(data) {
    return await this.post("flow", data);
  }

  async getTokenFromSession(sessionId) {
    return await this.getNew(`FlowBuilder/Token/${sessionId}`);
  }

  async getProfileData(profileId) {
    return await this.getNew(`FlowBuilder/${profileId}`);
  }

  async postProfileData(id, data) {
    if (id) {
      delete data["atsProperties"];
      return await this.patch(`FlowBuilder/${id}`, data);
    }
  }

  async uploadFile(data) {
    return await this.post("FlowBuilder/UploadFile", data);
  }

  async getTemplateFlow(id) {
    return await this.getNew("FlowBuilder/Template/" + id);
  }

  async postTemplateFlow(id, data) {
    return await this.patch(`FlowBuilder/Template/${id}`, data);
  }

  async getProfileQuestions(profileId) {
    return await this.getNew(`FlowBuilder/${profileId}/Questions`);
  }
}

export const flowModuleApi = new FlowModule();
