import styled from "styled-components";

export const CalendarNode = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 15px;
    background-color: white;
    border-radius: 3px;
    width: 280px;
    height: 100%;
    /* box-shadow: 0 2px 20px 0 rgb(232 233 239 / 50%), inset 5px 0 0 -1px #359cec; */
    border: 1px solid #359cec;
    /* box-shadow: inset 0 0 4px #359cec, 0 0 4px #359cec; */
    border-radius: 10px;
    box-shadow: 1px 1px 0px #359cec, 2px 2px 0px #359cec, 3px 3px 0px #359cec,
        4px 4px 0px #359cec;
    position: relative;
    transition: box-shadow 0.2s ease-in;
    outline: ${(props) => (props?.$isActive ? "3px solid #359cec" : "none")};

    &:hover {
        box-shadow: 0px 0px 0px #359cec, 0px 0px 0px #359cec,
            0px 0px 0px #359cec, 0px 0px 0px #359cec;
    }

    &:hover {
        .action-btns {
            visibility: visible;
        }
    }

    .titleContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        span {
            font-size: 18px;
            font-weight: bold;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    span.messageText {
        font-size: 11px;
        color: #828d93;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        text-align: left;

        p {
            margin: 0;
            padding: 0;
        }
    }
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    position: absolute;
    top: 0;
    right: -50px;
    border: solid 1px #e8e9ef;
    background-color: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(232, 233, 239, 0.5);
    transition: all 1s ease;
    visibility: hidden;

    img {
        cursor: pointer;
    }
`;
