import { Rings } from "react-loader-spinner";
import { LoaderDiv } from "./Loader.styled";

function Loader(props) {
  const { visible } = props;
  return (
    <LoaderDiv>
      <Rings
        height="150"
        width="150"
        color="#007cc5"
        radius="6"
        visible={visible}
        ariaLabel="rings-loading"
      />
    </LoaderDiv>
  );
}

export default Loader;
