import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Select, { components } from "react-select";
import { NODE_TYPE } from "../../utils/constants";
import { matchSorter } from "match-sorter";
import { useState } from "react";
import { useReducer } from "react";
import { useMemo } from "react";
import { forwardRef } from "react";

const Searchbar = forwardRef(
  ({ nodes, selectedOption, setSelectedOption, handleSearch }, searchRef) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const defaultNodes = useMemo(() => {
      return nodes
        .filter(
          (node) =>
            node?.type !== NODE_TYPE.END_NODE &&
            node?.type !== NODE_TYPE.STARTING_NODE &&
            node.type !== NODE_TYPE.BUTTON_NODE
        )
        .map((node) => {
          let message = "";
          if (node.data.messages) {
            message = node.data?.messages?.[0]?.text
              .getCurrentContent()
              .getPlainText();
          } else if (node.data?.message) {
            message = node.data?.message?.getCurrentContent().getPlainText();
          } else if (node?.message) {
            message = node?.message?.getCurrentContent().getPlainText();
          }
          if (!message) return null;
          return {
            value: message,
            label: message,
            id: node.id,
          };
        })
        .filter((item) => item !== null);
    }, [nodes]);

    const [options, dispatch] = useReducer((state, action) => {
      switch (action.type) {
        case "SET_OPTIONS":
          return action.payload;
        default:
          return state;
      }
    });

    const handleInputChange = (newValue, { action }) => {
      newValue = newValue.trim();
      if (action === "input-change" && newValue.length >= 2) {
        dispatch({
          type: "SET_OPTIONS",
          payload: matchSorter(defaultNodes, newValue, { keys: ["label"] }),
        });
        setIsMenuOpen(true);
        // setOptions(
        //   nodes
        //     .filter(
        //       (node) =>
        //         node?.type !== NODE_TYPE.END_NODE &&
        //         node?.type !== NODE_TYPE.STARTING_NODE &&
        //         node.type !== NODE_TYPE.BUTTON_NODE
        //     )
        //     .map((node) => {
        //       let message = "";
        //       if (node.data.messages) {
        //         message = node.data?.messages?.[0]?.text
        //           .getCurrentContent()
        //           .getPlainText();
        //       } else if (node.data?.message) {
        //         message = node.data?.message?.getCurrentContent().getPlainText();
        //       } else if (node?.message) {
        //         message = node?.message?.getCurrentContent().getPlainText();
        //       }
        //       if (!message) return null;
        //       return {
        //         value: message,
        //         label: message,
        //         id: node.id,
        //       };
        //     })
        //     .filter((item) => item !== null)
        // );
      } else {
        setIsMenuOpen(false);
      }
    };
    return (
      <Select
        ref={searchRef}
        openMenuOnFocus={true}
        className="basic-single"
        classNamePrefix="select"
        isSearchable={true}
        onChange={(e) => {
          setSelectedOption(e);
          handleSearch(e.id);
          searchRef.current.blur();
        }}
        menuIsOpen={isMenuOpen}
        onInputChange={handleInputChange}
        value={selectedOption}
        name="nodes"
        placeholder="Search"
        options={options}
        styles={{
          control: (provided, state) => ({
            ...provided,
            width: 350,
            border: "1px solid #ccc",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 0px 0px 10px",
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontSize: "15px",
            fontWeight: "400",
            paddingLeft: "30px",
            paddingRight: "15px",
            color: "#007cc5",
            boxShadow: "none",
            "&:hover": {
              border: "1px solid #ccc",
            },
            textAlign: "left",
          }),
          menu: (provided, state) => ({
            ...provided,
            width: 350,
            border: "1px solid #ccc",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontSize: "13px",
            fontWeight: "400",
            boxShadow: "none",
          }),
          option: (provided, state) => ({
            ...provided,
            width: 340,
            textAlign: "left",
            marginLeft: "4px",
            marginRight: "4px",
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontSize: "13px",
            fontWeight: "400",
            boxShadow: "none",
          }),
        }}
        components={{
          DropdownIndicator: () => (
            <div style={{ position: "absolute", left: "10px" }}>
              <FontAwesomeIcon icon={faSearch} color="grey" />
            </div>
          ),
          IndicatorSeparator: () => null,
          Option: MyOption,
        }}
      />
    );
  }
);

export default Searchbar;

export class MyOption extends React.Component {
  f_search = (input, option) => {
    const { label } = option;
    const num = label.toLowerCase().indexOf(input.trim().toLowerCase());
    const len = input.trim().length;

    return (
      <div>
        <span>{label.substr(0, num)}</span>
        <span style={{ color: "#007cc5", textDecoration: "underline" }}>
          {label.substr(num, len)}
        </span>
        <span>{label.substr(num + len)}</span>
      </div>
    );
  };
  render() {
    const input = this.props.selectProps.inputValue;
    const option = this.props.data;
    return (
      <components.Option {...this.props}>
        {this.f_search(input, option)}
      </components.Option>
    );
  }
}
